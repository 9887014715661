section.product-categories {
  margin-top: 48px;

  @include breakpoint(large) {
    margin-top: 100px;
  }

  .row {
    position: relative;
  }

  .text-link {
    text-align: center;

    @include breakpoint(medium) {
      text-align: right;
    }

    a {
      font-size: 16px;
      font-weight: 600;
      text-decoration-line: underline;
      color: $black;
      margin-bottom: 24px;
      display: block;

      @include hover-focus {
        color: $red;
      }
    }
  }

  .slider-wrapper .slick-list {
    padding-right: 32px;
  }

  .slick-list {
    overflow: visible;
    // margin: 0 -7.5px;
    margin-left: -7.5px;

    @include breakpoint(medium) {
      // margin: 0 -16px;
      margin-left: -16px;
    }
  }

  .slick-slide {
    margin: 0 7.5px;

    @include breakpoint(medium) {
      margin: 0 16px;
    }
  }

  .slider-arrow {
    opacity: 0;

    @include breakpoint(medium) {
      opacity: 1;
    }
  }

  .product-category {
    color: $black;

    .img-wrapper {
      border-radius: 6px;
      margin-bottom: 12px;
      overflow: hidden;
    }

    img {
      width: 100%;
      border-radius: 6px;
      // height: 418px;
      aspect-ratio: 1/1;
      object-fit: cover;
      @include transit;
    }

    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.015em;
    }

    .desc {
      font-size: 18px;
    }

    .promo {
      background: $red;
      padding: 41px;
      border-radius: 6px;
      // height: 418px;
      aspect-ratio: 1/1;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .header {
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: #FFFFFF;
        margin-bottom: 36px;
      }
    }

    @include hover-focus {
      .title {
        text-decoration: underline;
      }

      img {
        transform: scale(1.1);
      }
    }
  }
}