@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 30em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto; } }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=30em&large=53.125em&xlarge=65em&xxlarge=75em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fff;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #2D2926;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }
  .row .row {
    margin-right: -0.25rem;
    margin-left: -0.25rem; }
    @media print, screen and (min-width: 30em) {
      .row .row {
        margin-right: -0.25rem;
        margin-left: -0.25rem; } }
    @media print, screen and (min-width: 53.125em) {
      .row .row {
        margin-right: -0.25rem;
        margin-left: -0.25rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 12px;
    padding-left: 12px; }
  .row.is-collapse-child,
  .row.collapse > .column > .row,
  .row.collapse > .columns > .row {
    margin-right: 0;
    margin-left: 0; }

.column, .columns {
  flex: 1 1 0px;
  padding-right: 12px;
  padding-left: 12px;
  min-width: 0; }
  @media print, screen and (min-width: 30em) {
    .column, .columns {
      padding-right: 12px;
      padding-left: 12px; } }

.column.row.row, .row.row.columns {
  float: none;
  display: block; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 {
  flex-wrap: wrap; }
  .small-up-1 > .column, .small-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%; }

.small-up-2 {
  flex-wrap: wrap; }
  .small-up-2 > .column, .small-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%; }

.small-up-3 {
  flex-wrap: wrap; }
  .small-up-3 > .column, .small-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.small-up-4 {
  flex-wrap: wrap; }
  .small-up-4 > .column, .small-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%; }

.small-up-5 {
  flex-wrap: wrap; }
  .small-up-5 > .column, .small-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%; }

.small-up-6 {
  flex-wrap: wrap; }
  .small-up-6 > .column, .small-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.small-up-7 {
  flex-wrap: wrap; }
  .small-up-7 > .column, .small-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.small-up-8 {
  flex-wrap: wrap; }
  .small-up-8 > .column, .small-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 12px;
  padding-left: 12px; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 12px;
  padding-left: 12px; }

@media print, screen and (min-width: 30em) {
  .medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 {
    flex-wrap: wrap; }
    .medium-up-1 > .column, .medium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .medium-up-2 {
    flex-wrap: wrap; }
    .medium-up-2 > .column, .medium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .medium-up-3 {
    flex-wrap: wrap; }
    .medium-up-3 > .column, .medium-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .medium-up-4 {
    flex-wrap: wrap; }
    .medium-up-4 > .column, .medium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .medium-up-5 {
    flex-wrap: wrap; }
    .medium-up-5 > .column, .medium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .medium-up-6 {
    flex-wrap: wrap; }
    .medium-up-6 > .column, .medium-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .medium-up-7 {
    flex-wrap: wrap; }
    .medium-up-7 > .column, .medium-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .medium-up-8 {
    flex-wrap: wrap; }
    .medium-up-8 > .column, .medium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 30em) and (min-width: 30em) {
  .medium-expand {
    flex: 1 1 0px; } }

.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 30em) {
    .row.medium-unstack > .column, .row.medium-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 30em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 12px;
    padding-left: 12px; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 12px;
    padding-left: 12px; } }

@media print, screen and (min-width: 53.125em) {
  .large-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 {
    flex-wrap: wrap; }
    .large-up-1 > .column, .large-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .large-up-2 {
    flex-wrap: wrap; }
    .large-up-2 > .column, .large-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .large-up-3 {
    flex-wrap: wrap; }
    .large-up-3 > .column, .large-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .large-up-4 {
    flex-wrap: wrap; }
    .large-up-4 > .column, .large-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .large-up-5 {
    flex-wrap: wrap; }
    .large-up-5 > .column, .large-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .large-up-6 {
    flex-wrap: wrap; }
    .large-up-6 > .column, .large-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .large-up-7 {
    flex-wrap: wrap; }
    .large-up-7 > .column, .large-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .large-up-8 {
    flex-wrap: wrap; }
    .large-up-8 > .column, .large-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 53.125em) and (min-width: 53.125em) {
  .large-expand {
    flex: 1 1 0px; } }

.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 53.125em) {
    .row.large-unstack > .column, .row.large-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 53.125em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 12px;
    padding-left: 12px; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 12px;
    padding-left: 12px; } }

.shrink {
  flex: 0 0 auto;
  max-width: 100%; }

.column-block {
  margin-bottom: 0.5rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 30em) {
    .column-block {
      margin-bottom: 0.5rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #cacaca; }

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 30em) {
  h1, .h1 {
    font-size: 3rem; }
  h2, .h2 {
    font-size: 2.5rem; }
  h3, .h3 {
    font-size: 1.9375rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #0e4b8f;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #0c417b; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 80rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #2D2926;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #2D2926; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #2D2926; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 30em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 53.125em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fff;
  box-shadow: inset 0 1px 2px rgba(45, 41, 38, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #2D2926;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fff;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #2D2926; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #2D2926; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #2D2926;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fff;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #2D2926;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fff;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #faedeb; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #0e4b8f;
  color: #fff; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #0c407a;
    color: #fff; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #0e4b8f;
    color: #fff; }
    .button.primary:hover, .button.primary:focus {
      background-color: #0b3c72;
      color: #fff; }
  .button.secondary {
    background-color: #fff;
    color: #2D2926; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #cccccc;
      color: #2D2926; }
  .button.success {
    background-color: #3adb76;
    color: #2D2926; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #2D2926; }
  .button.warning {
    background-color: #ffae00;
    color: #2D2926; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #2D2926; }
  .button.alert {
    background-color: #cc4b37;
    color: #fff; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #fff; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #0e4b8f;
      color: #fff; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #0e4b8f;
        color: #fff; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #fff;
        color: #2D2926; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #2D2926; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #2D2926; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #fff; }
  .button.hollow {
    border: 1px solid #0e4b8f;
    color: #0e4b8f; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #072648;
      color: #072648; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 1px solid #0e4b8f;
        color: #0e4b8f; }
    .button.hollow.primary {
      border: 1px solid #0e4b8f;
      color: #0e4b8f; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #072648;
        color: #072648; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 1px solid #0e4b8f;
          color: #0e4b8f; }
    .button.hollow.secondary {
      border: 1px solid #fff;
      color: #fff; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: gray;
        color: gray; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 1px solid #fff;
          color: #fff; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
  .button.clear {
    border: 1px solid #0e4b8f;
    color: #0e4b8f; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #072648;
      color: #072648; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 1px solid #0e4b8f;
        color: #0e4b8f; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 1px solid #0e4b8f;
      color: #0e4b8f; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #072648;
        color: #072648; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 1px solid #0e4b8f;
          color: #0e4b8f; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 1px solid #fff;
      color: #fff; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: gray;
        color: gray; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 1px solid #fff;
          color: #fff; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fff transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #0e4b8f; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #0e4b8f; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #fff; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

a.button:hover, a.button:focus {
  text-decoration: none; }

.accordion {
  margin-left: 0;
  background: #fff;
  list-style-type: none; }
  .accordion[disabled] .accordion-title {
    cursor: not-allowed; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #0e4b8f; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e6e6e6; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '\2013'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fff;
  color: #2D2926; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e6e6e6; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.6875rem;
    color: #2D2926;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #cacaca; }
  .breadcrumbs a {
    color: #0e4b8f; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #cacaca;
    cursor: not-allowed; }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(45, 41, 38, 0.25);
  border-radius: 0;
  background-color: white;
  color: #2D2926; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #cfe3fa;
    color: #2D2926; }
  .callout.secondary {
    background-color: white;
    color: #2D2926; }
  .callout.success {
    background-color: #e1faea;
    color: #2D2926; }
  .callout.warning {
    background-color: #fff3d9;
    color: #2D2926; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #2D2926; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.7rem 1rem; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  .menu.vertical {
    flex-wrap: nowrap;
    flex-direction: column; }
  .menu.expanded li {
    flex: 1 1 0px; }
  .menu.simple {
    align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 30em) {
    .menu.medium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.medium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.medium-expanded li {
      flex: 1 1 0px; }
    .menu.medium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 53.125em) {
    .menu.large-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.large-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.large-expanded li {
      flex: 1 1 0px; }
    .menu.large-simple li {
      flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: flex; }
  .menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
    display: flex; }
  .menu.icon-left li a {
    flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #0e4b8f;
    color: #fff; }
  .menu .active > a {
    background: #0e4b8f;
    color: #fff; }
  .menu.align-left {
    justify-content: flex-start; }
  .menu.align-right li {
    display: flex;
    justify-content: flex-end; }
    .menu.align-right li .submenu li {
      justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: flex;
    justify-content: center; }
    .menu.align-center li .submenu li {
      justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.7rem 1rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  justify-content: center; }
  .menu-centered > .menu li {
    display: flex;
    justify-content: center; }
    .menu-centered > .menu li .submenu li {
      justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  height: 20px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 3px;
    background: #fff;
    box-shadow: 0 8px 0 #fff, 0 16px 0 #fff;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 8px 0 #cacaca, 0 16px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  height: 20px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 3px;
    background: #2D2926;
    box-shadow: 0 8px 0 #2D2926, 0 16px 0 #2D2926;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #8a8a8a;
    box-shadow: 0 8px 0 #8a8a8a, 0 16px 0 #8a8a8a; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.drilldown a {
  padding: 0.7rem 1rem;
  background: #fff; }

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fff;
  transition: transform 0.15s linear; }
  .drilldown .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .drilldown .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }
  .drilldown .is-drilldown-submenu a {
    padding: 0.7rem 1rem; }

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0; }

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%; }

.drilldown .is-drilldown-submenu-parent > a {
  position: relative; }
  .drilldown .is-drilldown-submenu-parent > a::after {
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0e4b8f; }

.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #0e4b8f; }

.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0e4b8f transparent transparent; }

.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0e4b8f transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fff;
  font-size: 1rem; }
  .dropdown-pane.is-opening {
    display: block; }
  .dropdown-pane.is-open {
    visibility: visible;
    display: block; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #0e4b8f transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.7rem 1rem; }
  [data-whatinput='mouse'] .dropdown.menu a {
    outline: 0; }

.dropdown.menu .is-active > a {
  background: transparent;
  color: #0e4b8f; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0e4b8f transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #0e4b8f; }

@media print, screen and (min-width: 30em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0e4b8f transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0e4b8f transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0e4b8f; } }

@media print, screen and (min-width: 53.125em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0e4b8f transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0e4b8f transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0e4b8f; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    left: auto;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fff; }
  .dropdown .is-dropdown-submenu a {
    padding: 0.7rem 1rem; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0e4b8f transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0e4b8f; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(255, 255, 255, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-push {
    z-index: 12; }
  .off-canvas.is-closed {
    visibility: hidden; }
  .off-canvas.is-transition-overlap {
    z-index: 13; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(45, 41, 38, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-push {
    z-index: 12; }
  .off-canvas-absolute.is-closed {
    visibility: hidden; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 13; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(45, 41, 38, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(-250px); }
  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-250px); }
    .off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-left.has-transition-push {
    transform: translateX(250px); }
  .position-left.is-transition-push {
    box-shadow: inset -13px 0 20px -13px rgba(45, 41, 38, 0.25); }

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(250px); }
  .off-canvas-content .off-canvas.position-right {
    transform: translateX(250px); }
    .off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-right.has-transition-push {
    transform: translateX(-250px); }
  .position-right.is-transition-push {
    box-shadow: inset 13px 0 20px -13px rgba(45, 41, 38, 0.25); }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(-250px); }
  .off-canvas-content .off-canvas.position-top {
    transform: translateY(-250px); }
    .off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-top.has-transition-push {
    transform: translateY(250px); }
  .position-top.is-transition-push {
    box-shadow: inset 0 -13px 20px -13px rgba(45, 41, 38, 0.25); }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(250px); }
  .off-canvas-content .off-canvas.position-bottom {
    transform: translateY(250px); }
    .off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-bottom.has-transition-push {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push {
    box-shadow: inset 0 13px 20px -13px rgba(45, 41, 38, 0.25); }

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden; }
  .off-canvas-content.has-transition-push {
    transform: translate(0, 0); }
  .off-canvas-content .off-canvas.is-open {
    transform: translate(0, 0); }

@media print, screen and (min-width: 30em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 53.125em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 30em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-medium .close-button {
      display: none; } }

@media print, screen and (min-width: 53.125em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-large .close-button {
      display: none; } }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 30em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #2D2926; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e6e6e6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #0e4b8f;
    color: #fff;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #2D2926; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #0e4b8f;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #0c407a; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed !important;
  z-index: 5;
  width: 100%; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(45, 41, 38, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fff;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 30em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 30em) {
    .reveal {
      width: 600px;
      max-width: 80rem; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 30em) {
    .reveal.tiny {
      width: 30%;
      max-width: 80rem; } }
  @media print, screen and (min-width: 30em) {
    .reveal.small {
      width: 50%;
      max-width: 80rem; } }
  @media print, screen and (min-width: 30em) {
    .reveal.large {
      width: 90%;
      max-width: 80rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 29.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fff;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #fff;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #0e4b8f; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fff;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #0e4b8f; }
  .tabs.primary > li > a {
    color: #fff; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #10539f; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 0.75rem;
    line-height: 1;
    color: #0e4b8f; }
    .tabs-title > a:hover {
      background: #fff;
      color: #0c417b; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #e6e6e6;
      color: #0e4b8f; }

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #fff;
  color: #2D2926;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel.is-active {
    display: block; }

.title-bar {
  padding: 0.5rem;
  background: #2D2926;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px; }

.title-bar-right {
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 9999;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #2D2926;
  font-size: 80%;
  color: #fff; }
  .tooltip::before {
    position: absolute; }
  .tooltip.bottom::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #2D2926;
    bottom: 100%; }
  .tooltip.bottom.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #2D2926 transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.top.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #2D2926;
    left: 100%; }
  .tooltip.left.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #2D2926 transparent transparent;
    right: 100%;
    left: auto; }
  .tooltip.right.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.align-top::before {
    bottom: auto;
    top: 10%; }
  .tooltip.align-bottom::before {
    bottom: 10%;
    top: auto; }
  .tooltip.align-left::before {
    left: 10%;
    right: auto; }
  .tooltip.align-right::before {
    left: auto;
    right: 10%; }

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap; }
  .top-bar,
  .top-bar ul {
    background-color: #e6e6e6; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%; }
  @media print, screen and (min-width: 30em) {
    .top-bar {
      flex-wrap: nowrap; }
      .top-bar .top-bar-left {
        flex: 1 1 auto;
        margin-right: auto; }
      .top-bar .top-bar-right {
        flex: 0 1 auto;
        margin-left: auto; } }
  @media screen and (max-width: 53.0625em) {
    .top-bar.stacked-for-medium {
      flex-wrap: wrap; }
      .top-bar.stacked-for-medium .top-bar-left,
      .top-bar.stacked-for-medium .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 64.9375em) {
    .top-bar.stacked-for-large {
      flex-wrap: wrap; }
      .top-bar.stacked-for-large .top-bar-left,
      .top-bar.stacked-for-large .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0; }

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 29.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 30em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 30em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 29.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 30em) and (max-width: 53.0625em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 29.9375em), screen and (min-width: 53.125em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 53.125em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 53.0625em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 53.125em) and (max-width: 64.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 53.0625em), screen and (min-width: 65em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1; }

.clearfix::after {
  clear: both; }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 30em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 53.125em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 30em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 53.125em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

/*
=============================================================================
                   Normalize, border box and clearfix
=============================================================================
*/
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

a:focus {
  outline: thin dotted; }

a:active,
a:hover {
  outline: 0; }

h1 {
  font-size: 2em; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: 700; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

code, kbd, pre, samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: \201C \201D \2018 \2019; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em; }

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button, input {
  line-height: normal; }

button, html input[type=button],
input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], input[disabled] {
  cursor: default; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

input[type='search'] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body, figure {
  margin: 0; }

legend, button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.clearfix:after {
  content: " ";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both; }

body {
  background-color: #fff; }

:focus {
  outline: none !important;
  transition: none; }

.cbm-container {
  max-width: 85vw;
  width: 100%;
  margin: 0 auto; }

.cbm-container-hero {
  max-width: 95vw;
  width: 100%;
  margin: 0 auto; }

.row {
  max-width: 96vw; }

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  img {
    image-rendering: -webkit-optimize-contrast !important; } }

/* Unset for Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    img {
      image-rendering: unset !important; } } }

hr {
  margin: 0;
  margin-bottom: 32px;
  max-width: 100%;
  border-color: rgba(217, 217, 217, 0.2); }

a {
  color: #DA291C; }
  a:hover, a:focus {
    color: #2D2926; }

section.hero + section {
  margin-top: 48px; }

section:not(.hero) {
  margin-top: 90px; }
  @media print, screen and (min-width: 53.125em) {
    section:not(.hero) {
      margin-top: 100px; } }

a,
button,
.button,
svg,
svg path,
svg circle,
svg {
  transition: all 150ms ease-in; }

.accessible {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  border: 0 none !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px; }

.skipnav:focus, .skipnav:active {
  position: absolute !important;
  top: 5px !important;
  left: 42% !important;
  width: 16%;
  background-color: #2D2926;
  padding: 10px;
  color: #fff !important;
  z-index: 9999;
  text-align: center;
  clip: inherit;
  overflow: hidden;
  border: 0 none !important;
  width: auto !important;
  height: auto !important;
  margin: inherit; }

.sticky-content {
  position: fixed;
  left: 50%;
  transform: translateX(-50%); }
  .sticky-content.stuck {
    z-index: 999;
    opacity: 1; }
  .sticky-content.unstuck {
    opacity: 0;
    pointer-events: none; }

.mb-5 {
  margin-bottom: 2.5rem !important; }

.text-lg {
  font-size: 2rem !important; }

section.mobile-search-wrapper {
  padding: 18px 0;
  margin-top: 0;
  top: 47px;
  width: 100%;
  background-color: white; }

@media screen and (max-width: 1279px) {
  .show-for-xlarge {
    display: none !important; } }

footer {
  margin-top: 60px;
  padding-top: 22px;
  padding-bottom: 22px;
  border-top: 1px solid #D9D9D9;
  background: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #292929;
  position: relative;
  z-index: 10000; }
  @media print, screen and (min-width: 53.125em) {
    footer {
      z-index: unset; }
      footer .row {
        flex-direction: row-reverse; } }
  @media print, screen and (min-width: 53.125em) {
    footer {
      margin-top: 150px; } }
  footer a {
    color: #292929; }
    footer a:hover, footer a:focus {
      text-decoration: underline; }
  footer .copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    font-size: 14px;
    line-height: 20px; }
    @media print, screen and (min-width: 53.125em) {
      footer .copyright {
        margin-top: 0;
        justify-content: start;
        gap: 18px;
        font-size: 16px;
        line-height: 22px; } }
    footer .copyright a {
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px; }
      footer .copyright a:before {
        content: '';
        display: inline-block;
        width: 3px;
        height: 3px;
        margin-top: -3px;
        background: #292929; }
      @media print, screen and (min-width: 53.125em) {
        footer .copyright a {
          gap: 18px; } }
  footer .resources {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    footer .resources button {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px; }
      footer .resources button:hover, footer .resources button:focus {
        text-decoration: underline; }
    @media print, screen and (min-width: 30em) {
      footer .resources {
        justify-content: space-around; } }
    @media print, screen and (min-width: 53.125em) {
      footer .resources {
        justify-content: flex-end;
        gap: 47px; }
        footer .resources button {
          position: relative;
          display: flex;
          align-items: center;
          gap: 6px;
          font-size: 16px;
          line-height: 22px; }
          footer .resources button:after {
            content: '';
            display: block;
            width: 19px;
            height: 19px;
            background: url("../images/icon-chevron-up.svg") no-repeat center center;
            background-size: contain; } }

header {
  z-index: 9996; }
  header .logo {
    width: 144px; }
    @media print, screen and (min-width: 30em) {
      header .logo {
        width: auto; } }
  header nav {
    background-color: #fff;
    transition: all 300ms ease-in-out; }
  header .sticky.is-stuck {
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.05)); }
  header .top-bar {
    height: 47px;
    background: #fff;
    padding: 0;
    transition: all 300ms ease-in-out; }
    @media print, screen and (min-width: 30em) {
      header .top-bar {
        height: 91px; } }
    header .top-bar .row {
      display: flex;
      align-items: center;
      width: 100%; }
      header .top-bar .row .medium-6 {
        justify-content: center;
        display: flex; }
    header .top-bar .search-form,
    header .top-bar .search-form input[type="text"],
    header .top-bar .meta a,
    header .top-bar .meta a span,
    header .top-bar .meta button,
    header .top-bar .meta button span {
      transition: all 300ms ease-in-out; }
    header .top-bar.search-open .search-form {
      max-width: 100%; }
      header .top-bar.search-open .search-form input {
        text-align: left; }
    header .top-bar.search-open .meta a span,
    header .top-bar.search-open .meta button:not(.hamburger) span {
      margin-left: 0;
      max-width: 0;
      opacity: 0; }
  header .quote-btn,
  header .quote-btn a {
    width: 100%; }
  header .meta {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-end; }
    header .meta a,
    header .meta button:not(.hamburger) {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #292929;
      display: flex;
      align-items: center; }
      header .meta a img,
      header .meta button:not(.hamburger) img {
        height: 21px; }
        @media print, screen and (min-width: 30em) {
          header .meta a img,
          header .meta button:not(.hamburger) img {
            height: initial; } }
      header .meta a .icon-wrapper,
      header .meta button:not(.hamburger) .icon-wrapper {
        position: relative; }
      header .meta a .cart-qty,
      header .meta button:not(.hamburger) .cart-qty {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background: #DA291C;
        position: absolute;
        top: -10px;
        right: -10px;
        color: white;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        padding-top: 2px; }
      header .meta a.w-notice .icon-wrapper:after,
      header .meta button:not(.hamburger).w-notice .icon-wrapper:after {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        background: #DA291C;
        position: absolute;
        top: -7px;
        right: -4px; }
      header .meta a > span,
      header .meta button:not(.hamburger) > span {
        margin-left: 12px;
        overflow: hidden; }
      header .meta a:hover, header .meta a:focus,
      header .meta button:not(.hamburger):hover,
      header .meta button:not(.hamburger):focus {
        text-decoration: underline; }
  header .hamburger-bun {
    height: 17px; }
    @media print, screen and (min-width: 30em) {
      header .hamburger-bun {
        display: flex;
        align-items: center;
        gap: 10px; }
        header .hamburger-bun:hover, header .hamburger-bun:focus {
          cursor: pointer; }
        header .hamburger-bun > span {
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          letter-spacing: 0.015em; }
        header .hamburger-bun .hamburger {
          line-height: 21px; } }
  header .bottom-bar {
    height: 47px;
    display: flex;
    align-items: center; }
    header .bottom-bar .menu button {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #292929;
      margin-left: 64px; }
      header .bottom-bar .menu button:hover, header .bottom-bar .menu button:focus {
        color: #DA291C;
        text-decoration: underline; }
    header .bottom-bar .hamburger-bun > span {
      line-height: 1;
      display: block; }

.off-canvas-content .off-canvas {
  z-index: 9999;
  width: 100vw;
  padding: 24px;
  background: #fff;
  box-shadow: none !important; }
  @media print, screen and (min-width: 30em) {
    .off-canvas-content .off-canvas {
      width: 420px;
      border-radius: 0px 24px 24px 0px; } }
  .off-canvas-content .off-canvas .close-button {
    border-radius: 100%; }
    .off-canvas-content .off-canvas .close-button:hover, .off-canvas-content .off-canvas .close-button:focus {
      background: #D9D9D9; }
  .off-canvas-content .off-canvas hr {
    margin-bottom: 32px;
    width: 100%;
    border-bottom: 1px solid #D0D0D0; }
  .off-canvas-content .off-canvas .menu li {
    margin-bottom: 12px; }
    .off-canvas-content .off-canvas .menu li:last-of-type {
      margin-bottom: 0; }
    .off-canvas-content .off-canvas .menu li a {
      font-size: 18px;
      line-height: 25px;
      color: #292929;
      background: transparent;
      border-bottom: 2px solid transparent;
      padding: 0;
      display: inline; }
      .off-canvas-content .off-canvas .menu li a:hover, .off-canvas-content .off-canvas .menu li a:focus {
        border-color: #2D2926; }
  .off-canvas-content .off-canvas .social-icons-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 36px; }
    .off-canvas-content .off-canvas .social-icons-wrapper a:hover svg path, .off-canvas-content .off-canvas .social-icons-wrapper a:focus svg path {
      fill: #2D2926; }
  @media print, screen and (min-width: 30em) {
    .off-canvas-content .off-canvas .inner {
      padding: 85px 52px 0; } }
  .off-canvas-content .off-canvas .inner .inner-menu {
    display: block;
    font-weight: 600;
    color: #292929; }
    .off-canvas-content .off-canvas .inner .inner-menu.primary {
      font-size: 24px;
      line-height: 32px;
      border-bottom: 3px solid transparent;
      margin-bottom: 32px; }
      @media print, screen and (min-width: 30em) {
        .off-canvas-content .off-canvas .inner .inner-menu.primary {
          font-size: 32px;
          line-height: 36px; } }
    .off-canvas-content .off-canvas .inner .inner-menu.secondary {
      font-size: 18px;
      line-height: 25px;
      border-bottom: 2px solid transparent;
      margin-bottom: 32px; }
    .off-canvas-content .off-canvas .inner .inner-menu:hover, .off-canvas-content .off-canvas .inner .inner-menu:focus {
      border-color: #DA291C; }
  .off-canvas-content .off-canvas .inner a.inner-menu {
    display: inline-block; }
  .off-canvas-content .off-canvas .inner .content {
    margin-top: 32px; }
    @media print, screen and (min-width: 30em) {
      .off-canvas-content .off-canvas .inner .content {
        margin-top: 48px; } }
    .off-canvas-content .off-canvas .inner .content .button {
      margin-top: 12px; }
  .off-canvas-content .off-canvas .locations-wrapper .location {
    margin-bottom: 40px; }
    @media print, screen and (min-width: 30em) {
      .off-canvas-content .off-canvas .locations-wrapper .location {
        margin-bottom: 55px; } }
  .off-canvas-content .off-canvas .locations-wrapper .city {
    font-size: 16px;
    font-weight: 600;
    color: #2D2926; }
    .off-canvas-content .off-canvas .locations-wrapper .city:hover, .off-canvas-content .off-canvas .locations-wrapper .city:focus {
      text-decoration: underline; }
    .off-canvas-content .off-canvas .locations-wrapper .city:after {
      content: '';
      display: inline-block;
      width: 14px;
      height: 12px;
      margin-left: 8px;
      background: url("../images/icon-arrow-right.svg") no-repeat center center;
      background-size: contain; }
  .off-canvas-content .off-canvas .locations-wrapper .desc {
    font-size: 16px;
    line-height: 1.2; }

.off-canvas-content .off-canvas.position-top {
  transform: translateY(-625px);
  height: 534px;
  width: 100vw;
  padding: 20px 2vw;
  z-index: 9995;
  border-radius: 0px 0px 24px 24px; }
  .off-canvas-content .off-canvas.position-top .master-close {
    margin-left: auto;
    margin-right: 8px;
    display: block; }
  .off-canvas-content .off-canvas.position-top.is-open {
    transform: translate(0, 91px) !important; }
  .off-canvas-content .off-canvas.position-top + .js-off-canvas-overlay {
    z-index: 9994; }
  .off-canvas-content .off-canvas.position-top .cbm-container {
    position: relative; }
    .off-canvas-content .off-canvas.position-top .cbm-container > .row {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 100%; }
  .off-canvas-content .off-canvas.position-top .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 32px; }
  .off-canvas-content .off-canvas.position-top .menu li {
    margin-bottom: 24px; }
    .off-canvas-content .off-canvas.position-top .menu li a {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px; }
  .off-canvas-content .off-canvas.position-top a.button {
    margin-top: 40px; }

.off-canvas-content .off-canvas.position-left {
  transform: translateX(100vw);
  padding: 20px;
  z-index: 10001; }
  .off-canvas-content .off-canvas.position-left .master-close {
    margin-left: auto;
    display: block; }
  @media print, screen and (min-width: 30em) {
    .off-canvas-content .off-canvas.position-left {
      transform: translateX(-420px); }
      .off-canvas-content .off-canvas.position-left .master-close {
        margin-left: unset; } }

.off-canvas-content .off-canvas.position-right {
  transform: translateX(100vw);
  padding: 13px 20px; }
  @media print, screen and (min-width: 30em) {
    .off-canvas-content .off-canvas.position-right {
      transform: translateX(420px);
      border-radius: 24px 0px 0px 24px;
      padding: 24px 50px; } }
  .off-canvas-content .off-canvas.position-right .close-button {
    margin-left: auto;
    display: block;
    margin-bottom: 12px; }
  .off-canvas-content .off-canvas.position-right h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 45px;
    margin-bottom: 32px; }
    @media print, screen and (min-width: 30em) {
      .off-canvas-content .off-canvas.position-right h2 {
        margin-bottom: 40px; } }
  .off-canvas-content .off-canvas.position-right .menu-cta {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 42px;
    margin-bottom: 36px; }
    @media print, screen and (min-width: 30em) {
      .off-canvas-content .off-canvas.position-right .menu-cta {
        margin-bottom: 44px; } }
    .off-canvas-content .off-canvas.position-right .menu-cta .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #2D2926;
      margin-bottom: 20px;
      display: inline-block;
      border-bottom: 2px solid transparent;
      transition: all 300ms ease-in-out; }
    .off-canvas-content .off-canvas.position-right .menu-cta .copy {
      font-size: 18px;
      line-height: 25px; }
    .off-canvas-content .off-canvas.position-right .menu-cta svg {
      transform: rotate(180deg); }
    .off-canvas-content .off-canvas.position-right .menu-cta:hover .title, .off-canvas-content .off-canvas.position-right .menu-cta:focus .title {
      border-color: #DA291C; }
    .off-canvas-content .off-canvas.position-right .menu-cta:hover svg circle:first-of-type,
    .off-canvas-content .off-canvas.position-right .menu-cta:hover svg path, .off-canvas-content .off-canvas.position-right .menu-cta:focus svg circle:first-of-type,
    .off-canvas-content .off-canvas.position-right .menu-cta:focus svg path {
      fill: #D9D9D6; }

.off-canvas-content .off-canvas.off-canvas-nested {
  transform: translateX(100vw);
  background: #F8F8F8; }
  .off-canvas-content .off-canvas.off-canvas-nested .master-close {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start; }
  @media print, screen and (min-width: 30em) {
    .off-canvas-content .off-canvas.off-canvas-nested {
      transform: translateX(-850px);
      width: 850px;
      z-index: 9997;
      padding: 142px 76px 109px 500px; } }
  .off-canvas-content .off-canvas.off-canvas-nested .close-button,
  .off-canvas-content .off-canvas.off-canvas-nested .close-drilldown {
    width: 42px;
    height: 42px;
    background: transparent;
    margin-bottom: 36px; }
    @media print, screen and (min-width: 30em) {
      .off-canvas-content .off-canvas.off-canvas-nested .close-button,
      .off-canvas-content .off-canvas.off-canvas-nested .close-drilldown {
        margin-bottom: 45px; } }
    .off-canvas-content .off-canvas.off-canvas-nested .close-button svg,
    .off-canvas-content .off-canvas.off-canvas-nested .close-drilldown svg {
      width: 42px;
      height: 42px; }
    .off-canvas-content .off-canvas.off-canvas-nested .close-button:hover, .off-canvas-content .off-canvas.off-canvas-nested .close-button:focus,
    .off-canvas-content .off-canvas.off-canvas-nested .close-drilldown:hover,
    .off-canvas-content .off-canvas.off-canvas-nested .close-drilldown:focus {
      animation: 0.5s linear 0.350s infinite alternate wobble; }
      .off-canvas-content .off-canvas.off-canvas-nested .close-button:hover svg path,
      .off-canvas-content .off-canvas.off-canvas-nested .close-button:hover svg circle:first-of-type, .off-canvas-content .off-canvas.off-canvas-nested .close-button:focus svg path,
      .off-canvas-content .off-canvas.off-canvas-nested .close-button:focus svg circle:first-of-type,
      .off-canvas-content .off-canvas.off-canvas-nested .close-drilldown:hover svg path,
      .off-canvas-content .off-canvas.off-canvas-nested .close-drilldown:hover svg circle:first-of-type,
      .off-canvas-content .off-canvas.off-canvas-nested .close-drilldown:focus svg path,
      .off-canvas-content .off-canvas.off-canvas-nested .close-drilldown:focus svg circle:first-of-type {
        fill: #D9D9D6; }
  .off-canvas-content .off-canvas.off-canvas-nested li.subtitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.01em; }
  .off-canvas-content .off-canvas.off-canvas-nested .drilldown .is-drilldown-submenu {
    background: #F8F8F8; }
  .off-canvas-content .off-canvas.off-canvas-nested .drilldown .is-drilldown-submenu-parent > a::after {
    content: unset;
    display: none; }
  .off-canvas-content .off-canvas.off-canvas-nested .js-drilldown-back {
    margin-bottom: 45px;
    display: none; }
    .off-canvas-content .off-canvas.off-canvas-nested .js-drilldown-back a {
      border: none;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      align-items: center;
      gap: 24px; }
      .off-canvas-content .off-canvas.off-canvas-nested .js-drilldown-back a:before {
        border: none;
        width: 50px;
        height: 50px;
        margin: 0;
        background: url("../images/icon-back.svg") no-repeat center center;
        background-size: contain; }
      .off-canvas-content .off-canvas.off-canvas-nested .js-drilldown-back a:hover:before, .off-canvas-content .off-canvas.off-canvas-nested .js-drilldown-back a:focus:before {
        animation: 0.5s linear 0.350s infinite alternate wobble; }

@keyframes wobble {
  from {
    transform: translateX(0px); }
  to {
    transform: translateX(10px); } }
  .off-canvas-content .off-canvas.off-canvas-nested .products-wrapper {
    display: grid;
    justify-content: center;
    gap: 14px; }
    .off-canvas-content .off-canvas.off-canvas-nested .products-wrapper a.product-link {
      display: block;
      width: 150px;
      border-radius: 6px;
      background: white;
      overflow: hidden;
      border: 2px solid transparent; }
      @media print, screen and (min-width: 30em) {
        .off-canvas-content .off-canvas.off-canvas-nested .products-wrapper a.product-link {
          width: 200px; } }
      .off-canvas-content .off-canvas.off-canvas-nested .products-wrapper a.product-link img {
        width: 150px;
        height: 150px;
        object-fit: contain; }
        @media print, screen and (min-width: 30em) {
          .off-canvas-content .off-canvas.off-canvas-nested .products-wrapper a.product-link img {
            width: 200px;
            height: 200px; } }
      .off-canvas-content .off-canvas.off-canvas-nested .products-wrapper a.product-link:hover, .off-canvas-content .off-canvas.off-canvas-nested .products-wrapper a.product-link:focus {
        border-color: #000000; }
    .off-canvas-content .off-canvas.off-canvas-nested .products-wrapper a.brands-link {
      display: block;
      width: 150px;
      border-radius: 6px;
      background: white;
      overflow: hidden;
      border: 2px solid transparent; }
      @media print, screen and (min-width: 30em) {
        .off-canvas-content .off-canvas.off-canvas-nested .products-wrapper a.brands-link {
          width: 200px; } }
      .off-canvas-content .off-canvas.off-canvas-nested .products-wrapper a.brands-link img {
        max-width: 90%; }
      .off-canvas-content .off-canvas.off-canvas-nested .products-wrapper a.brands-link:hover, .off-canvas-content .off-canvas.off-canvas-nested .products-wrapper a.brands-link:focus {
        border-color: #000000; }
  .off-canvas-content .off-canvas.off-canvas-nested .featured-wrapper {
    display: grid;
    justify-content: center;
    gap: 40px; }
    @media print, screen and (min-width: 30em) {
      .off-canvas-content .off-canvas.off-canvas-nested .featured-wrapper {
        gap: 55px; } }
    .off-canvas-content .off-canvas.off-canvas-nested .featured-wrapper a {
      display: block;
      width: 200px;
      overflow: hidden; }
      .off-canvas-content .off-canvas.off-canvas-nested .featured-wrapper a .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.015em;
        color: #2D2926; }
      .off-canvas-content .off-canvas.off-canvas-nested .featured-wrapper a img {
        max-height: 250px;
        object-fit: cover;
        border-radius: 6px;
        background: #F8F8F8;
        border: 2px solid #F8F8F8;
        margin-bottom: 15px;
        transition: all 300ms ease-in-out; }
        @media print, screen and (min-width: 30em) {
          .off-canvas-content .off-canvas.off-canvas-nested .featured-wrapper a img {
            max-height: unset; } }
      .off-canvas-content .off-canvas.off-canvas-nested .featured-wrapper a:hover img, .off-canvas-content .off-canvas.off-canvas-nested .featured-wrapper a:focus img {
        border-color: #000000; }
      .off-canvas-content .off-canvas.off-canvas-nested .featured-wrapper a:hover .title, .off-canvas-content .off-canvas.off-canvas-nested .featured-wrapper a:focus .title {
        text-decoration: underline; }

@media print, screen and (min-width: 30em) {
  .off-canvas-content .off-canvas.off-canvas-nested#locations,
  .off-canvas-content .off-canvas.off-canvas-nested#recent,
  .off-canvas-content .off-canvas.off-canvas-nested#featured,
  .off-canvas-content .off-canvas.off-canvas-nested#brands {
    transform: translateX(-761px);
    width: 761px;
    padding-right: 61px; } }

.off-canvas-content .off-canvas.off-canvas-nested#locations.is-open,
.off-canvas-content .off-canvas.off-canvas-nested#recent.is-open,
.off-canvas-content .off-canvas.off-canvas-nested#featured.is-open,
.off-canvas-content .off-canvas.off-canvas-nested#brands.is-open {
  transform: translate(0, 0); }

#brands .products-wrapper {
  gap: 14px; }
  #brands .products-wrapper a {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center; }

.off-canvas-content .off-canvas.off-canvas-footer {
  width: 100%;
  border-radius: 24px 24px 0px 0px;
  padding: 52px 21px 21px; }
  @media print, screen and (min-width: 30em) {
    .off-canvas-content .off-canvas.off-canvas-footer {
      height: 350px;
      transform: translateY(350px); } }
  @media print, screen and (min-width: 53.125em) {
    .off-canvas-content .off-canvas.off-canvas-footer {
      padding: 56px 24px 24px; } }
  .off-canvas-content .off-canvas.off-canvas-footer.is-open {
    transform: translate(0, -113px) !important; }
    @media print, screen and (min-width: 53.125em) {
      .off-canvas-content .off-canvas.off-canvas-footer.is-open {
        transform: translate(0, 0) !important; } }
  @media screen and (max-width: 53.0625em) {
    .off-canvas-content .off-canvas.off-canvas-footer .menu li a {
      font-size: 14px;
      line-height: 18px; } }
  .off-canvas-content .off-canvas.off-canvas-footer .close-button {
    position: absolute;
    top: 21px;
    right: 21px; }
    @media print, screen and (min-width: 53.125em) {
      .off-canvas-content .off-canvas.off-canvas-footer .close-button {
        top: 11px;
        left: 11px;
        right: unset; } }
  .off-canvas-content .off-canvas.off-canvas-footer .copy {
    font-size: 16px; }

#resources-footer {
  height: 450px;
  transform: translateY(450px); }
  #resources-footer .button {
    margin-top: 32px;
    min-width: 157px; }
    #resources-footer .button.hollow {
      margin-top: 17px; }
  #resources-footer .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 34px; }
  #resources-footer .menu li a {
    font-size: 16px;
    line-height: 22px; }

#locations-footer {
  height: 325px;
  transform: translateY(325px); }
  #locations-footer .button {
    margin-top: 32px; }
  #locations-footer p a {
    color: #292929;
    text-decoration: none; }
    #locations-footer p a:hover, #locations-footer p a:focus {
      text-decoration: underline; }
  #locations-footer .locations-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 50px; }
    #locations-footer .locations-wrapper .location {
      margin-bottom: 0; }

#filter-footer {
  padding: 27px 0 0;
  height: 670px;
  transform: translateY(670px); }
  #filter-footer.is-open {
    transform: translate(0, 0) !important; }
  #filter-footer .close-button {
    top: 15px; }
  #filter-footer .title {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 24px; }
  #filter-footer hr {
    margin-bottom: 0; }
  #filter-footer form {
    margin-bottom: 0; }
  #filter-footer .accordion-title {
    padding: 50px 20px; }

@media screen and (max-width: 29.9375em) {
  #search-header {
    height: calc(100vh - 137px);
    border-radius: 0;
    transform: translateY(-100%);
    z-index: 10000; }
    #search-header.is-open {
      transform: translate(0, 137px) !important; }
    #search-header .title {
      margin-top: 24px; }
    #search-header .row {
      position: relative;
      left: unset;
      top: unset; }
    #search-header + .js-off-canvas-overlay {
      display: none !important; } }

@media screen and (max-width: 29.9375em) {
  #salesrep-list {
    height: calc(100vh - 137px);
    border-radius: 0;
    transform: translateY(-100%);
    z-index: 10000; }
    #salesrep-list.is-open {
      transform: translate(0, 137px) !important; }
    #salesrep-list .title {
      margin-top: 24px; }
    #salesrep-list .row {
      position: relative;
      left: unset;
      top: unset; }
    #salesrep-list + .js-off-canvas-overlay {
      display: none !important; } }

#login form {
  margin-bottom: 32px; }

#favourite button.favourites-grid {
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: 88px 1fr;
  gap: 30px;
  align-items: center; }
  #favourite button.favourites-grid img {
    justify-self: center; }
  #favourite button.favourites-grid:hover, #favourite button.favourites-grid:focus {
    text-decoration: underline; }

#favourite #create-project-btn img {
  transition: all 300ms ease-in-out; }

#favourite #create-project-btn.open img {
  transform: rotate(45deg); }

#favourite .create-project {
  margin-bottom: 32px;
  display: none;
  opacity: 0;
  transition: all 300ms ease-in-out; }
  #favourite .create-project input[type="submit"] {
    width: 100%; }
  #favourite .create-project.open {
    opacity: 1;
    display: block; }

#addresses h2 {
  font-size: 24px;
  line-height: 1.2; }

#addresses .address {
  border: 1px solid #D9D9D6;
  border-radius: 24px;
  padding: 32px;
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2; }
  #addresses .address .name {
    color: #727272; }
  #addresses .address.active, #addresses .address:hover, #addresses .address:focus {
    border-color: #2D2926; }

#two-factor-auth .sent-to {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 12px; }

#two-factor-auth .destination {
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 16px; }

#two-factor-auth .form-footer {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-bottom: 46px; }
  #two-factor-auth .form-footer > * {
    min-width: unset;
    width: 100%; }

#two-factor-auth .authenticate-method .checkbox-wrapper {
  display: grid;
  gap: 24px;
  margin-top: 18px; }
  @media print, screen and (min-width: 30em) {
    #two-factor-auth .authenticate-method .checkbox-wrapper {
      grid-template-columns: repeat(3, minmax(0, max-content));
      gap: 32px; } }

#two-factor-auth .authenticate-method .content {
  font-size: 18px; }
  #two-factor-auth .authenticate-method .content strong {
    display: block;
    font-size: 20px; }

#two-factor-auth input.hollow {
  width: 100%; }

@media print, screen and (min-width: 30em) {
  .off-canvas-content .off-canvas.position-right.product-canvas {
    width: 534px;
    transform: translateX(534px);
    padding-left: 40px;
    padding-right: 40px; } }

.off-canvas-content .off-canvas.position-right.product-canvas.is-open {
  transform: translate(0, 0); }

.off-canvas-content .off-canvas.position-right.product-canvas h2 {
  margin-bottom: 12px; }

.off-canvas-content .off-canvas.position-right.product-canvas .item-num {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 24px; }

.off-canvas-content .off-canvas.position-right.product-canvas .details-desc {
  font-size: 18px;
  line-height: 1.5;
  color: #272324;
  margin-bottom: 24px; }
  .off-canvas-content .off-canvas.position-right.product-canvas .details-desc p:last-of-type {
    margin-bottom: 0; }

.off-canvas-content .off-canvas.position-right.product-canvas .brand-details {
  font-size: 18px;
  line-height: 1.2; }

.off-canvas-content .off-canvas.position-right.product-canvas .specs-grid {
  margin-top: 24px;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  gap: 24px;
  font-size: 18px; }

.off-canvas-content .off-canvas.position-right.product-canvas .price-grid {
  margin-top: 24px;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  align-items: center;
  gap: 12px 18px; }
  .off-canvas-content .off-canvas.position-right.product-canvas .price-grid .range {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    padding: 10px 28px;
    border: 0.5px solid #727272;
    border-radius: 2px; }
  .off-canvas-content .off-canvas.position-right.product-canvas .price-grid .ppu {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #272324; }
  .off-canvas-content .off-canvas.position-right.product-canvas .price-grid .selectedBreak {
    color: #DA291C;
    border: 0.5px solid #DA291C; }

.off-canvas-content .off-canvas.position-right.product-canvas .warranty-header {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.5;
  color: #272324;
  margin-bottom: 12px;
  margin-top: 24px; }

.off-canvas-content .off-canvas.position-right.product-canvas .accordion {
  margin-top: 24px;
  border-top: 1px solid #D9D9D6; }
  .off-canvas-content .off-canvas.position-right.product-canvas .accordion .accordion-title,
  .off-canvas-content .off-canvas.position-right.product-canvas .accordion .accordion-content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 24px;
    padding-top: 0; }
  .off-canvas-content .off-canvas.position-right.product-canvas .accordion .accordion-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.2;
    padding-top: 24px; }
  .off-canvas-content .off-canvas.position-right.product-canvas .accordion .accordion-content h3 {
    margin-top: 24px;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2; }
  .off-canvas-content .off-canvas.position-right.product-canvas .accordion .accordion-content p {
    font-size: 18px;
    line-height: 1.2;
    color: #272324;
    margin-bottom: 12px; }
    .off-canvas-content .off-canvas.position-right.product-canvas .accordion .accordion-content p:last-of-type {
      margin-bottom: 0; }
  .off-canvas-content .off-canvas.position-right.product-canvas .accordion .accordion-content a {
    font-size: 18px;
    line-height: 1.2;
    text-decoration-line: underline;
    color: #272324;
    display: block;
    margin-bottom: 12px; }
    .off-canvas-content .off-canvas.position-right.product-canvas .accordion .accordion-content a:hover, .off-canvas-content .off-canvas.position-right.product-canvas .accordion .accordion-content a:focus {
      text-decoration: none;
      color: #DA291C; }

.off-canvas-content .off-canvas.position-right.location-canvas .notice {
  display: grid;
  gap: 12px;
  grid-template-columns: auto minmax(0, 1fr);
  margin-top: 48px; }

.off-canvas-content .off-canvas.position-right.location-canvas .hours-grid {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  gap: 24px;
  margin-top: 12px; }
  @media print, screen and (min-width: 30em) {
    .off-canvas-content .off-canvas.position-right.location-canvas .hours-grid {
      gap: 4px 64px; } }

.js-off-canvas-overlay {
  background: rgba(0, 0, 0, 0.4);
  opacity: 0.4 !important;
  z-index: 9997; }

form {
  display: grid;
  grid-gap: 32px; }
  form h3,
  form h4 {
    font-weight: 400;
    font-size: 20px;
    line-height: 1.5; }
  form hr {
    border-bottom: 1px solid #EEEEEE;
    margin-top: 32px;
    margin-bottom: 32px; }
  form label {
    font-size: 18px;
    line-height: 1.56;
    color: #6F6F6F; }
  form .error-msg {
    color: #EA0000;
    margin-top: 8px;
    line-height: 17px;
    font-size: 14px; }
  form .inner-grid {
    display: grid;
    gap: 20px; }
    @media print, screen and (min-width: 30em) {
      form .inner-grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 32px 14px; } }
  form .inner-grid-3 {
    display: grid;
    gap: 20px; }
    @media print, screen and (min-width: 30em) {
      form .inner-grid-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 32px 14px; } }
  form .input-wrapper {
    position: relative; }
    form .input-wrapper .form-copy {
      margin-top: 12px;
      margin-bottom: 0; }
    form .input-wrapper label {
      position: absolute;
      left: 12px;
      top: 12px;
      padding: 0 4px;
      background: white;
      pointer-events: none;
      transform: scale(1);
      transform-origin: left center;
      will-change: transform;
      transition-property: transform;
      transition-duration: 250ms;
      transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }
      form .input-wrapper label:hover, form .input-wrapper label:focus {
        cursor: text; }
    form .input-wrapper input:focus + label,
    form .input-wrapper input:not(:placeholder-shown) + label,
    form .input-wrapper textarea:focus + label,
    form .input-wrapper textarea:not(:placeholder-shown) + label,
    form .input-wrapper select:focus + label,
    form .input-wrapper select:not(:placeholder-shown) + label {
      transform: scale(0.75) translateY(-32px); }
  form .form-copy {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #727272; }
    form .form-copy a {
      color: #2D2926;
      text-decoration: underline; }
      form .form-copy a:hover, form .form-copy a:focus {
        color: #DA291C; }
    form .form-copy.black {
      color: #2D2926; }
  form input[type="submit"] {
    margin-right: auto; }
  form [type='text'],
  form [type='password'],
  form [type='date'],
  form [type='datetime'],
  form [type='datetime-local'],
  form [type='month'],
  form [type='week'],
  form [type='email'],
  form [type='number'],
  form [type='search'],
  form [type='tel'],
  form [type='time'],
  form [type='url'],
  form [type='color'],
  form [type='checkbox'],
  form [type='radio'],
  form textarea,
  form select {
    padding: 9px 16px;
    height: 49px;
    background: #FFFFFF;
    border: 0.75px solid #8D8D8D;
    border-radius: 2px;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #6F6F6F; }
    form [type='text']:focus,
    form [type='password']:focus,
    form [type='date']:focus,
    form [type='datetime']:focus,
    form [type='datetime-local']:focus,
    form [type='month']:focus,
    form [type='week']:focus,
    form [type='email']:focus,
    form [type='number']:focus,
    form [type='search']:focus,
    form [type='tel']:focus,
    form [type='time']:focus,
    form [type='url']:focus,
    form [type='color']:focus,
    form [type='checkbox']:focus,
    form [type='radio']:focus,
    form textarea:focus,
    form select:focus {
      border: 0.75px solid #2D2926;
      box-shadow: none; }
  form [type='checkbox'],
  form [type='radio'] {
    position: absolute;
    left: -999999999px;
    left: -200vw; }
    form [type='checkbox'] + label,
    form [type='radio'] + label {
      line-height: 17px;
      font-size: 16px;
      color: #727272;
      margin: 0;
      position: relative;
      display: flex;
      gap: 4px; }
      form [type='checkbox'] + label:before,
      form [type='radio'] + label:before {
        content: '';
        display: block;
        width: 27px;
        height: 27px;
        min-width: 27px;
        min-height: 27px;
        max-width: 27px;
        max-height: 27px;
        border: 0.75px solid #8D8D8D;
        border-radius: 2px;
        margin-top: -6px;
        transition: all 300ms ease-in-out; }
      form [type='checkbox'] + label:after,
      form [type='radio'] + label:after {
        content: '';
        width: 19px;
        height: 15px;
        display: block;
        opacity: 0;
        background: url("../images/icon-check.png") no-repeat center center;
        background-size: contain;
        transition: all 300ms ease-in-out;
        position: absolute;
        left: 4px;
        top: 0px; }
    form [type='checkbox']:checked + label:before,
    form [type='radio']:checked + label:before {
      border-color: #2D2926;
      background: #2D2926; }
    form [type='checkbox']:checked + label:after,
    form [type='radio']:checked + label:after {
      opacity: 1; }
  form select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background: white url("../images/icon-select-arrow.svg") no-repeat calc(100% - 16px) center; }
    form select + label {
      position: absolute !important;
      clip: rect(0, 0, 0, 0);
      overflow: hidden;
      border: 0 none !important;
      width: 1px !important;
      height: 1px !important;
      margin: -1px; }
  form textarea {
    height: 180px;
    resize: vertical;
    overflow: auto; }
  form .login-inputs {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    form .login-inputs button,
    form .login-inputs a {
      line-height: 17px;
      font-size: 14px;
      color: #727272; }
      form .login-inputs button:hover, form .login-inputs button:focus,
      form .login-inputs a:hover,
      form .login-inputs a:focus {
        text-decoration: underline; }
    form .login-inputs input[type='checkbox'] + label {
      line-height: 17px;
      font-size: 14px;
      color: #727272; }
    form .login-inputs input[type='checkbox'] + label:before {
      margin-top: -6px; }
    form .login-inputs input[type='checkbox'] + label:after {
      top: 0px; }

.search-form {
  display: inline-block;
  gap: unset;
  position: relative;
  margin-bottom: 0;
  width: 100%;
  max-width: 320px; }
  @media print, screen and (min-width: 30em) {
    .search-form {
      max-width: 520px; } }
  .search-form:after {
    content: '';
    display: block;
    width: 28px;
    height: 28px;
    background: url("../images/icon-search.png") no-repeat center center;
    background-size: contain;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%); }
  .search-form input[type="text"] {
    padding: 26px 20px;
    border: 1px solid #EDEDED;
    border-radius: 31px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #727272;
    width: 100%;
    min-width: 100%;
    text-align: center;
    margin-right: 0; }
    @media print, screen and (min-width: 30em) {
      .search-form input[type="text"] {
        font-size: 16px;
        color: #383637; } }
  .search-form input[type="submit"] {
    display: none; }

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #2D2926;
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s; }

input::placeholder {
  font-size: 14px;
  line-height: 24px;
  color: #6F6F6F;
  opacity: 1; }
  @media print, screen and (min-width: 30em) {
    input::placeholder {
      font-size: 16px; } }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type=number] {
  -moz-appearance: textfield; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }

.hamburger-bun:hover .hamburger-inner,
.hamburger.is-active .hamburger-inner,
.hamburger-bun:hover .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::before,
.hamburger-bun:hover .hamburger-inner::after,
.hamburger.is-active .hamburger-inner::after {
  background-color: #DA291C; }

.hamburger-box {
  width: 25px;
  height: 17px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%; }

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 25px;
  height: 2px;
  background-color: #292929;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease; }
  @media print, screen and (min-width: 30em) {
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      height: 3px; } }

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block; }

.hamburger-inner::before {
  top: -8px; }

.hamburger-inner::after {
  bottom: -8px; }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease; }

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease; }

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.button,
input[type="submit"] {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding: 16px 58px;
  margin-bottom: 0;
  position: relative;
  background-color: #2D2926;
  color: #fff;
  border-radius: 6px;
  border: solid 1px black;
  min-width: 191px;
  transition: all 300ms ease-in-out; }
  @media print, screen and (min-width: 30em) {
    .button,
    input[type="submit"] {
      min-width: unset;
      padding: 16px 41px;
      font-size: 16px; } }
  .button:hover, .button:focus,
  input[type="submit"]:hover,
  input[type="submit"]:focus {
    background: #727272;
    color: #fff;
    border-color: #727272; }
  .button.white,
  input[type="submit"].white {
    background-color: #fff;
    border-color: #fff;
    color: #2D2926; }
    .button.white:hover, .button.white:focus,
    input[type="submit"].white:hover,
    input[type="submit"].white:focus {
      background: #727272;
      color: white;
      border-color: #727272; }
  .button.hollow,
  input[type="submit"].hollow {
    background-color: #fff;
    color: #2D2926;
    border-color: #2D2926; }
    .button.hollow:hover, .button.hollow:focus,
    input[type="submit"].hollow:hover,
    input[type="submit"].hollow:focus {
      background: #727272;
      color: white;
      border-color: #727272; }
  .button.red,
  input[type="submit"].red {
    background: #DA291C;
    border-color: #DA291C; }
    .button.red:hover, .button.red:focus,
    input[type="submit"].red:hover,
    input[type="submit"].red:focus {
      background: #727272;
      color: white;
      border-color: #727272; }
  .button.to-top,
  input[type="submit"].to-top {
    margin-top: 36px; }

.button-group a:not(:first-child) {
  margin-top: 24px; }
  @media print, screen and (min-width: 30em) {
    .button-group a:not(:first-child) {
      margin-top: 0;
      margin-left: 24px; } }

.button-group.small {
  margin-bottom: 32px; }
  .button-group.small a {
    margin-top: 12px; }
  @media print, screen and (min-width: 30em) {
    .button-group.small a:not(:first-child) {
      margin-left: 12px; } }

.form-buttons-wrapper {
  display: grid;
  gap: 32px;
  margin-bottom: 32px; }
  @media print, screen and (min-width: 30em) {
    .form-buttons-wrapper {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin-bottom: 72px; } }
  .form-buttons-wrapper button.form-select {
    background: #FFFFFF;
    border: 1px solid transparent;
    border-radius: 24px;
    padding: 32px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: start; }
    .form-buttons-wrapper button.form-select strong {
      display: block;
      margin-bottom: 12px; }
    .form-buttons-wrapper button.form-select.active, .form-buttons-wrapper button.form-select:focus, .form-buttons-wrapper button.form-select:hover {
      border-color: #2D2926; }

.hero.home {
  margin-top: 100px;
  position: relative; }
  @media print, screen and (min-width: 30em) {
    .hero.home {
      margin-top: 50px; } }
  @media print, screen and (min-width: 53.125em) {
    .hero.home {
      margin-top: 74px; }
      .hero.home .row {
        flex-direction: row; }
      .hero.home .row-rev {
        flex-direction: row-reverse; } }
  .hero.home .slider-arrow {
    opacity: 0; }
  @media print, screen and (min-width: 53.125em) {
    .hero.home:hover .slider-arrow {
      opacity: 1; } }
  .hero.home .column, .hero.home .columns {
    align-self: center; }
  .hero.home .hero-content {
    text-align: center; }
    .hero.home .hero-content h1 {
      margin-bottom: 12px; }
      @media print, screen and (min-width: 30em) {
        .hero.home .hero-content h1 {
          margin-bottom: 23px; } }
    .hero.home .hero-content .hero-copy {
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 24px; }
      @media print, screen and (min-width: 30em) {
        .hero.home .hero-content .hero-copy {
          font-weight: 400;
          font-size: 22px;
          line-height: 34px;
          padding: 0 24px;
          margin-bottom: 32px; } }
  .hero.home img {
    width: 100%;
    height: 395px;
    object-fit: cover;
    border-radius: 6px;
    margin-bottom: 32px; }
    @media print, screen and (min-width: 53.125em) {
      .hero.home img {
        height: 810px;
        margin-bottom: 0; } }

.hero-generic {
  margin-top: 124px; }
  @media print, screen and (min-width: 53.125em) {
    .hero-generic {
      margin-top: 74px; } }
  .hero-generic h1 {
    margin-bottom: 12px; }
  @media print, screen and (min-width: 30em) {
    .hero-generic .copy {
      font-size: 24px;
      line-height: 32; } }
  .hero-generic .category {
    font-size: 20px;
    line-height: 1.2;
    color: #DA291C;
    margin-bottom: 12px; }
  .hero-generic .date {
    font-size: 18px;
    line-height: 1.2;
    color: #727272; }
  .hero-generic img.brand-hero {
    margin-bottom: 50px; }
  .hero-generic img.icon {
    margin-bottom: 18px; }
  .hero-generic .related-categories {
    margin-top: 32px; }
    .hero-generic .related-categories .related-title {
      font-size: 24px;
      line-height: 1.2;
      margin-bottom: 12px; }
    .hero-generic .related-categories .categories-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap; }
    .hero-generic .related-categories a.category {
      display: inline-block;
      padding: 8px 9px;
      border: 1px solid #727272;
      border-radius: 2px;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      color: #2D2926; }
      .hero-generic .related-categories a.category:hover, .hero-generic .related-categories a.category:focus {
        color: #fff;
        background: #2D2926;
        border-color: #2D2926; }
  .hero-generic .products-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 12px;
    margin-top: 12px; }
    @media print, screen and (min-width: 30em) {
      .hero-generic .products-wrapper {
        grid-template-columns: repeat(3, minmax(0, 107px));
        justify-content: space-around; } }

.hotspot-container {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  padding-bottom: 114.51613%;
  background-repeat: no-repeat !important;
  background-size: cover !important; }
  .hotspot-container .hotspot {
    position: absolute;
    display: block; }
    .hotspot-container .hotspot .icon {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      overflow: hidden;
      border: 1px solid #FFFFFF;
      background: rgba(0, 0, 0, 0.2);
      cursor: pointer;
      transition: all 0.3s ease; }
      .hotspot-container .hotspot .icon:before {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        background: white;
        position: absolute;
        border-radius: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease; }
      .hotspot-container .hotspot .icon:hover:before {
        width: 22.5px;
        height: 22.5px; }
    .hotspot-container .hotspot .content {
      position: absolute;
      padding: 16px;
      background: #FFFFFF;
      box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25);
      margin: 0;
      overflow: auto;
      min-width: 150px;
      opacity: 0;
      transition: opacity 0.1s ease 0s; }
      @media print, screen and (min-width: 30em) {
        .hotspot-container .hotspot .content {
          min-width: 200px; } }
      .hotspot-container .hotspot .content .hotspot-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 1;
        margin-bottom: 20px; }
      .hotspot-container .hotspot .content .desc {
        font-size: 16px;
        line-height: 1;
        margin-bottom: 24px; }
      .hotspot-container .hotspot .content .favourite path {
        transition: none; }
      .hotspot-container .hotspot .content .favourite:hover path, .hotspot-container .hotspot .content .favourite:focus path, .hotspot-container .hotspot .content .favourite.favourited path {
        fill: #DA291C;
        stroke: #DA291C; }
      .hotspot-container .hotspot .content a:hover svg path {
        fill: #75787B; }
    .hotspot-container .hotspot.open .icon:before {
      width: 30px;
      height: 30px; }
    .hotspot-container .hotspot.open .content {
      opacity: 1;
      transition: opacity 0.2s ease 0.2s; }
    .hotspot-container .hotspot.left-top .content {
      border-radius: 0px 6px 6px 6px;
      top: 45px;
      left: 50px; }
    .hotspot-container .hotspot.right-top .content {
      border-radius: 6px 0px 6px 6px;
      top: 45px;
      right: 5px; }
    .hotspot-container .hotspot.left-bottom .content {
      border-radius: 6px 6px 6px 0px;
      left: 50px;
      bottom: -5px; }
    .hotspot-container .hotspot.right-bottom .content {
      border-radius: 6px 6px 0px 6px;
      bottom: -5px;
      right: 5px; }

.card {
  border-radius: 0.125rem;
  background-color: #ffffff;
  display: block;
  position: relative;
  overflow: hidden;
  transition: all 300ms ease-in-out; }
  .card-approval:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: solid 2px rgba(217, 217, 217, 0.5);
    border-radius: 0.125rem;
    transition: all 300ms ease-in-out; }
  .card .row {
    position: relative;
    z-index: 10; }
  .card .inner-content {
    padding: 2rem 2.5rem; }
  .card-account {
    display: block;
    font-weight: 600;
    position: relative; }
    .card-account-image {
      display: flex; }
      @media screen and (max-width: 29.9375em) {
        .card-account-image {
          flex-wrap: wrap; } }
    .card-account .inner-image {
      top: 0;
      left: 2px;
      bottom: 0;
      right: 2px;
      background-size: contain !important; }
    .card-account textarea {
      height: 140px !important; }
    .card-account .top,
    .card-account .bottom {
      display: flex;
      flex-wrap: wrap; }
      .card-account .top > div,
      .card-account .bottom > div {
        padding: 0.5rem; }
      .card-account .top .first,
      .card-account .bottom .first {
        width: 25%; }
    .card-account .top {
      font-size: 16px;
      padding: 1.5rem; }
    .card-account .bottom {
      background-color: #F7F7F7;
      font-size: 15px;
      padding: 1rem 1.5rem;
      text-transform: uppercase; }
    .card-account .left {
      position: relative;
      width: 450px !important; }
      @media screen and (max-width: 29.9375em) {
        .card-account .left {
          width: 100%;
          max-width: 350px !important;
          min-height: 350px;
          margin: 0 auto; } }
    .card-account .right {
      width: 100%;
      font-size: 16px;
      padding: 2rem; }
    .card-account-delete .right {
      padding: 2rem 2rem 5rem; }
    .card-account .title {
      font-weight: 400;
      font-size: 0.6875rem;
      text-transform: uppercase; }
    .card-account .delete {
      position: absolute;
      bottom: 2rem;
      right: 2rem; }
      .card-account .delete img {
        width: 1rem; }
    .card-account .has-checkbox {
      padding-right: 120px; }
      .card-account .has-checkbox .checkbox {
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 2px;
        right: 6px;
        bottom: 2px;
        background-color: #F7F7F7;
        padding: 1.5rem 2rem !important; }
        .card-account .has-checkbox .checkbox label {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 !important;
          height: 1.5rem;
          width: 1.5rem; }
          .card-account .has-checkbox .checkbox label:before {
            position: static;
            transform: none;
            left: -30px;
            z-index: 10; }

.card.product {
  position: relative; }
  @media print, screen and (min-width: 30em) {
    .card.product {
      display: flex;
      flex-direction: column; } }
  .card.product .img-wrapper {
    height: 0;
    position: relative;
    padding-top: 100%;
    border-radius: 6px 6px 0px 0px;
    overflow: hidden; }
    .card.product .img-wrapper > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: absolute;
      top: 0;
      left: 0; }
  .card.product .product-images-slider {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }
    .card.product .product-images-slider > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: absolute;
      top: 0;
      left: 0; }
  .card.product .slider-arrow {
    display: none;
    left: 26.75px;
    opacity: 0; }
    .card.product .slider-arrow.next {
      left: unset;
      right: 26.75px; }
    @media print, screen and (min-width: 30em) {
      .card.product .slider-arrow {
        display: block; } }
    .card.product .slider-arrow:hover path:nth-child(2), .card.product .slider-arrow:focus path:nth-child(2) {
      fill: white; }
  .card.product .slider-counter {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    bottom: 12px;
    right: 12px;
    color: #727272;
    opacity: 1;
    transition: all 300ms ease-in-out; }
    @media print, screen and (min-width: 30em) {
      .card.product .slider-counter {
        opacity: 0;
        right: 27px; } }
    @media print, screen and (min-width: 53.125em) {
      .card.product .slider-counter {
        bottom: 110px; } }
  .card.product .content {
    padding: 20px 11px 20px 16px;
    background: #FFFFFF;
    border-radius: 0px 0px 6px 6px; }
    @media print, screen and (min-width: 30em) {
      .card.product .content {
        flex-grow: 1; } }
    @media print, screen and (min-width: 53.125em) {
      .card.product .content {
        position: relative;
        z-index: 2; } }
  .card.product .slick-dots {
    position: absolute;
    z-index: 3;
    width: 100%;
    margin: 0;
    bottom: 0; }
    .card.product .slick-dots button {
      width: 25px; }
  .card.product .variations-wrapper {
    background: #FFFFFF;
    padding: 17px 11px 17px 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%);
    transition: all 300ms ease-in-out; }
    .card.product .variations-wrapper a.variation {
      border: 1px solid #D9D9D6; }
      .card.product .variations-wrapper a.variation:hover, .card.product .variations-wrapper a.variation:focus {
        border-color: #DA291C; }
    .card.product .variations-wrapper img {
      width: 62px;
      height: 62px;
      object-fit: contain; }
    .card.product .variations-wrapper .remaining {
      font-weight: 400;
      font-size: 16px;
      color: #727272;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center; }
  .card.product .favourite {
    position: absolute;
    top: 12px;
    right: 12px; }
    @media print, screen and (min-width: 30em) {
      .card.product .favourite {
        top: 22px;
        right: 11px; } }
    .card.product .favourite path {
      transition: none; }
    .card.product .favourite:hover path, .card.product .favourite:focus path, .card.product .favourite.active path {
      fill: #DA291C;
      stroke: #DA291C; }
  .card.product .featured-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    color: #DA291C;
    margin-bottom: 6px;
    position: absolute;
    background: white;
    border-radius: 6px;
    padding: 8px;
    left: 20px;
    top: 20px;
    z-index: 1; }
    @media print, screen and (min-width: 30em) {
      .card.product .featured-title {
        font-size: 16px; } }
  .card.product .product-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    color: #2D2926; }
    @media print, screen and (min-width: 30em) {
      .card.product .product-title {
        font-size: 20px; } }
    .card.product .product-title:hover, .card.product .product-title:focus {
      text-decoration: underline; }
  .card.product .price {
    margin-top: 12px;
    font-size: 14px;
    line-height: 1; }
    @media print, screen and (min-width: 30em) {
      .card.product .price {
        font-size: 20px;
        line-height: 1.2; } }
  .card.product .category {
    margin-top: 12px;
    margin-bottom: 6px; }
  .card.product .category,
  .card.product .meta {
    font-size: 14px;
    line-height: 1;
    color: #727272; }
    @media print, screen and (min-width: 30em) {
      .card.product .category,
      .card.product .meta {
        font-size: 16px; } }
  .card.product:hover, .card.product:focus {
    cursor: pointer; }
    .card.product:hover .slick-arrow,
    .card.product:hover .slider-counter, .card.product:focus .slick-arrow,
    .card.product:focus .slider-counter {
      opacity: 1; }
    .card.product:hover .variations-wrapper, .card.product:focus .variations-wrapper {
      transform: translate(0, 0); }

.card.article {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-bottom: 32px !important; }
  @media print, screen and (min-width: 30em) {
    .card.article {
      display: block; } }
  @media print, screen and (min-width: 53.125em) {
    .card.article {
      margin-bottom: 90px !important; } }
  .card.article img {
    border-radius: 6px;
    width: 100%;
    height: 100%;
    object-fit: cover; }
    @media print, screen and (min-width: 30em) {
      .card.article img {
        margin-bottom: 32px;
        height: auto; } }
  .card.article .category {
    font-size: 18px;
    line-height: 1.2;
    color: #DA291C;
    margin-bottom: 12px; }
  .card.article h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.2; }
    @media print, screen and (min-width: 30em) {
      .card.article h3 {
        font-size: 24px;
        line-height: 1.25; } }
  .card.article .date {
    margin-top: 12px;
    font-size: 18px;
    line-height: 1.2;
    color: #727272; }
  .card.article:hover h3, .card.article:focus h3 {
    text-decoration: underline; }
  .card.article.white .content {
    background: #fff;
    opacity: 0;
    transition: all 300ms ease-in-out; }
  .card.article.white h3 {
    font-weight: 400;
    color: black; }
    @media print, screen and (min-width: 30em) {
      .card.article.white h3 {
        font-size: 36px;
        line-height: 1.2; } }
  .card.article.white .date,
  .card.article.white .category {
    font-size: 20px;
    color: #727272; }

.card.search .img-wrapper {
  height: 0;
  position: relative;
  padding-top: 81.34328%; }
  @media only screen and (min-width: 1400px) {
    .card.search .img-wrapper {
      padding-top: 60%; } }
  .card.search .img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0; }

.card.search .content {
  padding: 0 4px; }

.card.search .product-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #2D2926; }

.card.search .product-title,
.card.search .category {
  margin-bottom: 12px; }

.card.search .category,
.card.search .meta {
  font-size: 14px;
  line-height: 1;
  color: #727272; }

.card.search:hover .product-title, .card.search:focus .product-title {
  text-decoration: underline; }

.card.order {
  background: #FFFFFF;
  border-radius: 24px;
  padding: 32px;
  margin-bottom: 32px;
  position: relative;
  display: grid;
  gap: 24px; }
  @media print, screen and (min-width: 53.125em) {
    .card.order {
      gap: 42px;
      margin-bottom: 72px;
      grid-template-columns: 1fr 72px;
      align-items: center; } }
  .card.order .alert {
    position: absolute;
    top: -16px;
    right: -16px; }
  .card.order .inner-row {
    display: grid;
    gap: 24px;
    margin-bottom: 24px; }
    @media print, screen and (min-width: 30em) {
      .card.order .inner-row {
        padding-bottom: 24px;
        border-bottom: 1px solid #EEEEEE;
        grid-template-columns: repeat(3, minmax(0, 1fr)); } }
    .card.order .inner-row:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
      border: none; }
  .card.order .value.red {
    color: #DA291C; }
  .card.order .label {
    font-size: 16px; }
  .card.order .actions {
    display: flex;
    gap: 16px;
    align-items: flex-end; }
    .card.order .actions a {
      line-height: 1;
      position: relative; }
      .card.order .actions a:before {
        content: '';
        display: block;
        width: 43px;
        height: 43px;
        border-radius: 100%;
        background: transparent;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .card.order .actions a img {
        position: relative;
        z-index: 1; }
      .card.order .actions a:hover:before, .card.order .actions a:focus:before {
        background: #D9D9D9; }

.range-wrapper {
  margin-top: 30px; }

.slider {
  height: 2px;
  margin-top: 16px;
  margin-bottom: 16px;
  background: rgba(120, 120, 120, 0.2); }
  .slider .slider-fill {
    height: 2px;
    background: #727272; }
  .slider .slider-handle {
    background: white;
    height: 35px;
    width: 35px;
    border-radius: 100%; }

.slider-inputs {
  margin-top: 46px;
  display: flex;
  align-items: center;
  gap: 12px; }
  .slider-inputs label {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px; }
  .slider-inputs .range-input-wrapper {
    border: 0.5px solid #727272;
    border-radius: 2px;
    padding: 8px;
    position: relative; }
    .slider-inputs .range-input-wrapper input {
      margin: 0;
      background: transparent;
      border: none;
      box-shadow: none;
      height: auto;
      padding: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #272324; }
    .slider-inputs .range-input-wrapper span {
      position: absolute;
      top: 24px;
      right: 8px; }

.accordion {
  margin: 0; }

.accordion-title {
  border: none;
  font-weight: 600;
  font-size: 16px;
  color: #2D2926;
  background: transparent !important; }
  .accordion-title:before {
    content: '' !important;
    width: 12.67px;
    height: 6.33px;
    background: url("../images/accordion-arrow.png") no-repeat center center;
    background-size: contain;
    margin-top: -2px;
    transition: all 300ms ease-in-out; }

.accordion-content {
  border: none; }

.accordion-item:not(.is-active) > .accordion-title {
  border-bottom: 1px solid #D9D9D6; }

.accordion-item > .accordion-content {
  border-bottom: 1px solid #D9D9D6; }

.accordion-item.is-active > .accordion-title:before {
  transform: rotate(180deg); }

.accordion-item.is-active > .accordion-content {
  display: block !important; }

.slider-wrapper {
  opacity: 0;
  transition: all 300ms ease-in-out; }

.slider-wrapper.slick-initialized {
  opacity: 1; }

.slider-arrow {
  position: absolute;
  top: 50%;
  left: -15.5px;
  transform: translateY(-50%);
  transition: all 300ms ease-in-out; }
  .slider-arrow.next {
    left: unset;
    right: -15.5px; }
  .slider-arrow.slick-disabled {
    opacity: 0 !important; }
  .slider-arrow.slick-hidden {
    display: none !important;
    opacity: 0 !important; }
  .slider-arrow:hover svg path, .slider-arrow:focus svg path {
    fill: #75787B; }

.slick-dots {
  margin: 28px 0 0;
  list-style-type: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px; }
  .slick-dots li {
    height: 10px; }
  .slick-dots button {
    background: #D9D9D6;
    border-radius: 1px;
    width: 25px;
    height: 2px;
    text-indent: -99999px; }
  .slick-dots li.slick-active button,
  .slick-dots li:hover button,
  .slick-dots li:focus button {
    background: #DA291C; }
  @media print, screen and (min-width: 53.125em) {
    .slick-dots {
      margin: 20px 0 0; } }

section.content-blocks .column, section.content-blocks .columns {
  align-self: center;
  text-align: center; }
  @media print, screen and (min-width: 30em) {
    section.content-blocks .column, section.content-blocks .columns {
      text-align: left;
      align-self: stretch; }
      section.content-blocks .column:nth-child(2), section.content-blocks .columns:nth-child(2) {
        padding-top: 8.33333%;
        padding-bottom: 8.33333%; } }
  @media print, screen and (min-width: 53.125em) {
    section.content-blocks .column, section.content-blocks .columns {
      align-self: center; }
      section.content-blocks .column:nth-child(2), section.content-blocks .columns:nth-child(2) {
        padding-top: 0;
        padding-bottom: 0; } }

section.content-blocks .row {
  margin-bottom: 90px; }
  section.content-blocks .row:last-of-type {
    margin-bottom: 0; }
  @media print, screen and (min-width: 30em) {
    section.content-blocks .row {
      margin-bottom: 0; } }

section.content-blocks h2 {
  margin-bottom: 24px; }
  @media print, screen and (min-width: 30em) {
    section.content-blocks h2 {
      margin-bottom: 12px; } }

section.content-blocks a.button {
  margin-top: 24px; }
  @media print, screen and (min-width: 30em) {
    section.content-blocks a.button {
      margin-top: 12px; } }

section.content-blocks .img-wrapper {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 24px; }
  section.content-blocks .img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0; }
  @media print, screen and (min-width: 30em) {
    section.content-blocks .img-wrapper {
      border-radius: 0px;
      margin-bottom: 0;
      height: 100%;
      padding-top: 0; } }
  @media print, screen and (min-width: 53.125em) {
    section.content-blocks .img-wrapper {
      height: 0;
      padding-top: 100%; } }

@media print, screen and (min-width: 30em) {
  section.content-blocks:not(.reverse) .row:nth-child(odd) {
    flex-direction: row-reverse; }
    section.content-blocks:not(.reverse) .row:nth-child(odd) .column:last-child, section.content-blocks:not(.reverse) .row:nth-child(odd) .columns:last-child {
      margin-right: 5%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    section.content-blocks:not(.reverse) .row:nth-child(odd) .column:first-child, section.content-blocks:not(.reverse) .row:nth-child(odd) .columns:first-child {
      padding-left: 0; }
  section.content-blocks:not(.reverse) .row:nth-child(even) .column:last-child, section.content-blocks:not(.reverse) .row:nth-child(even) .columns:last-child {
    margin-left: 5%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  section.content-blocks:not(.reverse) .row:nth-child(even) .column:first-child, section.content-blocks:not(.reverse) .row:nth-child(even) .columns:first-child {
    padding-right: 0; }
  section.content-blocks.reverse .row:nth-child(even) {
    flex-direction: row-reverse; }
    section.content-blocks.reverse .row:nth-child(even) .column:last-child, section.content-blocks.reverse .row:nth-child(even) .columns:last-child {
      margin-right: 5%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    section.content-blocks.reverse .row:nth-child(even) .column:first-child, section.content-blocks.reverse .row:nth-child(even) .columns:first-child {
      padding-left: 0; }
  section.content-blocks.reverse .row:nth-child(odd) .column:last-child, section.content-blocks.reverse .row:nth-child(odd) .columns:last-child {
    margin-left: 5%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  section.content-blocks.reverse .row:nth-child(odd) .column:first-child, section.content-blocks.reverse .row:nth-child(odd) .columns:first-child {
    padding-right: 0; } }

section.locations .location {
  margin-bottom: 64px; }
  @media print, screen and (min-width: 53.125em) {
    section.locations .location {
      margin-bottom: 100px; } }

section.locations .img-wrapper {
  margin-bottom: 24px;
  position: relative;
  padding-top: 73.59155%; }
  section.locations .img-wrapper img {
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

section.locations .title {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 12px; }
  section.locations .title[data-toggle]:hover, section.locations .title[data-toggle]:focus {
    text-decoration: underline; }

section.locations img:hover, section.locations img:focus,
section.locations .title[data-toggle]:hover,
section.locations .title[data-toggle]:focus {
  cursor: pointer; }

section.locations a {
  color: #2D2926; }
  section.locations a:hover, section.locations a:focus {
    text-decoration: underline; }

section.locations .location-detail {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 4px; }
  section.locations .location-detail.highlight,
  section.locations .location-detail.highlight a {
    color: #0a6192; }

section.locations .location > a {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 8px; }
  section.locations .location > a:hover svg path:nth-child(1),
  section.locations .location > a:hover svg path:nth-child(3), section.locations .location > a:focus svg path:nth-child(1),
  section.locations .location > a:focus svg path:nth-child(3) {
    fill: #D9D9D6; }

section.product-categories {
  margin-top: 48px; }
  @media print, screen and (min-width: 53.125em) {
    section.product-categories {
      margin-top: 100px; } }
  section.product-categories .row {
    position: relative; }
  section.product-categories .text-link {
    text-align: center; }
    @media print, screen and (min-width: 30em) {
      section.product-categories .text-link {
        text-align: right; } }
    section.product-categories .text-link a {
      font-size: 16px;
      font-weight: 600;
      text-decoration-line: underline;
      color: #2D2926;
      margin-bottom: 24px;
      display: block; }
      section.product-categories .text-link a:hover, section.product-categories .text-link a:focus {
        color: #DA291C; }
  section.product-categories .slider-wrapper .slick-list {
    padding-right: 32px; }
  section.product-categories .slick-list {
    overflow: visible;
    margin-left: -7.5px; }
    @media print, screen and (min-width: 30em) {
      section.product-categories .slick-list {
        margin-left: -16px; } }
  section.product-categories .slick-slide {
    margin: 0 7.5px; }
    @media print, screen and (min-width: 30em) {
      section.product-categories .slick-slide {
        margin: 0 16px; } }
  section.product-categories .slider-arrow {
    opacity: 0; }
    @media print, screen and (min-width: 30em) {
      section.product-categories .slider-arrow {
        opacity: 1; } }
  section.product-categories .product-category {
    color: #2D2926; }
    section.product-categories .product-category .img-wrapper {
      border-radius: 6px;
      margin-bottom: 12px;
      overflow: hidden; }
    section.product-categories .product-category img {
      width: 100%;
      border-radius: 6px;
      aspect-ratio: 1/1;
      object-fit: cover;
      transition: all 300ms ease-in-out; }
    section.product-categories .product-category .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.015em; }
    section.product-categories .product-category .desc {
      font-size: 18px; }
    section.product-categories .product-category .promo {
      background: #DA291C;
      padding: 41px;
      border-radius: 6px;
      aspect-ratio: 1/1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end; }
      section.product-categories .product-category .promo .header {
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: #FFFFFF;
        margin-bottom: 36px; }
    section.product-categories .product-category:hover .title, section.product-categories .product-category:focus .title {
      text-decoration: underline; }
    section.product-categories .product-category:hover img, section.product-categories .product-category:focus img {
      transform: scale(1.1); }

.has-tip {
  border: none; }

.tooltip {
  background: #2D2926;
  border-radius: 6px;
  padding: 18px 24px;
  max-width: 325px; }

.tooltip-wrapper {
  display: flex;
  gap: 6px;
  align-items: center; }

.datepicker {
  display: none;
  position: absolute;
  padding: 4px;
  margin-top: 1px;
  direction: ltr; }

.datepicker.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  display: none;
  min-width: 160px;
  list-style: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  color: #333;
  font-size: 13px;
  line-height: 18px; }

.datepicker.dropdown-menu td,
.datepicker.dropdown-menu th {
  padding: 4px 5px; }

.datepicker table {
  border: 0;
  margin: 0;
  width: auto; }

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer; }

.datepicker td,
.datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  border: 0;
  font-size: 12px;
  padding: 4px 8px;
  cursor: pointer; }

.datepicker td {
  background: #fff; }

.datepicker td span.active,
.datepicker td.active.day,
.datepicker td.active.year {
  background: #2ba6cb; }

.datepicker td.new,
.datepicker td.old {
  color: #999; }

.datepicker td span.month.disabled,
.datepicker td span.year.disabled,
.datepicker td.day.disabled {
  color: #eee; }

.datepicker th {
  background: #fff; }

.datepicker th span.active,
.datepicker th.active.day,
.datepicker th.active.year {
  background: #2ba6cb; }

.datepicker th.date-switch {
  width: 145px; }

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle; }

.datepicker.days div.datepicker-days,
.datepicker.months div.datepicker-months,
.datepicker.years div.datepicker-years {
  display: block; }

.datepicker-dropdown:after,
.datepicker-dropdown:before {
  content: '';
  display: inline-block;
  position: absolute; }

.datepicker thead tr:first-child th {
  cursor: pointer; }

.datepicker thead tr:first-child th.cw {
  cursor: default;
  background-color: transparent; }

.datepicker tfoot tr:first-child th {
  cursor: pointer; }

.datepicker-inline {
  width: 220px; }

.datepicker-rtl {
  direction: rtl; }

.datepicker-rtl table tr td span {
  float: right; }

.datepicker-dropdown {
  top: 0;
  left: 0; }

.datepicker-dropdown:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: 1px solid rgba(0, 0, 0, 0.2);
  top: -7px;
  left: 6px; }

.datepicker-dropdown:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  top: -6px;
  left: 7px; }

.datepicker-dropdown::after,
.datepicker-dropdown::before,
.datepicker > div {
  display: none; }

.datepicker-close {
  position: absolute;
  top: -30px;
  right: 0;
  width: 15px;
  height: 30px;
  padding: 0;
  display: none; }

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent; }

ul.pagination {
  margin-bottom: 0;
  margin-top: 32px; }
  ul.pagination li,
  ul.pagination a,
  ul.pagination .current {
    font-size: 16px;
    line-height: 22px;
    padding: 0; }
  ul.pagination li {
    border-radius: 6px;
    overflow: hidden; }
    ul.pagination li a {
      background: #2D2926;
      color: white;
      padding: 4px 8px;
      min-width: 30px; }
      ul.pagination li a:hover, ul.pagination li a:focus {
        background: #727272; }
  ul.pagination .current {
    padding: 4px 8px;
    min-width: 30px;
    background-color: #DA291C; }

@font-face {
  font-family: 'Hurme Geometric Sans';
  src: url("../fonts/HurmeGeometricSans1-Regular.woff") format("woff"), url("../fonts/HurmeGeometricSans1-Regular.woff2") format("woff2"); }

@font-face {
  font-family: 'Hurme Geometric Sans';
  font-weight: 600;
  src: url("../fonts/HurmeGeometricSans1-SemiBold.woff") format("woff"), url("../fonts/HurmeGeometricSans1-SemiBold.woff2") format("woff2"); }

body,
.copy {
  font-family: "Hurme Geometric Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #292929; }
  @media print, screen and (min-width: 30em) {
    body,
    .copy {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px; } }
  body.small,
  .copy.small {
    font-size: 18px;
    line-height: 24px; }

.copy a {
  color: #0a6192;
  text-decoration: underline; }
  .copy a:hover, .copy a:focus {
    color: #2D2926; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Hurme Geometric Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #292929;
  font-style: normal;
  margin-bottom: 0; }
  h1.white,
  h2.white,
  h3.white,
  h4.white,
  h5.white,
  h6.white {
    color: #fff; }
  h1 .hl,
  h1 span,
  h2 .hl,
  h2 span,
  h3 .hl,
  h3 span,
  h4 .hl,
  h4 span,
  h5 .hl,
  h5 span,
  h6 .hl,
  h6 span {
    color: #DA291C; }

h1 {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 400; }
  @media print, screen and (min-width: 30em) {
    h1 {
      font-size: 46px; } }
  @media print, screen and (min-width: 53.125em) {
    h1 {
      font-size: 62px;
      line-height: 1.1; } }

h2 {
  font-weight: 400;
  font-size: 22px;
  line-height: 1.2; }
  @media print, screen and (min-width: 30em) {
    h2 {
      font-size: 46px;
      line-height: 1.2; } }

h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px; }
  @media print, screen and (min-width: 30em) {
    h3 {
      font-size: 22px;
      line-height: 30px; } }

.copy p {
  line-height: 1.4; }

.copy > p:last-child {
  margin-bottom: 0; }

.copy.white {
  color: #fff; }

strong {
  font-weight: 600; }

mark {
  color: #DA291C;
  background-color: transparent; }

@media screen and (max-width: 29.9375em) {
  br {
    display: none; } }

.template-homepage section.showcase h2 {
  margin-bottom: 12px; }

.template-homepage section.showcase .hotspot-container {
  margin-top: 24px; }
  @media print, screen and (min-width: 30em) {
    .template-homepage section.showcase .hotspot-container {
      margin-top: 50px; } }

.template-homepage section.help .help-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 46px; }
  @media print, screen and (min-width: 30em) {
    .template-homepage section.help .help-icon-wrapper {
      margin-top: 70px;
      border-right: 2px solid #D9D9D6;
      padding-right: 32px;
      min-height: 130px; } }
  .template-homepage section.help .help-icon-wrapper .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 30px; }
    @media print, screen and (min-width: 30em) {
      .template-homepage section.help .help-icon-wrapper .title {
        font-size: 24px; } }
  .template-homepage section.help .help-icon-wrapper a {
    font-size: 16px;
    line-height: 30px;
    color: #5B5B5B;
    text-decoration: underline; }
    .template-homepage section.help .help-icon-wrapper a:hover, .template-homepage section.help .help-icon-wrapper a:focus {
      color: #2D2926; }
    @media print, screen and (min-width: 30em) {
      .template-homepage section.help .help-icon-wrapper a {
        font-size: 20px; } }
  @media screen and (max-width: 29.9375em) {
    .template-homepage section.help .help-icon-wrapper .icon-wrapper {
      width: 60px;
      height: 48px;
      text-align: center; }
      .template-homepage section.help .help-icon-wrapper .icon-wrapper img {
        height: 100%; } }

.template-homepage section.help .column.large-3:last-of-type .help-icon-wrapper, .template-homepage section.help .large-3.columns:last-of-type .help-icon-wrapper {
  border: none; }

.template-homepage section.featured h2 {
  margin-bottom: 50px; }

.template-homepage section.featured h3 {
  margin-bottom: 12px; }

.template-homepage section.featured .button {
  margin-top: 24px; }

.template-homepage section.featured .featured-slider-old-wrapper .row {
  align-items: center; }
  @media print, screen and (min-width: 30em) {
    .template-homepage section.featured .featured-slider-old-wrapper .row {
      flex-direction: row-reverse;
      justify-content: start; } }

.template-homepage section.featured .cbm-container {
  position: relative; }

.template-homepage section.featured img {
  margin-bottom: 62px; }
  @media print, screen and (min-width: 30em) {
    .template-homepage section.featured img {
      margin-bottom: 0; } }

.template-homepage section.featured .large-5 {
  text-align: center; }
  @media print, screen and (min-width: 30em) {
    .template-homepage section.featured .large-5 {
      text-align: left; } }

.template-homepage section.featured .slider-arrow.next {
  right: 84.5px; }

.template-homepage section.featured .slider-arrow.prev {
  display: none !important; }

@media screen and (max-width: 53.0625em) {
  .template-homepage section.featured .slider-arrow {
    display: none !important; } }

.template-homepage section.stories .medium-6 {
  margin-top: 24px; }
  @media print, screen and (min-width: 30em) {
    .template-homepage section.stories .medium-6 {
      margin-top: 50px; } }

.template-homepage section.stories .img-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 125.78616%;
  position: relative;
  margin-bottom: 12px;
  border-radius: 6px;
  overflow: hidden; }
  @media print, screen and (min-width: 30em) {
    .template-homepage section.stories .img-wrapper {
      padding-bottom: 115.14085%;
      margin-bottom: 24px; } }
  .template-homepage section.stories .img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0; }

.template-homepage section.stories h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 12px; }
  @media print, screen and (min-width: 30em) {
    .template-homepage section.stories h3 {
      font-size: 20px;
      line-height: 28px; } }

.template-homepage section.stories a.button {
  margin-top: 12px; }

@media screen and (max-width: 29.9375em) {
  .template-homepage section.stories .stories-slider-wrapper .slick-list {
    padding-right: 48px; }
  .template-homepage section.stories .slick-list {
    overflow: visible;
    margin-left: -7.5px; }
  .template-homepage section.stories .slick-slide {
    margin: 24px 7.5px 0; } }

.template-homepage section.gallery .gallery-wrapper {
  column-count: 2;
  column-gap: 12px;
  margin-top: 24px; }
  @media print, screen and (min-width: 30em) {
    .template-homepage section.gallery .gallery-wrapper {
      column-count: 3;
      column-gap: 32px;
      margin-top: 50px; } }
  .template-homepage section.gallery .gallery-wrapper .gallery-item {
    margin-bottom: 12px; }
    @media print, screen and (min-width: 30em) {
      .template-homepage section.gallery .gallery-wrapper .gallery-item {
        margin-bottom: 32px; } }
    .template-homepage section.gallery .gallery-wrapper .gallery-item .hotspot-container {
      padding-bottom: 105.98592%; }
    .template-homepage section.gallery .gallery-wrapper .gallery-item.tall .hotspot-container {
      padding-bottom: 139.64912%; }
    .template-homepage section.gallery .gallery-wrapper .gallery-item .hotspot {
      display: none; }
      @media print, screen and (min-width: 53.125em) {
        .template-homepage section.gallery .gallery-wrapper .gallery-item .hotspot {
          display: block; } }

.template-homepage section.video .video-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  margin-top: 32px; }
  .template-homepage section.video .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%; }

.template-homepage section.videos .cbm-container {
  position: relative;
  padding-left: 12px;
  padding-right: 12px; }

.template-homepage section.videos .slider-arrow {
  opacity: 0;
  left: -20px;
  top: 0;
  transform: none;
  margin-top: -55px;
  padding-top: 15.27149%; }
  .template-homepage section.videos .slider-arrow.next {
    left: unset;
    right: -20px; }
  .template-homepage section.videos .slider-arrow:hover svg path:nth-child(2), .template-homepage section.videos .slider-arrow:focus svg path:nth-child(2) {
    fill: white; }
  @media print, screen and (min-width: 53.125em) {
    .template-homepage section.videos .slider-arrow {
      opacity: 1; } }

.template-homepage section.videos .video-wrapper iframe,
.template-homepage section.videos .video-wrapper video {
  aspect-ratio: 16/9;
  width: 100%;
  height: unset;
  margin-bottom: 22px;
  border-radius: 6px; }

.template-homepage section.videos .video-wrapper .title {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 22px; }

.template-homepage section.videos .video-wrapper .copy {
  font-size: 24px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.24px; }

.template-homepage section.videos .video-slider-wrapper .slick-list {
  margin: 0 -7.5px; }
  @media print, screen and (min-width: 30em) {
    .template-homepage section.videos .video-slider-wrapper .slick-list {
      margin: 0 -16px; } }

.template-homepage section.videos .video-slider-wrapper .slick-slide {
  margin: 0 7.5px; }
  @media print, screen and (min-width: 30em) {
    .template-homepage section.videos .video-slider-wrapper .slick-slide {
      margin: 0 16px; } }

.template-get-started h2 {
  font-weight: 600; }

.template-get-started section.discover {
  margin-top: 68px; }
  .template-get-started section.discover h1 {
    font-weight: 600;
    font-size: 48px;
    line-height: 1;
    margin-top: 36px; }
    @media print, screen and (min-width: 30em) {
      .template-get-started section.discover h1 {
        margin-top: 64px;
        font-size: 96px; } }
  .template-get-started section.discover .discovery-images {
    margin-top: 90px;
    position: relative;
    height: 600px; }
    @media print, screen and (min-width: 30em) {
      .template-get-started section.discover .discovery-images {
        margin-top: 132px;
        height: 1250px; } }
    .template-get-started section.discover .discovery-images img {
      border-radius: 6px;
      max-width: unset; }
    .template-get-started section.discover .discovery-images .caption {
      margin-top: 16px;
      font-size: 20px;
      line-height: 28px;
      display: none; }
      @media print, screen and (min-width: 30em) {
        .template-get-started section.discover .discovery-images .caption {
          display: block; } }
  .template-get-started section.discover .disc-wrap-1 {
    position: absolute;
    top: 96px;
    left: 0;
    z-index: 1; }
    .template-get-started section.discover .disc-wrap-1 img {
      width: 200px;
      height: 168px;
      object-fit: cover; }
    @media print, screen and (min-width: 30em) {
      .template-get-started section.discover .disc-wrap-1 {
        top: 162px;
        left: 8%; }
        .template-get-started section.discover .disc-wrap-1 img {
          width: 516px;
          height: 434px; } }
  .template-get-started section.discover .disc-wrap-2 {
    position: absolute;
    top: 30px;
    left: 60px;
    z-index: 4; }
    .template-get-started section.discover .disc-wrap-2 img {
      width: 168px;
      height: 262px;
      object-fit: cover; }
    @media print, screen and (min-width: 30em) {
      .template-get-started section.discover .disc-wrap-2 {
        top: 46px;
        left: 30%; }
        .template-get-started section.discover .disc-wrap-2 img {
          width: 378px;
          height: 590px; } }
  .template-get-started section.discover .disc-wrap-3 {
    position: absolute;
    top: 0;
    left: 120px;
    z-index: 2; }
    .template-get-started section.discover .disc-wrap-3 img {
      width: 198px;
      height: 283px;
      object-fit: cover; }
    @media print, screen and (min-width: 30em) {
      .template-get-started section.discover .disc-wrap-3 {
        top: 0;
        left: 50%;
        text-align: right; }
        .template-get-started section.discover .disc-wrap-3 img {
          width: 380px;
          height: 570px; } }
  .template-get-started section.discover .disc-wrap-4 {
    position: absolute;
    top: 176px;
    left: 150px;
    z-index: 3; }
    .template-get-started section.discover .disc-wrap-4 img {
      width: 198px;
      height: 149px;
      object-fit: cover; }
    @media print, screen and (min-width: 30em) {
      .template-get-started section.discover .disc-wrap-4 {
        top: 434px;
        left: 750px; }
        .template-get-started section.discover .disc-wrap-4 img {
          width: 361px;
          height: 272px; } }

.template-get-started section.find-need h2 {
  margin-bottom: 12px; }

.template-get-started section.find-need .copy {
  margin-bottom: 12px; }

.template-get-started section.find-need a {
  display: inline-block; }

.template-get-started section.find-need .find-slider-wrapper {
  margin-top: 55px;
  clip-path: inset(-100vw -100vw -100vw 0); }
  .template-get-started section.find-need .find-slider-wrapper .slick-track {
    display: flex;
    align-items: center; }
  .template-get-started section.find-need .find-slider-wrapper img {
    border-radius: 6px; }
  .template-get-started section.find-need .find-slider-wrapper .slick-list {
    overflow: visible; }
  .template-get-started section.find-need .find-slider-wrapper .slick-slide {
    margin: 0 16px; }

.template-get-started section.find-need .slider-buttons {
  margin-top: 20px;
  text-align: center; }

.template-get-started section.find-need .slider-arrow {
  display: none;
  position: relative;
  top: unset;
  left: unset;
  transform: unset; }
  @media print, screen and (min-width: 30em) {
    .template-get-started section.find-need .slider-arrow {
      display: inline-block; } }
  .template-get-started section.find-need .slider-arrow.slick-disabled {
    opacity: 1 !important; }
    .template-get-started section.find-need .slider-arrow.slick-disabled svg path {
      fill: #75787B; }

.template-get-started section.save h2 {
  margin-bottom: 48px; }
  @media print, screen and (min-width: 30em) {
    .template-get-started section.save h2 {
      margin-bottom: 113px; } }

.template-get-started section.save .products-wrapper {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 12px;
  margin-bottom: 48px;
  margin-top: 8px; }
  .template-get-started section.save .products-wrapper img:last-of-type {
    display: none; }
  @media print, screen and (min-width: 53.125em) {
    .template-get-started section.save .products-wrapper {
      grid-template-columns: repeat(5, minmax(0, 173px));
      justify-content: space-between;
      margin-bottom: 98px;
      margin-top: 0; }
      .template-get-started section.save .products-wrapper img:last-of-type {
        display: block; } }

.template-get-started section.save .button {
  margin-top: 26px; }
  @media print, screen and (min-width: 30em) {
    .template-get-started section.save .button {
      margin-top: 42px; } }

.template-get-started section.projects h2 {
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 24px; }
  .template-get-started section.projects h2 span {
    color: #2D2926; }
  .template-get-started section.projects h2 br {
    display: block; }
  @media print, screen and (min-width: 53.125em) {
    .template-get-started section.projects h2 {
      margin-bottom: 36px;
      font-size: 64px;
      line-height: 72px; } }

.template-get-started section.projects .column, .template-get-started section.projects .columns {
  align-self: center; }

.template-get-started section.projects .outer-slider-wrapper {
  max-width: 718px; }

.template-get-started section.projects .projects-slider-wrapper {
  margin-bottom: 24px; }
  @media print, screen and (min-width: 30em) {
    .template-get-started section.projects .projects-slider-wrapper {
      margin-bottom: 0;
      clip-path: inset(-100vw -100vw -100vw 0); } }
  .template-get-started section.projects .projects-slider-wrapper .slick-list {
    overflow: visible;
    margin: 0 -7.5px; }
    @media print, screen and (min-width: 30em) {
      .template-get-started section.projects .projects-slider-wrapper .slick-list {
        margin: 0 -16px; } }
  .template-get-started section.projects .projects-slider-wrapper .slick-slide {
    margin: 0 7.5px; }
    @media print, screen and (min-width: 30em) {
      .template-get-started section.projects .projects-slider-wrapper .slick-slide {
        margin: 0 16px; } }

.template-get-started section.projects .button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  margin-top: 32px; }

.template-get-started section.projects .slider-arrow {
  position: relative;
  top: unset;
  left: unset;
  right: unset;
  transform: none;
  opacity: 0 !important; }
  .template-get-started section.projects .slider-arrow:hover path:nth-child(2), .template-get-started section.projects .slider-arrow:focus path:nth-child(2) {
    fill: white; }
  @media print, screen and (min-width: 30em) {
    .template-get-started section.projects .slider-arrow {
      opacity: 1 !important; }
      .template-get-started section.projects .slider-arrow.slick-disabled {
        opacity: 1 !important; }
        .template-get-started section.projects .slider-arrow.slick-disabled svg path {
          fill: #727272; }
          .template-get-started section.projects .slider-arrow.slick-disabled svg path:nth-child(2) {
            fill: white; } }

.template-get-started section.projects .project {
  position: relative;
  border-radius: 6px;
  overflow: hidden; }
  @media screen and (max-width: 29.9375em) {
    .template-get-started section.projects .project {
      width: 228px;
      height: 349px; }
      .template-get-started section.projects .project img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }
  @media print, screen and (min-width: 30em) {
    .template-get-started section.projects .project {
      aspect-ratio: 718/788; } }
  .template-get-started section.projects .project .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    z-index: 1; }
    @media print, screen and (min-width: 30em) {
      .template-get-started section.projects .project .title {
        font-size: 48px;
        line-height: 36px; } }
  .template-get-started section.projects .project:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); }

@media print, screen and (min-width: 53.125em) {
  .template-get-started section.sign-up .column, .template-get-started section.sign-up .columns {
    align-self: center; } }

.template-get-started section.sign-up h2 {
  margin-bottom: 12px;
  font-size: 36px; }
  @media print, screen and (min-width: 53.125em) {
    .template-get-started section.sign-up h2 {
      font-size: 64px;
      line-height: 72px; } }
  .template-get-started section.sign-up h2 br {
    display: block; }

.template-get-started section.sign-up .subtitle {
  font-weight: 600;
  font-size: 36px;
  line-height: 40px; }
  @media print, screen and (min-width: 30em) {
    .template-get-started section.sign-up .subtitle {
      font-weight: 400;
      font-size: 24px;
      line-height: 34px; } }

.template-get-started section.sign-up img {
  margin-bottom: 36px; }
  @media print, screen and (min-width: 30em) {
    .template-get-started section.sign-up img {
      margin-bottom: 0; } }

.template-get-started section.sign-up form {
  margin-top: 42px;
  margin-bottom: 0; }
  @media print, screen and (min-width: 30em) {
    .template-get-started section.sign-up form {
      margin-top: 56px; } }
  .template-get-started section.sign-up form .form-copy {
    font-size: 16px;
    line-height: 21px;
    color: #383637; }
    @media print, screen and (min-width: 30em) {
      .template-get-started section.sign-up form .form-copy {
        text-align: center; } }
  .template-get-started section.sign-up form input[type="submit"] {
    width: 100%; }

.template-category-landing .current-category {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px; }
  .template-category-landing .current-category a {
    color: #2D2926; }
    .template-category-landing .current-category a:hover, .template-category-landing .current-category a:focus {
      text-decoration: underline; }
  .template-category-landing .current-category span {
    color: #DA291C; }

.template-category-landing .view-toggle,
.template-category-landing .unit-toggle {
  display: flex; }
  .template-category-landing .view-toggle button,
  .template-category-landing .unit-toggle button {
    padding: 12.5px 28.5px;
    background: white;
    border: 1px solid #8E8A87;
    font-weight: 600;
    font-size: 14px;
    line-height: 12px; }
    .template-category-landing .view-toggle button.first,
    .template-category-landing .unit-toggle button.first {
      border-radius: 6px 0px 0px 6px; }
    .template-category-landing .view-toggle button.last,
    .template-category-landing .unit-toggle button.last {
      border-radius: 0px 6px 6px 0px; }
    .template-category-landing .view-toggle button:hover, .template-category-landing .view-toggle button:active, .template-category-landing .view-toggle button.active,
    .template-category-landing .unit-toggle button:hover,
    .template-category-landing .unit-toggle button:active,
    .template-category-landing .unit-toggle button.active {
      border: 1px solid #DA291C;
      color: #DA291C; }

.template-category-landing .unit-toggle button {
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  border: 1px solid #727272;
  padding: 9px 24px;
  border-radius: 2px 0px 0px 2px;
  background: transparent; }
  .template-category-landing .unit-toggle button:last-of-type {
    border-radius: 0px 2px 2px 0px; }
  .template-category-landing .unit-toggle button:hover, .template-category-landing .unit-toggle button:active, .template-category-landing .unit-toggle button.active {
    border: 1px solid #2D2926; }

.template-category-landing .product-filters-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px; }

.template-category-landing button.product-filter {
  padding: 17px 19px 17px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.5px;
  color: #2D2926;
  position: relative; }
  @media print, screen and (min-width: 30em) {
    .template-category-landing button.product-filter {
      font-size: 14px;
      line-height: 18px;
      text-decoration: underline; } }
  .template-category-landing button.product-filter.more-filters {
    margin-left: auto; }
    @media print, screen and (min-width: 30em) {
      .template-category-landing button.product-filter.more-filters {
        margin-left: initial; } }
    .template-category-landing button.product-filter.more-filters:after {
      content: '';
      display: block;
      width: 16px;
      height: 14px;
      background: url("../images/icon-filters.svg") no-repeat center center;
      background-size: contain;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%); }
    .template-category-landing button.product-filter.more-filters:hover, .template-category-landing button.product-filter.more-filters:focus, .template-category-landing button.product-filter.more-filters.hover {
      color: #2D2926; }
      .template-category-landing button.product-filter.more-filters:hover:after, .template-category-landing button.product-filter.more-filters:focus:after, .template-category-landing button.product-filter.more-filters.hover:after {
        background: url("../images/icon-filters-active.svg") no-repeat center center;
        background-size: contain; }
  .template-category-landing button.product-filter:not(.more-filters):before, .template-category-landing button.product-filter:not(.more-filters):after {
    content: '';
    display: block;
    width: 13px;
    height: 1px;
    background: #2D2926;
    border-radius: 2px;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: center;
    transition: all 300ms ease-in-out; }
    @media print, screen and (min-width: 30em) {
      .template-category-landing button.product-filter:not(.more-filters):before, .template-category-landing button.product-filter:not(.more-filters):after {
        background-color: #727272;
        height: 2px; } }
  .template-category-landing button.product-filter:not(.more-filters):after {
    transform: translateY(-50%) rotate(90deg); }
  .template-category-landing button.product-filter:not(.more-filters):hover, .template-category-landing button.product-filter:not(.more-filters):focus {
    color: #2D2926; }
    .template-category-landing button.product-filter:not(.more-filters):hover:before, .template-category-landing button.product-filter:not(.more-filters):hover:after, .template-category-landing button.product-filter:not(.more-filters):focus:before, .template-category-landing button.product-filter:not(.more-filters):focus:after {
      background: #2D2926; }
  .template-category-landing button.product-filter:not(.more-filters).hover {
    color: #2D2926; }
    .template-category-landing button.product-filter:not(.more-filters).hover:after {
      transform: translateY(-50%);
      background: #DA291C; }

.template-category-landing .clear-filters button {
  margin-top: 14px; }

.template-category-landing .clear-filters button.clear {
  padding: 13px 16.5px 13px 27px;
  background: white;
  border-radius: 2px;
  position: relative;
  border: 1px solid transparent;
  font-weight: 400;
  font-size: 14px;
  line-height: 1; }
  .template-category-landing .clear-filters button.clear:before {
    position: absolute;
    left: 9px;
    top: 15px;
    content: '';
    width: 8px;
    height: 8px;
    display: block;
    background: url("../images/icon-clear.svg") no-repeat center center; }
  .template-category-landing .clear-filters button.clear:hover, .template-category-landing .clear-filters button.clear:focus {
    border: 1px solid #2D2926; }
  @media print, screen and (min-width: 30em) {
    .template-category-landing .clear-filters button.clear {
      font-weight: 600;
      font-size: 12px;
      padding: 8px 9px 8px 27px; }
      .template-category-landing .clear-filters button.clear:before {
        position: absolute;
        left: 9px;
        top: 10px;
        content: '';
        width: 8px;
        height: 8px;
        display: block;
        background: url("../images/icon-clear.svg") no-repeat center center; } }

.template-category-landing .clear-filters button.clear-all {
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  text-decoration: underline; }
  .template-category-landing .clear-filters button.clear-all:hover, .template-category-landing .clear-filters button.clear-all:focus {
    text-decoration: none; }
  @media print, screen and (min-width: 30em) {
    .template-category-landing .clear-filters button.clear-all {
      font-weight: 600;
      font-size: 12px; } }

.template-category-landing section.mobile-filters {
  background: white;
  padding-top: 20px;
  padding-bottom: 8px;
  z-index: 5; }
  @media print, screen and (min-width: 30em) {
    .template-category-landing section.mobile-filters {
      margin-top: 70px; } }
  .template-category-landing section.mobile-filters .view-toggle {
    justify-content: center;
    margin-bottom: 30px; }
    .template-category-landing section.mobile-filters .view-toggle button {
      padding: 8px 23px; }
  .template-category-landing section.mobile-filters .current-category {
    font-weight: 400; }

.template-category-landing section.products-filters {
  margin-top: 110px;
  background: white;
  padding-top: 16px;
  padding-bottom: 16px;
  z-index: 5; }
  .template-category-landing section.products-filters .product-filters-bar {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 20px; }
  .template-category-landing section.products-filters .filters-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 40px; }
    @media only screen and (min-width: 1400px) {
      .template-category-landing section.products-filters .filters-wrapper {
        gap: 72px; } }

.template-category-landing section.products-grid {
  margin-top: 0;
  background: white;
  padding-top: 24px;
  padding-bottom: 40px; }
  .template-category-landing section.products-grid .products-wrapper {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-bottom: 32px; }
    @media print, screen and (min-width: 30em) {
      .template-category-landing section.products-grid .products-wrapper {
        grid-template-columns: repeat(3, minmax(0, 1fr)); } }
    @media print, screen and (min-width: 53.125em) {
      .template-category-landing section.products-grid .products-wrapper {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        margin-bottom: 50px;
        gap: 32px; } }
    .template-category-landing section.products-grid .products-wrapper .card.product.featured {
      grid-column: span 2; }
      @media print, screen and (min-width: 30em) {
        .template-category-landing section.products-grid .products-wrapper .card.product.featured {
          grid-column: span 1; } }
  .template-category-landing section.products-grid .call-to-action {
    border-radius: 6px;
    background: #2D2926;
    padding: 42px 16px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .template-category-landing section.products-grid .call-to-action .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: white;
      margin-bottom: 24px; }
      @media print, screen and (min-width: 30em) {
        .template-category-landing section.products-grid .call-to-action .title {
          font-size: 24px;
          line-height: 28px; } }
    .template-category-landing section.products-grid .call-to-action:hover .title, .template-category-landing section.products-grid .call-to-action:focus .title {
      text-decoration: underline; }
    .template-category-landing section.products-grid .call-to-action:hover svg path, .template-category-landing section.products-grid .call-to-action:focus svg path {
      fill: #727272; }
    .template-category-landing section.products-grid .call-to-action:hover svg path:nth-child(2), .template-category-landing section.products-grid .call-to-action:focus svg path:nth-child(2) {
      fill: white; }
  .template-category-landing section.products-grid .viewed {
    margin-top: 24px;
    font-size: 16px;
    line-height: 22px; }

.template-category-landing section.shop-more {
  margin-top: 0;
  background: white;
  padding-top: 40px;
  padding-bottom: 40px; }
  @media print, screen and (min-width: 53.125em) {
    .template-category-landing section.shop-more {
      padding-top: 110px;
      padding-bottom: 90px; } }
  .template-category-landing section.shop-more .shop-more-wrapper {
    position: relative; }
    .template-category-landing section.shop-more .shop-more-wrapper .slider-arrow {
      width: 42px;
      height: 42px;
      left: -21px;
      opacity: 0; }
      .template-category-landing section.shop-more .shop-more-wrapper .slider-arrow:hover path:nth-child(2), .template-category-landing section.shop-more .shop-more-wrapper .slider-arrow:focus path:nth-child(2) {
        fill: white; }
      @media print, screen and (min-width: 30em) {
        .template-category-landing section.shop-more .shop-more-wrapper .slider-arrow {
          opacity: 1; } }
    .template-category-landing section.shop-more .shop-more-wrapper .slider-arrow.next {
      left: unset;
      right: -21px; }
  .template-category-landing section.shop-more .shop-more-slider-wrapper {
    margin-top: 50px;
    clip-path: inset(-100vw -100vw -100vw 0); }
    .template-category-landing section.shop-more .shop-more-slider-wrapper .slick-list {
      overflow: visible;
      margin: 0 -7.5px; }
      @media print, screen and (min-width: 30em) {
        .template-category-landing section.shop-more .shop-more-slider-wrapper .slick-list {
          margin: 0 -16px; } }
    .template-category-landing section.shop-more .shop-more-slider-wrapper .slick-slide {
      margin: 0 7.5px; }
      @media print, screen and (min-width: 30em) {
        .template-category-landing section.shop-more .shop-more-slider-wrapper .slick-slide {
          margin: 0 16px; } }
  .template-category-landing section.shop-more .more-category {
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    display: block; }
    @media screen and (max-width: 29.9375em) {
      .template-category-landing section.shop-more .more-category {
        width: 228px;
        height: 348px; }
        .template-category-landing section.shop-more .more-category img:not(.logo) {
          width: 100%;
          height: 100%;
          object-fit: cover; } }
    .template-category-landing section.shop-more .more-category img.logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 75%;
      object-fit: contain;
      z-index: 1; }
    .template-category-landing section.shop-more .more-category img:not(.logo) {
      width: 100%;
      height: 654px;
      max-height: 654px;
      object-fit: cover; }
    .template-category-landing section.shop-more .more-category .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 26px;
      text-align: center;
      color: #FFFFFF;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      z-index: 1; }
      @media print, screen and (min-width: 53.125em) {
        .template-category-landing section.shop-more .more-category .title {
          font-size: 48px;
          line-height: 1; } }
    .template-category-landing section.shop-more .more-category:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6); }
    .template-category-landing section.shop-more .more-category:hover .title, .template-category-landing section.shop-more .more-category:focus .title {
      text-decoration: underline; }

.template-category-landing .dropdown-pane {
  background: #F7F8F9;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);
  border-radius: 0px 24px 24px 24px;
  overflow: scroll;
  border: none;
  padding: 24px; }
  .template-category-landing .dropdown-pane form [type='checkbox'] + label,
  .template-category-landing .dropdown-pane form [type='radio'] + label {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    color: #2D2926;
    gap: 9px;
    align-items: center; }

.template-category-landing #more-filters-dropdown {
  position: absolute;
  left: 0px !important;
  right: 0;
  margin: auto;
  width: 100%;
  border-radius: 0px 0px 24px 24px;
  box-shadow: 0px 8px 8px 2px rgba(0, 0, 0, 0.25); }
  @media print, screen and (min-width: 53.125em) {
    .template-category-landing #more-filters-dropdown {
      max-width: 860px; } }
  .template-category-landing #more-filters-dropdown .more-filters-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 75px; }
  .template-category-landing #more-filters-dropdown .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 24px; }
  .template-category-landing #more-filters-dropdown form {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 14px 32px; }
    @media print, screen and (min-width: 30em) {
      .template-category-landing #more-filters-dropdown form {
        gap: 20px 32px; } }

.template-category-landing .brands-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 8px 24px; }
  .template-category-landing .brands-wrapper .brands-link {
    background: #FFFFFF;
    border: 1px solid #D9D9D6;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .template-category-landing .brands-wrapper .brands-link.active, .template-category-landing .brands-wrapper .brands-link:hover, .template-category-landing .brands-wrapper .brands-link:focus {
      border-color: #2D2926; }

.template-category-landing .length-wrapper {
  display: grid;
  gap: 24px; }
  @media print, screen and (min-width: 30em) {
    .template-category-landing .length-wrapper {
      grid-template-columns: 200px minmax(0, 1fr);
      gap: 32px; } }

.template-category-landing .lengths-grid {
  display: grid;
  gap: 14px 8px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding: 10px; }
  .template-category-landing .lengths-grid button {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    padding: 5px 12px;
    border: 0.5px solid #727272;
    border-radius: 2px; }
    .template-category-landing .lengths-grid button:hover, .template-category-landing .lengths-grid button:focus {
      background: #727272;
      color: white; }
    .template-category-landing .lengths-grid button.active {
      background: #727272;
      color: white;
      border-color: #2D2926; }

.template-category-landing .lengths-grid-mobile {
  display: grid;
  gap: 14px 8px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding: 10px; }
  .template-category-landing .lengths-grid-mobile button {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    padding: 5px 12px;
    border: 0.5px solid #727272;
    border-radius: 2px; }
    .template-category-landing .lengths-grid-mobile button:hover, .template-category-landing .lengths-grid-mobile button:focus {
      background: #727272;
      color: white; }
    .template-category-landing .lengths-grid-mobile button.active {
      background: #727272;
      color: white;
      border-color: #2D2926; }

.template-category-landing .swatch-group-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  margin-top: 18px; }

.template-category-landing .swatch-group {
  display: grid;
  gap: 24px;
  grid-template-columns: 50px 1fr;
  margin-bottom: 42px; }
  .template-category-landing .swatch-group:last-of-type {
    margin-bottom: 0; }

.template-category-landing .swatch-wrapper {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: flex-start; }
  .template-category-landing .swatch-wrapper .swatch {
    text-align: center; }
    .template-category-landing .swatch-wrapper .swatch .circle {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      margin-bottom: 10px;
      display: inline-block;
      position: relative; }
      .template-category-landing .swatch-wrapper .swatch .circle:after {
        content: '';
        display: block;
        width: 58px;
        height: 58px;
        border-radius: 100%;
        border: 1px solid transparent;
        position: absolute;
        top: -4px;
        left: -4px;
        transition: all 300ms ease-in-out; }
      @media print, screen and (min-width: 30em) {
        .template-category-landing .swatch-wrapper .swatch .circle {
          width: 65px;
          height: 65px; }
          .template-category-landing .swatch-wrapper .swatch .circle:after {
            width: 79px;
            height: 79px;
            top: -7px;
            left: -7px; } }
    .template-category-landing .swatch-wrapper .swatch .swatch-name {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #525456; }
      @media print, screen and (min-width: 30em) {
        .template-category-landing .swatch-wrapper .swatch .swatch-name {
          color: #2D2926; } }
    .template-category-landing .swatch-wrapper .swatch.active .circle:after, .template-category-landing .swatch-wrapper .swatch:hover .circle:after, .template-category-landing .swatch-wrapper .swatch:focus .circle:after {
      border-color: #2D2926; }

.template-category-landing #style-dropdown,
.template-category-landing #sort-dropdown,
.template-category-landing .single-dropdown {
  padding: 31px;
  height: 230px;
  width: 230px; }

.template-category-landing #length-dropdown {
  width: 650px; }
  @media only screen and (min-width: 1400px) {
    .template-category-landing #length-dropdown {
      width: 700px; } }

.template-category-landing #finishes-dropdown,
.template-category-landing .group-dropdown {
  width: 685px;
  height: 420px;
  overflow: hidden;
  padding-top: 16px; }
  .template-category-landing #finishes-dropdown .finishes-wrapper,
  .template-category-landing .group-dropdown .finishes-wrapper {
    display: grid;
    grid-template-columns: minmax(100px, auto) minmax(0, 1fr);
    gap: 0px;
    height: 100%; }
  .template-category-landing #finishes-dropdown .vertical.tabs,
  .template-category-landing #finishes-dropdown .tabs-content,
  .template-category-landing .group-dropdown .vertical.tabs,
  .template-category-landing .group-dropdown .tabs-content {
    padding-top: 8px;
    height: 100%;
    overflow: scroll; }

.template-category-landing .tabs,
.template-category-landing .tabs-content {
  border: none;
  background: transparent; }

.template-category-landing .tabs-title {
  margin-bottom: 28px; }
  .template-category-landing .tabs-title a {
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    color: #2D2926;
    padding: 0;
    padding-right: 10px; }
  .template-category-landing .tabs-title > a:focus,
  .template-category-landing .tabs-title > a:hover,
  .template-category-landing .tabs-title > a[aria-selected='true'] {
    background: transparent;
    color: #2D2926;
    font-weight: 600; }

.template-category-landing .tabs-panel {
  padding: 0; }

.template-category-landing .back-to-top {
  background: #F7F8F9;
  padding-bottom: 64px; }
  .template-category-landing .back-to-top .button {
    margin-top: 0; }

.template-category-landing footer {
  margin-top: 0; }

.template-product-single main {
  background: #fff; }

.template-product-single section.breadcrumbs {
  margin: 100px 0 0;
  padding-top: 32px; }
  @media print, screen and (min-width: 30em) {
    .template-product-single section.breadcrumbs {
      margin: 0; } }
  .template-product-single section.breadcrumbs .crumbs {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px; }
    .template-product-single section.breadcrumbs .crumbs a {
      color: #2D2926; }
    .template-product-single section.breadcrumbs .crumbs span {
      font-weight: 400; }
    @media print, screen and (min-width: 30em) {
      .template-product-single section.breadcrumbs .crumbs {
        font-weight: 600; } }

.template-product-single .product-wrapper-grid > div {
  margin-bottom: 24px; }

@media print, screen and (min-width: 30em) {
  .template-product-single .product-wrapper-grid {
    margin: 0 -12px; }
    .template-product-single .product-wrapper-grid > div {
      padding: 0 12px;
      margin-bottom: 0;
      width: 50%;
      max-width: 50%; }
    .template-product-single .product-wrapper-grid .product-gallery,
    .template-product-single .product-wrapper-grid .products-wrapper,
    .template-product-single .product-wrapper-grid .product-information {
      float: left; }
    .template-product-single .product-wrapper-grid .product-header,
    .template-product-single .product-wrapper-grid .product-ordering {
      float: right; } }

@media print, screen and (min-width: 53.125em) {
  .template-product-single .product-wrapper-grid .product-gallery,
  .template-product-single .product-wrapper-grid .products-wrapper,
  .template-product-single .product-wrapper-grid .product-information {
    width: 66.66667%;
    max-width: 66.66667%; }
  .template-product-single .product-wrapper-grid .product-header,
  .template-product-single .product-wrapper-grid .product-ordering {
    width: 33.33333%;
    max-width: 33.33333%; } }

.template-product-single section.product-details {
  margin-top: 0;
  padding-top: 32px; }
  .template-product-single section.product-details .product-gallery .slick-dots {
    margin-top: 12px; }
  @media print, screen and (min-width: 30em) {
    .template-product-single section.product-details .product-gallery {
      display: grid;
      gap: 30px;
      grid-template-columns: repeat(2, minmax(0, 1fr)); } }
  .template-product-single section.product-details .product-gallery .img-wrapper {
    border-radius: 6px;
    overflow: hidden; }
    .template-product-single section.product-details .product-gallery .img-wrapper img,
    .template-product-single section.product-details .product-gallery .img-wrapper video {
      border-radius: 6px;
      width: 100%;
      height: 375px;
      object-fit: cover; }
    @media print, screen and (min-width: 30em) {
      .template-product-single section.product-details .product-gallery .img-wrapper {
        height: 0;
        padding-top: 107.92254%;
        position: relative;
        width: 100%; }
        .template-product-single section.product-details .product-gallery .img-wrapper img,
        .template-product-single section.product-details .product-gallery .img-wrapper video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%; } }
  .template-product-single section.product-details .item-num {
    margin-top: 24px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px; }
    @media print, screen and (min-width: 30em) {
      .template-product-single section.product-details .item-num {
        margin-top: 12px;
        font-size: 18px;
        line-height: 24px; } }
  .template-product-single section.product-details .product-desc {
    margin-top: 12px;
    margin-bottom: 24px;
    line-height: 24px;
    color: #272324; }
  .template-product-single section.product-details button.product-specs {
    padding-top: 24px;
    padding-bottom: 24px;
    border-top: 1px solid #D0D0D0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px; }
    @media print, screen and (min-width: 30em) {
      .template-product-single section.product-details button.product-specs {
        font-size: 24px;
        line-height: 34px; } }
    .template-product-single section.product-details button.product-specs:last-of-type {
      border-bottom: 1px solid #D0D0D0; }
    .template-product-single section.product-details button.product-specs:hover svg path, .template-product-single section.product-details button.product-specs:focus svg path {
      fill: #75787B; }
      .template-product-single section.product-details button.product-specs:hover svg path:nth-child(2), .template-product-single section.product-details button.product-specs:focus svg path:nth-child(2) {
        fill: white; }
  .template-product-single section.product-details h1 {
    font-weight: 600;
    font-size: 26px;
    line-height: 28px;
    letter-spacing: 0.5px; }
    @media print, screen and (min-width: 30em) {
      .template-product-single section.product-details h1 {
        font-weight: 400;
        font-size: 36px;
        line-height: 1.2;
        letter-spacing: normal; } }
  .template-product-single section.product-details .brand-link {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 24px;
    text-decoration-line: underline;
    color: #2D2926; }
    .template-product-single section.product-details .brand-link:hover, .template-product-single section.product-details .brand-link:focus {
      color: #DA291C; }
  .template-product-single section.product-details .part-num {
    font-size: 20px;
    line-height: 1.2;
    color: #2D2926; }
  .template-product-single section.product-details .alt-part-num {
    font-size: 16px;
    line-height: 1.2;
    color: #2D2926; }
  .template-product-single section.product-details .pricing-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 12px; }
    .template-product-single section.product-details .pricing-wrapper .price {
      font-weight: 600;
      font-size: 20px;
      line-height: 1.2; }
      .template-product-single section.product-details .pricing-wrapper .price span.silver {
        color: #727272; }
      .template-product-single section.product-details .pricing-wrapper .price span.sale {
        color: #DA291C; }
      .template-product-single section.product-details .pricing-wrapper .price s {
        color: #727272; }
  .template-product-single section.product-details .pricing-notice,
  .template-product-single section.product-details .pricing-notice button,
  .template-product-single section.product-details .pricing-wrapper button {
    font-size: 20px;
    line-height: 26px;
    color: #727272; }
    @media print, screen and (min-width: 30em) {
      .template-product-single section.product-details .pricing-notice,
      .template-product-single section.product-details .pricing-notice button,
      .template-product-single section.product-details .pricing-wrapper button {
        font-size: 16px;
        line-height: 1;
        color: #2D2926; } }
  .template-product-single section.product-details .pricing-notice button,
  .template-product-single section.product-details .pricing-wrapper button {
    text-decoration: underline; }
    .template-product-single section.product-details .pricing-notice button:hover, .template-product-single section.product-details .pricing-notice button:focus,
    .template-product-single section.product-details .pricing-wrapper button:hover,
    .template-product-single section.product-details .pricing-wrapper button:focus {
      color: #DA291C; }
  .template-product-single section.product-details .create-account-cta,
  .template-product-single section.product-details .create-account-cta a {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px; }
  .template-product-single section.product-details .pricing-notice + .pricing-wrapper,
  .template-product-single section.product-details .create-account-cta + .pricing-wrapper {
    margin-top: 12px; }
  .template-product-single section.product-details hr {
    border-color: #D0D0D0;
    margin-top: 24px;
    margin-bottom: 24px; }
  .template-product-single section.product-details .variation-name,
  .template-product-single section.product-details .error-message {
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 12px; }
    .template-product-single section.product-details .variation-name.error,
    .template-product-single section.product-details .error-message.error {
      color: #DA291C; }
    .template-product-single section.product-details .variation-name span,
    .template-product-single section.product-details .error-message span {
      color: #727272; }
  .template-product-single section.product-details .error-message {
    margin-top: 12px;
    color: #DA291C; }
  .template-product-single section.product-details .finishes-wrapper {
    margin-top: 12px;
    position: relative; }
    .template-product-single section.product-details .finishes-wrapper .slick-prev {
      display: none !important; }
    .template-product-single section.product-details .finishes-wrapper .slider-arrow.next {
      right: 0; }
      .template-product-single section.product-details .finishes-wrapper .slider-arrow.next:hover svg path:nth-child(2), .template-product-single section.product-details .finishes-wrapper .slider-arrow.next:focus svg path:nth-child(2) {
        fill: white; }
    .template-product-single section.product-details .finishes-wrapper .slick-list {
      margin: 0 -3.5px; }
    .template-product-single section.product-details .finishes-wrapper .slick-slide {
      margin: 0 3.5px; }
    @media print, screen and (min-width: 30em) {
      .template-product-single section.product-details .finishes-wrapper {
        padding-right: 50px; } }
  .template-product-single section.product-details .finishes-slider .finish {
    border: 1.5px solid transparent;
    border-radius: 6px;
    overflow: hidden;
    background: white;
    position: relative; }
    .template-product-single section.product-details .finishes-slider .finish img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
    .template-product-single section.product-details .finishes-slider .finish:hover, .template-product-single section.product-details .finishes-slider .finish:focus {
      border-color: #D9D9D9; }
    .template-product-single section.product-details .finishes-slider .finish.active {
      border-color: #DA291C; }
  .template-product-single section.product-details .products-wrapper {
    margin-top: 12px;
    position: relative; }
    .template-product-single section.product-details .products-wrapper .slick-prev {
      display: none !important; }
    .template-product-single section.product-details .products-wrapper .slider-arrow.next {
      right: 0; }
      .template-product-single section.product-details .products-wrapper .slider-arrow.next:hover svg path:nth-child(2), .template-product-single section.product-details .products-wrapper .slider-arrow.next:focus svg path:nth-child(2) {
        fill: white; }
    .template-product-single section.product-details .products-wrapper .slick-list {
      margin: 0 -3.5px; }
    .template-product-single section.product-details .products-wrapper .slick-slide {
      margin: 0 3.5px; }
    @media print, screen and (min-width: 30em) {
      .template-product-single section.product-details .products-wrapper {
        padding-right: 50px; } }
  .template-product-single section.product-details .products-slider .product {
    border: 1.5px solid transparent;
    border-radius: 6px;
    overflow: hidden;
    background: white;
    position: relative; }
    .template-product-single section.product-details .products-slider .product img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
    .template-product-single section.product-details .products-slider .product:hover, .template-product-single section.product-details .products-slider .product:focus {
      border-color: #D9D9D9; }
    .template-product-single section.product-details .products-slider .product.active {
      border-color: #DA291C; }
  .template-product-single section.product-details .meta {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1; }
    .template-product-single section.product-details .meta > div {
      padding: 7px 7px 5px 0px; }
    .template-product-single section.product-details .meta .stock {
      padding: 7px 7px 5px 7px;
      background: #ECECEC; }
  .template-product-single section.product-details .unit-toggle {
    display: flex;
    margin-bottom: 24px; }
    .template-product-single section.product-details .unit-toggle button {
      padding: 9px 4px;
      min-width: 58px;
      border: 1px solid #727272;
      border-radius: 2px 0px 0px 2px;
      font-weight: 400;
      font-size: 12px;
      line-height: 1; }
      .template-product-single section.product-details .unit-toggle button:last-of-type {
        border-radius: 0px 2px 2px 0px; }
      .template-product-single section.product-details .unit-toggle button:hover, .template-product-single section.product-details .unit-toggle button:active {
        border-color: #DA291C; }
      .template-product-single section.product-details .unit-toggle button.active {
        border-color: #2D2926; }
  .template-product-single section.product-details .button-grid.small {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(2, minmax(0, 1fr)); }
    @media print, screen and (min-width: 30em) {
      .template-product-single section.product-details .button-grid.small {
        gap: 18px;
        grid-template-columns: repeat(3, minmax(0, 1fr)); } }
  .template-product-single section.product-details .button-grid.large {
    display: flex;
    gap: 12px;
    flex-wrap: wrap; }
    .template-product-single section.product-details .button-grid.large button {
      padding: 12px; }
    @media print, screen and (min-width: 30em) {
      .template-product-single section.product-details .button-grid.large {
        gap: 18px; } }
  .template-product-single section.product-details .button-grid button {
    position: relative;
    box-shadow: inset 1px 1px 0 0 #727272, inset -1px -1px 0 0 #727272;
    border-radius: 2px;
    padding: 16px 12px;
    overflow: hidden;
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    color: #727272; }
    @media print, screen and (min-width: 30em) {
      .template-product-single section.product-details .button-grid button {
        padding: 6px 12px; } }
    .template-product-single section.product-details .button-grid button:hover, .template-product-single section.product-details .button-grid button:focus {
      color: #DA291C;
      box-shadow: inset 2px 2px 0 0 #DA291C, inset -2px -2px 0 0 #DA291C; }
    .template-product-single section.product-details .button-grid button.active {
      color: #DA291C;
      box-shadow: inset 2px 2px 0 0 #DA291C, inset -2px -2px 0 0 #DA291C; }
    .template-product-single section.product-details .button-grid button.disabled {
      border-color: #727272;
      color: #727272;
      background: linear-gradient(to bottom right, transparent, transparent 48%, #D9D9D6 48%, #D9D9D6 52%, transparent 52%, transparent); }
  .template-product-single section.product-details a {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
    text-decoration-line: underline;
    color: #272324; }
    .template-product-single section.product-details a:hover, .template-product-single section.product-details a:focus {
      color: #DA291C; }
  .template-product-single section.product-details .cart-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 24px; }
    .template-product-single section.product-details .cart-buttons .favourite {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 63px;
      height: 52px;
      background: #FFFFFF;
      border: 1px solid #2D2926;
      border-radius: 6px; }
      .template-product-single section.product-details .cart-buttons .favourite svg path {
        transition: none; }
      .template-product-single section.product-details .cart-buttons .favourite:hover svg path, .template-product-single section.product-details .cart-buttons .favourite:focus svg path, .template-product-single section.product-details .cart-buttons .favourite.active svg path {
        stroke: #DA291C;
        fill: #DA291C; }
    .template-product-single section.product-details .cart-buttons .quantity {
      padding: 14px 15px;
      width: 86px;
      height: 52px;
      background: #FFFFFF;
      border: 1px solid #2D2926;
      border-radius: 6px;
      font-weight: 600;
      font-size: 16px;
      line-height: 1;
      color: #000000; }
    .template-product-single section.product-details .cart-buttons form {
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: 12px; }
      .template-product-single section.product-details .cart-buttons form input[type="submit"], .template-product-single section.product-details .cart-buttons form a {
        flex-grow: 1;
        padding: 16px 20px; }
      .template-product-single section.product-details .cart-buttons form a {
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        text-decoration-line: none;
        color: #fff; }
  .template-product-single section.product-details .mobile-add-to-cart {
    margin-top: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    background: #fff;
    z-index: 99; }
    .template-product-single section.product-details .mobile-add-to-cart input {
      width: 100%; }
  .template-product-single section.product-details .negative-pin-spacer {
    margin-top: -66px; }
    @media print, screen and (min-width: 30em) {
      .template-product-single section.product-details .negative-pin-spacer {
        margin-top: 0; } }
  .template-product-single section.product-details .ordering-info {
    border: 1px solid #D9D9D6;
    border-radius: 24px;
    padding: 15px 20px; }
    .template-product-single section.product-details .ordering-info button,
    .template-product-single section.product-details .ordering-info a {
      display: grid;
      gap: 24px;
      grid-template-columns: 26px 1fr 10px;
      text-align: left;
      width: 100%;
      margin-bottom: 24px;
      text-decoration: none; }
      .template-product-single section.product-details .ordering-info button:hover, .template-product-single section.product-details .ordering-info button:focus,
      .template-product-single section.product-details .ordering-info a:hover,
      .template-product-single section.product-details .ordering-info a:focus {
        color: #2D2926; }
      .template-product-single section.product-details .ordering-info button:last-child,
      .template-product-single section.product-details .ordering-info a:last-child {
        margin-bottom: 0; }
      .template-product-single section.product-details .ordering-info button .title,
      .template-product-single section.product-details .ordering-info a .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 1.2; }
      .template-product-single section.product-details .ordering-info button p,
      .template-product-single section.product-details .ordering-info a p {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 0; }
      .template-product-single section.product-details .ordering-info button:hover .title, .template-product-single section.product-details .ordering-info button:focus .title,
      .template-product-single section.product-details .ordering-info a:hover .title,
      .template-product-single section.product-details .ordering-info a:focus .title {
        text-decoration: underline; }

.template-product-single section.related-products {
  margin-top: 0;
  padding-top: 124px;
  padding-bottom: 60px; }
  @media print, screen and (min-width: 53.125em) {
    .template-product-single section.related-products {
      padding-top: 187px;
      padding-bottom: 150px; } }
  .template-product-single section.related-products .row {
    position: relative; }
  .template-product-single section.related-products h2 {
    margin-bottom: 50px; }
  .template-product-single section.related-products .related-wrapper {
    display: grid;
    gap: 24px; }
    @media print, screen and (min-width: 30em) {
      .template-product-single section.related-products .related-wrapper {
        grid-template-columns: repeat(2, minmax(0, 1fr)); } }
    @media print, screen and (min-width: 53.125em) {
      .template-product-single section.related-products .related-wrapper {
        grid-template-columns: repeat(4, minmax(0, 1fr)); } }
  .template-product-single section.related-products .product-category {
    align-self: flex-start; }
    .template-product-single section.related-products .product-category .inner {
      height: 0;
      padding-top: 100%;
      position: relative; }
    .template-product-single section.related-products .product-category .promo {
      background: #DA291C;
      padding: 41px;
      border-radius: 6px;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start; }
      .template-product-single section.related-products .product-category .promo .header {
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: #FFFFFF;
        margin-bottom: 36px; }

.template-product-single .button.to-top {
  margin-bottom: 60px; }

.template-product-single footer {
  margin-top: 0; }

.template-brand section.brand-details h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 12px; }
  @media print, screen and (min-width: 30em) {
    .template-brand section.brand-details h2 {
      margin-bottom: 24px;
      font-size: 64px;
      line-height: 72px; } }

.template-brand section.brand-details a.button {
  margin-top: 24px; }

.template-brand section.brand-details .column, .template-brand section.brand-details .columns {
  align-self: center;
  position: relative; }

.template-brand section.brand-details .projects-slider-wrapper {
  margin-bottom: 24px; }
  @media print, screen and (min-width: 30em) {
    .template-brand section.brand-details .projects-slider-wrapper {
      margin-top: 32px;
      margin-bottom: 0;
      clip-path: inset(-100vw -100vw -100vw 0); } }
  @media print, screen and (min-width: 53.125em) {
    .template-brand section.brand-details .projects-slider-wrapper {
      margin-top: 0; } }
  .template-brand section.brand-details .projects-slider-wrapper .slick-list {
    overflow: visible;
    margin: 0 -7.5px; }
    @media print, screen and (min-width: 30em) {
      .template-brand section.brand-details .projects-slider-wrapper .slick-list {
        margin: 0 -16px; } }
  .template-brand section.brand-details .projects-slider-wrapper .slick-slide {
    margin: 0 7.5px; }
    @media print, screen and (min-width: 30em) {
      .template-brand section.brand-details .projects-slider-wrapper .slick-slide {
        margin: 0 16px; } }

.template-brand section.brand-details .slider-arrow {
  left: -6px;
  opacity: 0; }
  .template-brand section.brand-details .slider-arrow:hover path:nth-child(2), .template-brand section.brand-details .slider-arrow:focus path:nth-child(2) {
    fill: white; }
  @media print, screen and (min-width: 30em) {
    .template-brand section.brand-details .slider-arrow {
      opacity: 1; } }

.template-brand section.brand-details .slider-arrow.next {
  left: unset;
  right: -6px; }

.template-brand section.brand-details .project {
  position: relative;
  border-radius: 6px;
  overflow: hidden; }
  @media screen and (max-width: 29.9375em) {
    .template-brand section.brand-details .project {
      width: 228px;
      height: 349px; }
      .template-brand section.brand-details .project img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }
  .template-brand section.brand-details .project img {
    width: 100%;
    max-height: 610px;
    object-fit: cover;
    aspect-ratio: 718/610; }
  .template-brand section.brand-details .project .fake-hotspot {
    position: absolute;
    left: 18px;
    bottom: 18px;
    z-index: 2; }
    .template-brand section.brand-details .project .fake-hotspot .content {
      padding: 16px;
      background: #FFFFFF;
      border-radius: 6px;
      min-width: 157px; }
      .template-brand section.brand-details .project .fake-hotspot .content .hotspot-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 1;
        margin-bottom: 12px; }
      .template-brand section.brand-details .project .fake-hotspot .content .desc {
        font-size: 16px;
        line-height: 1;
        margin-bottom: 24px; }
      .template-brand section.brand-details .project .fake-hotspot .content .favourite path {
        transition: none; }
      .template-brand section.brand-details .project .fake-hotspot .content .favourite:hover path, .template-brand section.brand-details .project .fake-hotspot .content .favourite:focus path, .template-brand section.brand-details .project .fake-hotspot .content .favourite.favourited path {
        fill: #DA291C;
        stroke: #DA291C; }
      .template-brand section.brand-details .project .fake-hotspot .content a:hover svg path {
        fill: #75787B; }
  .template-brand section.brand-details .project .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    z-index: 1; }
    @media print, screen and (min-width: 30em) {
      .template-brand section.brand-details .project .title {
        font-size: 48px;
        line-height: 36px; } }

.template-brand article {
  margin-top: 32px; }
  @media print, screen and (min-width: 53.125em) {
    .template-brand article {
      margin-top: 90px; } }
  .template-brand article .inner-article {
    margin: auto;
    gap: 20px 0;
    grid-template-rows: auto;
    grid-template-columns: 6% minmax(0, 1fr) 6%; }
    @media print, screen and (min-width: 30em) {
      .template-brand article .inner-article {
        grid-template-columns: 12.5% minmax(0, 1fr) 12.5%; } }
    .template-brand article .inner-article > *:first-child {
      margin-top: 0; }
    .template-brand article .inner-article > * {
      grid-column: 2 / -2; }
    .template-brand article .inner-article img,
    .template-brand article .inner-article .video-wrapper,
    .template-brand article .inner-article blockquote,
    .template-brand article .inner-article > figure,
    .template-brand article .inner-article .two-images,
    .template-brand article .inner-article .story-slider {
      grid-column: 1 / -1; }
    .template-brand article .inner-article .video-wrapper {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;
      margin-top: 32px; }
      .template-brand article .inner-article .video-wrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%; }
    .template-brand article .inner-article figure figcaption {
      font-size: 18px;
      line-height: 1.2; }
    @media print, screen and (min-width: 30em) {
      .template-brand article .inner-article figure {
        margin-top: 32px;
        margin-bottom: 32px; } }
    .template-brand article .inner-article img {
      width: 100%;
      border-radius: 6px;
      margin-bottom: 12px; }
    .template-brand article .inner-article h2 {
      margin-top: 24px;
      font-weight: 600;
      font-size: 24px;
      line-height: 1.2; }
    .template-brand article .inner-article h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 1.2; }
    .template-brand article .inner-article blockquote {
      border-left: 7px solid #DA291C;
      padding: 20px 0 20px 32px;
      margin-top: 32px;
      margin-bottom: 32px; }
      .template-brand article .inner-article blockquote p {
        color: #2D2926;
        font-size: 26px;
        line-height: 1.2; }
      .template-brand article .inner-article blockquote .author {
        font-size: 18px;
        line-height: 1.2;
        color: #2D2926; }
    .template-brand article .inner-article .two-images {
      display: grid;
      gap: 32px; }
      @media print, screen and (min-width: 30em) {
        .template-brand article .inner-article .two-images {
          grid-template-columns: repeat(2, minmax(0, 1fr)); } }
    .template-brand article .inner-article .story-slider {
      margin-top: 32px;
      position: relative; }
      .template-brand article .inner-article .story-slider .slider-arrow {
        display: none; }
        @media print, screen and (min-width: 30em) {
          .template-brand article .inner-article .story-slider .slider-arrow {
            margin-top: calc((788 / 1168 * 100%) + 32px);
            top: 0;
            right: 0;
            left: unset;
            transform: none; }
            .template-brand article .inner-article .story-slider .slider-arrow.slick-disabled {
              opacity: 1 !important; }
              .template-brand article .inner-article .story-slider .slider-arrow.slick-disabled path {
                stroke: #727272; }
              .template-brand article .inner-article .story-slider .slider-arrow.slick-disabled:hover, .template-brand article .inner-article .story-slider .slider-arrow.slick-disabled:focus {
                cursor: not-allowed; }
            .template-brand article .inner-article .story-slider .slider-arrow:hover path, .template-brand article .inner-article .story-slider .slider-arrow:focus path {
              stroke: #DA291C;
              fill: none; } }
      .template-brand article .inner-article .story-slider .prev {
        right: 50px; }
    .template-brand article .inner-article .slider-counter {
      display: none;
      position: absolute;
      right: 0;
      bottom: 12px;
      font-weight: 400;
      font-size: 16px;
      color: #727272; }
      @media print, screen and (min-width: 30em) {
        .template-brand article .inner-article .slider-counter {
          display: block; } }
    .template-brand article .inner-article .story-slider-wrapper h2 {
      margin-bottom: 20px; }
    .template-brand article .inner-article .story-slider-wrapper img {
      margin-bottom: 32px; }
      @media print, screen and (min-width: 30em) {
        .template-brand article .inner-article .story-slider-wrapper img {
          margin-bottom: 90px; } }
    .template-brand article .inner-article .story-slider-wrapper figure {
      margin-top: 0; }
    .template-brand article .inner-article .story-slider-wrapper figcaption {
      padding-left: 6%;
      padding-right: 6%; }
      @media print, screen and (min-width: 30em) {
        .template-brand article .inner-article .story-slider-wrapper figcaption {
          padding-left: 12.5%;
          padding-right: 12.5%; } }
    .template-brand article .inner-article .article-footer {
      margin-top: 32px;
      padding-top: 32px;
      border-top: 1px solid #000000; }
      @media print, screen and (min-width: 53.125em) {
        .template-brand article .inner-article .article-footer {
          margin-top: 64px;
          padding-top: 64px; } }
      .template-brand article .inner-article .article-footer h2 {
        margin-top: 0;
        margin-bottom: 12px; }
      .template-brand article .inner-article .article-footer .bio {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5; }

.template-brand section.gallery .gallery-wrapper {
  column-count: 2;
  column-gap: 12px;
  margin-top: 24px; }
  @media print, screen and (min-width: 30em) {
    .template-brand section.gallery .gallery-wrapper {
      column-count: 3;
      column-gap: 32px;
      margin-top: 0px; } }
  .template-brand section.gallery .gallery-wrapper .gallery-item {
    margin-bottom: 12px; }
    @media print, screen and (min-width: 30em) {
      .template-brand section.gallery .gallery-wrapper .gallery-item {
        margin-bottom: 32px; } }
    .template-brand section.gallery .gallery-wrapper .gallery-item .hotspot-container {
      padding-bottom: 105.98592%; }
    .template-brand section.gallery .gallery-wrapper .gallery-item.tall .hotspot-container {
      padding-bottom: 139.64912%; }
    .template-brand section.gallery .gallery-wrapper .gallery-item .hotspot {
      display: none; }
      @media print, screen and (min-width: 53.125em) {
        .template-brand section.gallery .gallery-wrapper .gallery-item .hotspot {
          display: block; } }

@media print, screen and (min-width: 30em) {
  .template-brand section.gallery .gallery-wrapper-first {
    margin-top: 50px !important; } }

.template-brand section.products-filters,
.template-brand section.mobile-filters {
  margin-top: 50px; }

.template-brand section.content-blocks {
  background: #fff;
  margin-top: 0;
  padding-bottom: 90px;
  padding-top: 90px; }
  @media print, screen and (min-width: 30em) {
    .template-brand section.content-blocks {
      padding-top: 150px; } }

.template-brand section.shop-more,
.template-brand .back-to-top {
  background: white; }

@media print, screen and (min-width: 53.125em) {
  .template-feature section.showcase {
    margin-top: 50px; } }

.template-feature section.showcase .hotspot-container {
  margin-top: 0;
  margin-bottom: 32px; }

@media print, screen and (min-width: 53.125em) {
  .template-feature section.featured-products {
    margin-top: 100px; } }

.template-feature section.featured-products .cbm-container {
  position: relative;
  padding-left: 12px;
  padding-right: 12px; }

.template-feature section.featured-products .slider-arrow {
  opacity: 0;
  left: -20px;
  top: calc(50% - 26.5px); }
  .template-feature section.featured-products .slider-arrow.next {
    left: unset;
    right: -20px; }
  .template-feature section.featured-products .slider-arrow:hover svg path:nth-child(2), .template-feature section.featured-products .slider-arrow:focus svg path:nth-child(2) {
    fill: white; }
  @media print, screen and (min-width: 53.125em) {
    .template-feature section.featured-products .slider-arrow {
      opacity: 1; } }

.template-feature section.featured-products .featured-products-slider-wrapper .slick-list {
  margin: 0 -7.5px; }
  @media print, screen and (min-width: 30em) {
    .template-feature section.featured-products .featured-products-slider-wrapper .slick-list {
      margin: 0 -16px; } }

.template-feature section.featured-products .featured-products-slider-wrapper .slick-slide {
  margin: 0 7.5px; }
  @media print, screen and (min-width: 30em) {
    .template-feature section.featured-products .featured-products-slider-wrapper .slick-slide {
      margin: 0 16px; } }

.template-feature section.featured-products a.feature {
  display: block;
  color: #2D2926; }
  .template-feature section.featured-products a.feature .img-wrapper {
    margin-bottom: 12px;
    height: 0;
    padding-top: 100%;
    position: relative; }
    .template-feature section.featured-products a.feature .img-wrapper img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain; }
      .template-feature section.featured-products a.feature .img-wrapper img.hover-focus {
        opacity: 0;
        transition: all 300ms ease-in-out;
        will-change: opacity;
        border-radius: 6px;
        object-fit: cover; }
  .template-feature section.featured-products a.feature .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2; }
  .template-feature section.featured-products a.feature .category {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2; }
  .template-feature section.featured-products a.feature:hover .title, .template-feature section.featured-products a.feature:focus .title {
    text-decoration: underline; }
  @media print, screen and (min-width: 53.125em) {
    .template-feature section.featured-products a.feature:hover img.hover-focus, .template-feature section.featured-products a.feature:focus img.hover-focus {
      opacity: 1; } }

.template-locations section.projects {
  margin-top: 90px; }
  @media print, screen and (min-width: 53.125em) {
    .template-locations section.projects {
      margin-top: 150px; }
      .template-locations section.projects .row {
        padding-bottom: 94.5px; } }
  .template-locations section.projects h2 {
    font-size: 36px;
    line-height: 1.2;
    font-weight: 600;
    color: #727272;
    transition: all 300ms ease-in-out; }
    .template-locations section.projects h2.active {
      color: #2D2926; }
    .template-locations section.projects h2:hover, .template-locations section.projects h2:focus {
      cursor: pointer;
      color: #2D2926; }
    @media print, screen and (min-width: 53.125em) {
      .template-locations section.projects h2 {
        font-size: 64px; } }
    .template-locations section.projects h2:last-of-type {
      margin-bottom: 24px; }
      @media print, screen and (min-width: 30em) {
        .template-locations section.projects h2:last-of-type {
          margin-bottom: 36px; } }
  .template-locations section.projects .column, .template-locations section.projects .columns {
    align-self: center; }
  .template-locations section.projects .outer-slider-wrapper {
    max-width: 718px;
    margin-top: 32px; }
    @media print, screen and (min-width: 30em) {
      .template-locations section.projects .outer-slider-wrapper {
        margin-top: 0;
        position: relative; } }
  .template-locations section.projects .projects-slider-wrapper {
    margin-bottom: 24px; }
    @media print, screen and (min-width: 30em) {
      .template-locations section.projects .projects-slider-wrapper {
        margin-bottom: 0;
        clip-path: inset(-100vw -100vw -100vw 0); } }
    .template-locations section.projects .projects-slider-wrapper .slick-list {
      overflow: visible;
      margin: 0 -7.5px; }
      @media print, screen and (min-width: 30em) {
        .template-locations section.projects .projects-slider-wrapper .slick-list {
          margin: 0 -17px; } }
    .template-locations section.projects .projects-slider-wrapper .slick-slide {
      margin: 0 7.5px; }
      @media print, screen and (min-width: 30em) {
        .template-locations section.projects .projects-slider-wrapper .slick-slide {
          margin: 0 16px; } }
  .template-locations section.projects .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin-top: 32px; }
    @media print, screen and (min-width: 53.125em) {
      .template-locations section.projects .button-wrapper {
        position: absolute;
        width: 100%; } }
  .template-locations section.projects .slider-arrow {
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    transform: none;
    opacity: 0 !important; }
    .template-locations section.projects .slider-arrow:hover path:nth-child(2), .template-locations section.projects .slider-arrow:focus path:nth-child(2) {
      fill: white; }
    @media print, screen and (min-width: 30em) {
      .template-locations section.projects .slider-arrow {
        opacity: 1 !important; }
        .template-locations section.projects .slider-arrow.slick-disabled {
          opacity: 1 !important; }
          .template-locations section.projects .slider-arrow.slick-disabled svg path {
            fill: #727272; }
            .template-locations section.projects .slider-arrow.slick-disabled svg path:nth-child(2) {
              fill: white; } }
  .template-locations section.projects .project {
    position: relative;
    border-radius: 6px;
    overflow: hidden; }
    @media screen and (max-width: 29.9375em) {
      .template-locations section.projects .project {
        width: 228px;
        height: 349px; } }
    @media print, screen and (min-width: 30em) {
      .template-locations section.projects .project {
        aspect-ratio: 718/788; } }
    .template-locations section.projects .project img {
      height: 100%;
      object-fit: cover; }
    .template-locations section.projects .project .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 1.2;
      text-align: center;
      color: #FFFFFF;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      z-index: 1; }
      @media print, screen and (min-width: 30em) {
        .template-locations section.projects .project .title {
          font-size: 48px; } }
    .template-locations section.projects .project:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6); }

@media print, screen and (min-width: 53.125em) {
  .template-story-landing section.featured-story .row {
    flex-direction: row-reverse;
    justify-content: flex-end; }
  .template-story-landing section.featured-story .column, .template-story-landing section.featured-story .columns {
    align-self: center; }
  .template-story-landing section.featured-story .large-4 {
    padding-right: 32px; } }

.template-story-landing section.featured-story .category {
  font-size: 20px;
  line-height: 1.2;
  color: #DA291C;
  margin-bottom: 10px; }

.template-story-landing section.featured-story .date {
  margin-top: 12px;
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 1.2;
  color: #727272; }

.template-story-landing section.featured-story img {
  margin-bottom: 24px;
  border-radius: 6px;
  width: 100%; }
  @media print, screen and (min-width: 53.125em) {
    .template-story-landing section.featured-story img {
      margin-bottom: 0; } }

.template-story-landing section.latest-articles {
  position: relative; }
  .template-story-landing section.latest-articles:after {
    content: '';
    display: block;
    width: 100%;
    height: 75%;
    background: #fff;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    pointer-events: none; }
  .template-story-landing section.latest-articles h2 {
    margin-bottom: 32px; }
    .template-story-landing section.latest-articles h2 span {
      color: #727272; }
    @media print, screen and (min-width: 30em) {
      .template-story-landing section.latest-articles h2 {
        text-align: center; } }
  .template-story-landing section.latest-articles .card.article img {
    margin-bottom: 24px; }
  .template-story-landing section.latest-articles .latest-wrapper {
    position: relative; }
    .template-story-landing section.latest-articles .latest-wrapper .slider-arrow {
      opacity: 0;
      top: 0;
      left: -21px;
      padding-top: calc(34.5177665% - 21px);
      transform: none; }
      .template-story-landing section.latest-articles .latest-wrapper .slider-arrow.next {
        left: unset;
        right: -21px; }
      @media print, screen and (min-width: 30em) {
        .template-story-landing section.latest-articles .latest-wrapper .slider-arrow {
          opacity: 1; } }
      .template-story-landing section.latest-articles .latest-wrapper .slider-arrow:hover path:nth-child(2), .template-story-landing section.latest-articles .latest-wrapper .slider-arrow:focus path:nth-child(2) {
        fill: white; }
  .template-story-landing section.latest-articles .slick-list {
    overflow: visible;
    margin: 0 -7.5px; }
    @media print, screen and (min-width: 30em) {
      .template-story-landing section.latest-articles .slick-list {
        margin: 0 -16px; } }
  .template-story-landing section.latest-articles .slick-slide {
    margin: 0 7.5px; }
    @media print, screen and (min-width: 30em) {
      .template-story-landing section.latest-articles .slick-slide {
        margin: 0 16px; } }
  .template-story-landing section.latest-articles .slick-current .content {
    opacity: 1 !important; }
  .template-story-landing section.latest-articles .slick-dots {
    transform: translateY(-32px); }

.template-story-landing section.articles-grid h2 {
  margin-bottom: 32px; }

.template-story-landing section.articles-grid .category-filters {
  margin-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 12px; }
  @media print, screen and (min-width: 53.125em) {
    .template-story-landing section.articles-grid .category-filters {
      border: 2px solid #EDEDED;
      border-radius: 31px;
      padding: 7px;
      gap: 28px;
      margin-bottom: 64px; } }
  .template-story-landing section.articles-grid .category-filters button {
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    color: #727272;
    min-width: 62px;
    padding: 12px 18px;
    border-radius: 31px;
    border: 1px solid #727272; }
    .template-story-landing section.articles-grid .category-filters button:hover, .template-story-landing section.articles-grid .category-filters button:focus {
      color: white;
      background: #727272; }
    @media print, screen and (min-width: 53.125em) {
      .template-story-landing section.articles-grid .category-filters button {
        border-color: transparent; } }
  .template-story-landing section.articles-grid .category-filters button.active {
    background: #DA291C;
    border-radius: 31px;
    color: white;
    padding: 12px 18px;
    border-color: #DA291C; }

.template-story-landing section.articles-grid .viewed {
  margin-top: 24px;
  font-size: 16px;
  line-height: 22px; }

@media print, screen and (min-width: 53.125em) {
  .template-story-single h1 {
    font-size: 48px; } }

.template-story-single section.featured-image img {
  border-radius: 6px;
  width: 100%; }

.template-story-single .post-social-wrapper {
  margin-top: 24px; }
  .template-story-single .post-social-wrapper .social-links {
    display: flex;
    gap: 12px;
    justify-content: center; }
  .template-story-single .post-social-wrapper a:hover svg path, .template-story-single .post-social-wrapper a:focus svg path {
    fill: #727272; }
  .template-story-single .post-social-wrapper.vertical {
    margin-top: 0; }
    .template-story-single .post-social-wrapper.vertical .social-links {
      flex-direction: column; }

.template-story-single article {
  margin-top: 32px; }
  @media print, screen and (min-width: 53.125em) {
    .template-story-single article {
      margin-top: 90px; } }
  .template-story-single article .inner-article {
    display: grid;
    gap: 20px 0;
    grid-template-rows: auto;
    grid-template-columns: 6% minmax(0, 1fr) 6%; }
    @media print, screen and (min-width: 30em) {
      .template-story-single article .inner-article {
        grid-template-columns: 12.5% minmax(0, 1fr) 12.5%; } }
    .template-story-single article .inner-article > *:first-child {
      margin-top: 0; }
    .template-story-single article .inner-article > * {
      grid-column: 2 / -2; }
    .template-story-single article .inner-article img,
    .template-story-single article .inner-article .video-wrapper,
    .template-story-single article .inner-article blockquote,
    .template-story-single article .inner-article > figure,
    .template-story-single article .inner-article .two-images,
    .template-story-single article .inner-article .story-slider {
      grid-column: 1 / -1; }
    .template-story-single article .inner-article .video-wrapper {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;
      margin-top: 32px; }
      .template-story-single article .inner-article .video-wrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%; }
    .template-story-single article .inner-article figure figcaption {
      font-size: 18px;
      line-height: 1.2; }
    @media print, screen and (min-width: 30em) {
      .template-story-single article .inner-article figure {
        margin-top: 32px;
        margin-bottom: 32px; } }
    .template-story-single article .inner-article img {
      width: 100%;
      border-radius: 6px;
      margin-bottom: 12px; }
    .template-story-single article .inner-article h2 {
      margin-top: 24px;
      font-weight: 600;
      font-size: 24px;
      line-height: 1.2; }
    .template-story-single article .inner-article h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 1.2; }
    .template-story-single article .inner-article blockquote {
      border-left: 7px solid #DA291C;
      padding: 20px 0 20px 32px;
      margin-top: 32px;
      margin-bottom: 32px; }
      .template-story-single article .inner-article blockquote p {
        color: #2D2926;
        font-size: 26px;
        line-height: 1.2; }
      .template-story-single article .inner-article blockquote .author {
        font-size: 18px;
        line-height: 1.2;
        color: #2D2926; }
    .template-story-single article .inner-article .two-images {
      display: grid;
      gap: 32px; }
      @media print, screen and (min-width: 30em) {
        .template-story-single article .inner-article .two-images {
          grid-template-columns: repeat(2, minmax(0, 1fr)); } }
    .template-story-single article .inner-article div.story-product {
      margin-top: 32px;
      position: relative; }
      .template-story-single article .inner-article div.story-product .slider-wrapper .slick-list {
        padding-right: 32px; }
      .template-story-single article .inner-article div.story-product .slick-list {
        margin-left: -7.5px; }
        @media print, screen and (min-width: 30em) {
          .template-story-single article .inner-article div.story-product .slick-list {
            margin-left: -16px; } }
      .template-story-single article .inner-article div.story-product .slick-slide {
        margin: 0 7.5px; }
        @media print, screen and (min-width: 30em) {
          .template-story-single article .inner-article div.story-product .slick-slide {
            margin: 0 16px; } }
      .template-story-single article .inner-article div.story-product .slider-arrow {
        opacity: 0;
        top: calc(50% - 70px);
        transform: none; }
        .template-story-single article .inner-article div.story-product .slider-arrow.prev {
          left: -31.5px; }
        @media print, screen and (min-width: 30em) {
          .template-story-single article .inner-article div.story-product .slider-arrow {
            opacity: 1; } }
      .template-story-single article .inner-article div.story-product .product-category {
        color: #2D2926;
        text-decoration: none; }
        .template-story-single article .inner-article div.story-product .product-category .img-wrapper {
          border-radius: 6px;
          margin-bottom: 12px;
          overflow: hidden; }
        .template-story-single article .inner-article div.story-product .product-category img {
          width: 100%;
          border-radius: 6px;
          aspect-ratio: 1/1;
          object-fit: cover;
          transition: all 300ms ease-in-out;
          margin-bottom: 0; }
        .template-story-single article .inner-article div.story-product .product-category .title {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          letter-spacing: 0.015em; }
        .template-story-single article .inner-article div.story-product .product-category .desc {
          font-size: 18px; }
        .template-story-single article .inner-article div.story-product .product-category .promo {
          background: #DA291C;
          padding: 41px;
          border-radius: 6px;
          aspect-ratio: 1/1;
          display: flex;
          flex-direction: column;
          justify-content: flex-end; }
          .template-story-single article .inner-article div.story-product .product-category .promo .header {
            font-weight: 600;
            font-size: 24px;
            line-height: 34px;
            color: #FFFFFF;
            margin-bottom: 36px;
            text-decoration: none; }
        .template-story-single article .inner-article div.story-product .product-category:hover .title, .template-story-single article .inner-article div.story-product .product-category:focus .title {
          text-decoration: underline; }
        .template-story-single article .inner-article div.story-product .product-category:hover img, .template-story-single article .inner-article div.story-product .product-category:focus img {
          transform: scale(1.1); }
    .template-story-single article .inner-article .story-slider {
      margin-top: 32px;
      position: relative; }
      .template-story-single article .inner-article .story-slider .slider-arrow {
        display: none; }
        @media print, screen and (min-width: 30em) {
          .template-story-single article .inner-article .story-slider .slider-arrow {
            margin-top: calc((788 / 1168 * 100%) + 32px);
            top: 0;
            right: 0;
            left: unset;
            transform: none; }
            .template-story-single article .inner-article .story-slider .slider-arrow.slick-disabled {
              opacity: 1 !important; }
              .template-story-single article .inner-article .story-slider .slider-arrow.slick-disabled path {
                stroke: #727272; }
              .template-story-single article .inner-article .story-slider .slider-arrow.slick-disabled:hover, .template-story-single article .inner-article .story-slider .slider-arrow.slick-disabled:focus {
                cursor: not-allowed; }
            .template-story-single article .inner-article .story-slider .slider-arrow:hover path, .template-story-single article .inner-article .story-slider .slider-arrow:focus path {
              stroke: #DA291C;
              fill: none; } }
      .template-story-single article .inner-article .story-slider .prev {
        right: 50px; }
    .template-story-single article .inner-article .slider-counter {
      display: none;
      position: absolute;
      right: 0;
      bottom: 12px;
      font-weight: 400;
      font-size: 16px;
      color: #727272; }
      @media print, screen and (min-width: 30em) {
        .template-story-single article .inner-article .slider-counter {
          display: block; } }
    .template-story-single article .inner-article .story-slider-wrapper h2 {
      margin-bottom: 20px; }
    .template-story-single article .inner-article .story-slider-wrapper img {
      margin-bottom: 32px; }
      @media print, screen and (min-width: 30em) {
        .template-story-single article .inner-article .story-slider-wrapper img {
          margin-bottom: 90px; } }
    .template-story-single article .inner-article .story-slider-wrapper figure {
      margin-top: 0; }
    .template-story-single article .inner-article .story-slider-wrapper figcaption {
      padding-left: 6%;
      padding-right: 6%; }
      @media print, screen and (min-width: 30em) {
        .template-story-single article .inner-article .story-slider-wrapper figcaption {
          padding-left: 12.5%;
          padding-right: 12.5%; } }
    .template-story-single article .inner-article .article-footer {
      margin-top: 32px;
      padding-top: 32px;
      border-top: 1px solid #000000; }
      @media print, screen and (min-width: 53.125em) {
        .template-story-single article .inner-article .article-footer {
          margin-top: 64px;
          padding-top: 64px; } }
      .template-story-single article .inner-article .article-footer h2 {
        margin-top: 0;
        margin-bottom: 12px; }
      .template-story-single article .inner-article .article-footer .bio {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5; }

.template-story-single section.articles-grid h2 {
  margin-bottom: 32px; }

.template-story-single section.gallery .gallery-wrapper {
  column-count: 2;
  column-gap: 12px;
  margin-top: 24px; }
  @media print, screen and (min-width: 30em) {
    .template-story-single section.gallery .gallery-wrapper {
      column-count: 3;
      column-gap: 32px;
      margin-top: 0px; } }
  .template-story-single section.gallery .gallery-wrapper .gallery-item {
    margin-bottom: 12px; }
    @media print, screen and (min-width: 30em) {
      .template-story-single section.gallery .gallery-wrapper .gallery-item {
        margin-bottom: 32px; } }
    .template-story-single section.gallery .gallery-wrapper .gallery-item .hotspot-container {
      padding-bottom: 105.98592%; }
    .template-story-single section.gallery .gallery-wrapper .gallery-item.tall .hotspot-container {
      padding-bottom: 139.64912%; }
    .template-story-single section.gallery .gallery-wrapper .gallery-item .hotspot {
      display: none; }
      @media print, screen and (min-width: 53.125em) {
        .template-story-single section.gallery .gallery-wrapper .gallery-item .hotspot {
          display: block; } }

@media print, screen and (min-width: 30em) {
  .template-story-single section.gallery .gallery-wrapper-first {
    margin-top: 50px !important; } }

.template-contact main {
  background: #fff; }

.template-contact section.hero {
  background: white;
  padding-bottom: 90px; }
  @media print, screen and (min-width: 53.125em) {
    .template-contact section.hero {
      padding-bottom: 138px; } }

.template-contact section.contact,
.template-contact section.register {
  margin-top: 50px; }
  .template-contact section.contact h2,
  .template-contact section.register h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 12px; }
  .template-contact section.contact form,
  .template-contact section.register form {
    background: #FFFFFF;
    border-radius: 24px;
    padding: 50px; }
    .template-contact section.contact form .checkbox-wrapper,
    .template-contact section.register form .checkbox-wrapper {
      display: grid;
      gap: 32px;
      margin-bottom: 24px; }
      @media print, screen and (min-width: 30em) {
        .template-contact section.contact form .checkbox-wrapper,
        .template-contact section.register form .checkbox-wrapper {
          grid-template-columns: repeat(2, minmax(0, 1fr));
          gap: 56px; } }
    .template-contact section.contact form .checkbox-wrapper-3,
    .template-contact section.register form .checkbox-wrapper-3 {
      display: grid;
      gap: 32px;
      margin-bottom: 24px; }
      @media print, screen and (min-width: 30em) {
        .template-contact section.contact form .checkbox-wrapper-3,
        .template-contact section.register form .checkbox-wrapper-3 {
          grid-template-columns: repeat(3, minmax(0, 1fr));
          gap: 56px; } }
    .template-contact section.contact form .checkbox-input label,
    .template-contact section.register form .checkbox-input label {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #2D2926;
      gap: 14px;
      align-items: flex-start; }
      .template-contact section.contact form .checkbox-input label strong,
      .template-contact section.register form .checkbox-input label strong {
        display: block;
        margin-bottom: 4px; }
        @media print, screen and (min-width: 30em) {
          .template-contact section.contact form .checkbox-input label strong,
          .template-contact section.register form .checkbox-input label strong {
            margin-bottom: 8px; } }
      .template-contact section.contact form .checkbox-input label a,
      .template-contact section.register form .checkbox-input label a {
        text-decoration: underline;
        color: #2D2926; }
        .template-contact section.contact form .checkbox-input label a:hover, .template-contact section.contact form .checkbox-input label a:focus,
        .template-contact section.register form .checkbox-input label a:hover,
        .template-contact section.register form .checkbox-input label a:focus {
          color: #DA291C; }
    .template-contact section.contact form .checkbox-input.small label,
    .template-contact section.register form .checkbox-input.small label {
      font-size: 16px;
      line-height: 1.5; }
  .template-contact section.contact .form-copy,
  .template-contact section.register .form-copy {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px; }

@media print, screen and (min-width: 53.125em) {
  .template-contact section.locations {
    padding-bottom: 150px; } }

.template-contact section.locations .location {
  margin-bottom: 32px; }
  .template-contact section.locations .location > a {
    margin-top: 0;
    text-decoration: underline; }

.template-contact section.register {
  margin-top: 0;
  padding-bottom: 60px; }
  @media print, screen and (min-width: 53.125em) {
    .template-contact section.register {
      padding-top: 150;
      padding-bottom: 150px; } }
  .template-contact section.register input[type='submit'] {
    margin-top: 12px; }
  .template-contact section.register .sectioned-form-wrapper {
    margin-top: 32px; }
    @media print, screen and (min-width: 30em) {
      .template-contact section.register .sectioned-form-wrapper {
        margin-top: 72px; } }
    .template-contact section.register .sectioned-form-wrapper h2 {
      margin-bottom: 10px; }
    .template-contact section.register .sectioned-form-wrapper form {
      padding: 0;
      background-color: transparent;
      border-radius: unset;
      gap: 0;
      display: block; }
      .template-contact section.register .sectioned-form-wrapper form .form-section {
        background: #FFFFFF;
        border-radius: 24px;
        padding: 32px;
        margin-bottom: 32px;
        display: grid;
        grid-gap: 32px; }
        .template-contact section.register .sectioned-form-wrapper form .form-section hr {
          margin: 0; }
        @media print, screen and (min-width: 30em) {
          .template-contact section.register .sectioned-form-wrapper form .form-section {
            padding: 50px; } }

.template-contact .button.to-top {
  margin-bottom: 60px; }

.template-contact footer {
  margin-top: 0; }

.template-thanks section.more a {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  display: block;
  margin-bottom: 24px; }
  .template-thanks section.more a img {
    width: 100%;
    max-height: 651px;
    object-fit: cover; }
  .template-thanks section.more a .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    z-index: 1; }
    @media print, screen and (min-width: 53.125em) {
      .template-thanks section.more a .title {
        font-size: 48px;
        line-height: 1; } }
  .template-thanks section.more a:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); }
  .template-thanks section.more a:hover .title, .template-thanks section.more a:focus .title {
    text-decoration: underline; }

.template-cart main,
.template-checkout main {
  background: #fff; }

.template-cart section.cart,
.template-cart section.checkout,
.template-checkout section.cart,
.template-checkout section.checkout {
  margin-top: 0;
  padding-top: 150px; }
  @media print, screen and (min-width: 30em) {
    .template-cart section.cart,
    .template-cart section.checkout,
    .template-checkout section.cart,
    .template-checkout section.checkout {
      padding-top: 66px; } }
  .template-cart section.cart .column:nth-child(2), .template-cart section.cart .columns:nth-child(2),
  .template-cart section.checkout .column:nth-child(2),
  .template-cart section.checkout .columns:nth-child(2),
  .template-checkout section.cart .column:nth-child(2),
  .template-checkout section.cart .columns:nth-child(2),
  .template-checkout section.checkout .column:nth-child(2),
  .template-checkout section.checkout .columns:nth-child(2) {
    margin-top: 32px; }
    @media print, screen and (min-width: 53.125em) {
      .template-cart section.cart .column:nth-child(2), .template-cart section.cart .columns:nth-child(2),
      .template-cart section.checkout .column:nth-child(2),
      .template-cart section.checkout .columns:nth-child(2),
      .template-checkout section.cart .column:nth-child(2),
      .template-checkout section.cart .columns:nth-child(2),
      .template-checkout section.checkout .column:nth-child(2),
      .template-checkout section.checkout .columns:nth-child(2) {
        margin-top: 0; } }
  .template-cart section.cart .link-w-arrow,
  .template-cart section.checkout .link-w-arrow,
  .template-checkout section.cart .link-w-arrow,
  .template-checkout section.checkout .link-w-arrow {
    display: grid;
    gap: 24px;
    grid-template-columns: max-content 42px;
    align-items: center;
    justify-content: end;
    color: #2D2926;
    text-decoration: underline; }
    .template-cart section.cart .link-w-arrow svg,
    .template-cart section.checkout .link-w-arrow svg,
    .template-checkout section.cart .link-w-arrow svg,
    .template-checkout section.checkout .link-w-arrow svg {
      transform: rotate(180deg); }
    .template-cart section.cart .link-w-arrow:hover svg circle:first-of-type,
    .template-cart section.cart .link-w-arrow:hover svg path, .template-cart section.cart .link-w-arrow:focus svg circle:first-of-type,
    .template-cart section.cart .link-w-arrow:focus svg path,
    .template-cart section.checkout .link-w-arrow:hover svg circle:first-of-type,
    .template-cart section.checkout .link-w-arrow:hover svg path,
    .template-cart section.checkout .link-w-arrow:focus svg circle:first-of-type,
    .template-cart section.checkout .link-w-arrow:focus svg path,
    .template-checkout section.cart .link-w-arrow:hover svg circle:first-of-type,
    .template-checkout section.cart .link-w-arrow:hover svg path,
    .template-checkout section.cart .link-w-arrow:focus svg circle:first-of-type,
    .template-checkout section.cart .link-w-arrow:focus svg path,
    .template-checkout section.checkout .link-w-arrow:hover svg circle:first-of-type,
    .template-checkout section.checkout .link-w-arrow:hover svg path,
    .template-checkout section.checkout .link-w-arrow:focus svg circle:first-of-type,
    .template-checkout section.checkout .link-w-arrow:focus svg path {
      fill: #D9D9D6; }
  .template-cart section.cart h2,
  .template-cart section.checkout h2,
  .template-checkout section.cart h2,
  .template-checkout section.checkout h2 {
    font-size: 36px;
    line-height: 1.2;
    margin-bottom: 36px; }
  .template-cart section.cart h3,
  .template-cart section.checkout h3,
  .template-checkout section.cart h3,
  .template-checkout section.checkout h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 12px; }
  .template-cart section.cart button.form-select,
  .template-cart section.checkout button.form-select,
  .template-checkout section.cart button.form-select,
  .template-checkout section.checkout button.form-select {
    flex-direction: row;
    align-items: center;
    gap: 30px; }
  .template-cart section.cart .inner-frame,
  .template-cart section.checkout .inner-frame,
  .template-checkout section.cart .inner-frame,
  .template-checkout section.checkout .inner-frame {
    background: #F9F9F9;
    border-radius: 24px;
    padding: 32px 16px; }
    @media print, screen and (min-width: 30em) {
      .template-cart section.cart .inner-frame.summary,
      .template-cart section.checkout .inner-frame.summary,
      .template-checkout section.cart .inner-frame.summary,
      .template-checkout section.checkout .inner-frame.summary {
        padding: 32px; } }
    .template-cart section.cart .inner-frame.shopping-cart hr,
    .template-cart section.checkout .inner-frame.shopping-cart hr,
    .template-checkout section.cart .inner-frame.shopping-cart hr,
    .template-checkout section.checkout .inner-frame.shopping-cart hr {
      margin-top: 32px; }
    .template-cart section.cart .inner-frame .details-grid,
    .template-cart section.checkout .inner-frame .details-grid,
    .template-checkout section.cart .inner-frame .details-grid,
    .template-checkout section.checkout .inner-frame .details-grid {
      font-weight: 400;
      font-size: 18px;
      line-height: 1.2;
      display: grid;
      grid-template-columns: max-content 1fr;
      gap: 8px 24px; }
      .template-cart section.cart .inner-frame .details-grid > .full,
      .template-cart section.checkout .inner-frame .details-grid > .full,
      .template-checkout section.cart .inner-frame .details-grid > .full,
      .template-checkout section.checkout .inner-frame .details-grid > .full {
        grid-column: 1 / -1; }
      .template-cart section.cart .inner-frame .details-grid form,
      .template-cart section.cart .inner-frame .details-grid .actions,
      .template-cart section.checkout .inner-frame .details-grid form,
      .template-cart section.checkout .inner-frame .details-grid .actions,
      .template-checkout section.cart .inner-frame .details-grid form,
      .template-checkout section.cart .inner-frame .details-grid .actions,
      .template-checkout section.checkout .inner-frame .details-grid form,
      .template-checkout section.checkout .inner-frame .details-grid .actions {
        grid-column: 1 / -1; }
        @media print, screen and (min-width: 53.125em) {
          .template-cart section.cart .inner-frame .details-grid form,
          .template-cart section.cart .inner-frame .details-grid .actions,
          .template-cart section.checkout .inner-frame .details-grid form,
          .template-cart section.checkout .inner-frame .details-grid .actions,
          .template-checkout section.cart .inner-frame .details-grid form,
          .template-checkout section.cart .inner-frame .details-grid .actions,
          .template-checkout section.checkout .inner-frame .details-grid form,
          .template-checkout section.checkout .inner-frame .details-grid .actions {
            grid-column: initial; } }
      .template-cart section.cart .inner-frame .details-grid .favourite,
      .template-cart section.checkout .inner-frame .details-grid .favourite,
      .template-checkout section.cart .inner-frame .details-grid .favourite,
      .template-checkout section.checkout .inner-frame .details-grid .favourite {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 43px;
        height: 32px; }
        .template-cart section.cart .inner-frame .details-grid .favourite svg path,
        .template-cart section.checkout .inner-frame .details-grid .favourite svg path,
        .template-checkout section.cart .inner-frame .details-grid .favourite svg path,
        .template-checkout section.checkout .inner-frame .details-grid .favourite svg path {
          transition: none; }
        .template-cart section.cart .inner-frame .details-grid .favourite:hover svg path, .template-cart section.cart .inner-frame .details-grid .favourite:focus svg path, .template-cart section.cart .inner-frame .details-grid .favourite.active svg path,
        .template-cart section.checkout .inner-frame .details-grid .favourite:hover svg path,
        .template-cart section.checkout .inner-frame .details-grid .favourite:focus svg path,
        .template-cart section.checkout .inner-frame .details-grid .favourite.active svg path,
        .template-checkout section.cart .inner-frame .details-grid .favourite:hover svg path,
        .template-checkout section.cart .inner-frame .details-grid .favourite:focus svg path,
        .template-checkout section.cart .inner-frame .details-grid .favourite.active svg path,
        .template-checkout section.checkout .inner-frame .details-grid .favourite:hover svg path,
        .template-checkout section.checkout .inner-frame .details-grid .favourite:focus svg path,
        .template-checkout section.checkout .inner-frame .details-grid .favourite.active svg path {
          stroke: #DA291C;
          fill: #DA291C; }
    .template-cart section.cart .inner-frame .inner-split,
    .template-cart section.checkout .inner-frame .inner-split,
    .template-checkout section.cart .inner-frame .inner-split,
    .template-checkout section.checkout .inner-frame .inner-split {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.2;
      margin-bottom: 24px; }
      .template-cart section.cart .inner-frame .inner-split.total,
      .template-cart section.checkout .inner-frame .inner-split.total,
      .template-checkout section.cart .inner-frame .inner-split.total,
      .template-checkout section.checkout .inner-frame .inner-split.total {
        font-size: 24px; }
    .template-cart section.cart .inner-frame hr,
    .template-cart section.checkout .inner-frame hr,
    .template-checkout section.cart .inner-frame hr,
    .template-checkout section.checkout .inner-frame hr {
      margin-top: 12px;
      margin-bottom: 32px;
      border-bottom: 1px solid #D0D0D0; }
    .template-cart section.cart .inner-frame .quantity,
    .template-cart section.checkout .inner-frame .quantity,
    .template-checkout section.cart .inner-frame .quantity,
    .template-checkout section.checkout .inner-frame .quantity {
      padding: 7.5px 15px;
      width: 86px;
      height: 39px;
      background: #FFFFFF;
      border: 1px solid #2D2926;
      border-radius: 6px;
      font-weight: 600;
      font-size: 16px;
      line-height: 1;
      color: #000000; }
    .template-cart section.cart .inner-frame .tag,
    .template-cart section.checkout .inner-frame .tag,
    .template-checkout section.cart .inner-frame .tag,
    .template-checkout section.checkout .inner-frame .tag {
      padding: 7.5px 15px;
      width: 200px;
      height: 39px;
      background: #FFFFFF;
      border: 1px solid #2D2926;
      border-radius: 6px;
      font-weight: 400;
      font-size: 16px;
      line-height: 1;
      color: #000000; }
    .template-cart section.cart .inner-frame .actions,
    .template-cart section.checkout .inner-frame .actions,
    .template-checkout section.cart .inner-frame .actions,
    .template-checkout section.checkout .inner-frame .actions {
      display: flex;
      gap: 24px;
      align-items: center;
      flex-wrap: wrap; }
      .template-cart section.cart .inner-frame .actions button,
      .template-cart section.checkout .inner-frame .actions button,
      .template-checkout section.cart .inner-frame .actions button,
      .template-checkout section.checkout .inner-frame .actions button {
        display: flex;
        gap: 8px;
        text-align: left; }
        .template-cart section.cart .inner-frame .actions button:hover, .template-cart section.cart .inner-frame .actions button:focus,
        .template-cart section.checkout .inner-frame .actions button:hover,
        .template-cart section.checkout .inner-frame .actions button:focus,
        .template-checkout section.cart .inner-frame .actions button:hover,
        .template-checkout section.cart .inner-frame .actions button:focus,
        .template-checkout section.checkout .inner-frame .actions button:hover,
        .template-checkout section.checkout .inner-frame .actions button:focus {
          text-decoration: underline; }
    .template-cart section.cart .inner-frame .cart-product,
    .template-cart section.cart .inner-frame .checkout-product,
    .template-cart section.checkout .inner-frame .cart-product,
    .template-cart section.checkout .inner-frame .checkout-product,
    .template-checkout section.cart .inner-frame .cart-product,
    .template-checkout section.cart .inner-frame .checkout-product,
    .template-checkout section.checkout .inner-frame .cart-product,
    .template-checkout section.checkout .inner-frame .checkout-product {
      display: grid;
      gap: 32px; }
      @media print, screen and (min-width: 30em) {
        .template-cart section.cart .inner-frame .cart-product,
        .template-cart section.cart .inner-frame .checkout-product,
        .template-cart section.checkout .inner-frame .cart-product,
        .template-cart section.checkout .inner-frame .checkout-product,
        .template-checkout section.cart .inner-frame .cart-product,
        .template-checkout section.cart .inner-frame .checkout-product,
        .template-checkout section.checkout .inner-frame .cart-product,
        .template-checkout section.checkout .inner-frame .checkout-product {
          grid-template-columns: 130px 1fr 100px; } }
      @media only screen and (min-width: 1920px) {
        .template-cart section.cart .inner-frame .cart-product,
        .template-cart section.cart .inner-frame .checkout-product,
        .template-cart section.checkout .inner-frame .cart-product,
        .template-cart section.checkout .inner-frame .checkout-product,
        .template-checkout section.cart .inner-frame .cart-product,
        .template-checkout section.cart .inner-frame .checkout-product,
        .template-checkout section.checkout .inner-frame .cart-product,
        .template-checkout section.checkout .inner-frame .checkout-product {
          gap: 132px; } }
      .template-cart section.cart .inner-frame .cart-product .details h3,
      .template-cart section.cart .inner-frame .cart-product .details h4,
      .template-cart section.cart .inner-frame .checkout-product .details h3,
      .template-cart section.cart .inner-frame .checkout-product .details h4,
      .template-cart section.checkout .inner-frame .cart-product .details h3,
      .template-cart section.checkout .inner-frame .cart-product .details h4,
      .template-cart section.checkout .inner-frame .checkout-product .details h3,
      .template-cart section.checkout .inner-frame .checkout-product .details h4,
      .template-checkout section.cart .inner-frame .cart-product .details h3,
      .template-checkout section.cart .inner-frame .cart-product .details h4,
      .template-checkout section.cart .inner-frame .checkout-product .details h3,
      .template-checkout section.cart .inner-frame .checkout-product .details h4,
      .template-checkout section.checkout .inner-frame .cart-product .details h3,
      .template-checkout section.checkout .inner-frame .cart-product .details h4,
      .template-checkout section.checkout .inner-frame .checkout-product .details h3,
      .template-checkout section.checkout .inner-frame .checkout-product .details h4 {
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2;
        margin-bottom: 12px; }
        .template-cart section.cart .inner-frame .cart-product .details h3 span.unit-count,
        .template-cart section.cart .inner-frame .cart-product .details h4 span.unit-count,
        .template-cart section.cart .inner-frame .checkout-product .details h3 span.unit-count,
        .template-cart section.cart .inner-frame .checkout-product .details h4 span.unit-count,
        .template-cart section.checkout .inner-frame .cart-product .details h3 span.unit-count,
        .template-cart section.checkout .inner-frame .cart-product .details h4 span.unit-count,
        .template-cart section.checkout .inner-frame .checkout-product .details h3 span.unit-count,
        .template-cart section.checkout .inner-frame .checkout-product .details h4 span.unit-count,
        .template-checkout section.cart .inner-frame .cart-product .details h3 span.unit-count,
        .template-checkout section.cart .inner-frame .cart-product .details h4 span.unit-count,
        .template-checkout section.cart .inner-frame .checkout-product .details h3 span.unit-count,
        .template-checkout section.cart .inner-frame .checkout-product .details h4 span.unit-count,
        .template-checkout section.checkout .inner-frame .cart-product .details h3 span.unit-count,
        .template-checkout section.checkout .inner-frame .cart-product .details h4 span.unit-count,
        .template-checkout section.checkout .inner-frame .checkout-product .details h3 span.unit-count,
        .template-checkout section.checkout .inner-frame .checkout-product .details h4 span.unit-count {
          color: #2D2926;
          font-weight: 400; }
      .template-cart section.cart .inner-frame .cart-product .price,
      .template-cart section.cart .inner-frame .checkout-product .price,
      .template-cart section.checkout .inner-frame .cart-product .price,
      .template-cart section.checkout .inner-frame .checkout-product .price,
      .template-checkout section.cart .inner-frame .cart-product .price,
      .template-checkout section.cart .inner-frame .checkout-product .price,
      .template-checkout section.checkout .inner-frame .cart-product .price,
      .template-checkout section.checkout .inner-frame .checkout-product .price {
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2;
        margin-bottom: 12px; }
    .template-cart section.cart .inner-frame .checkout-product,
    .template-cart section.checkout .inner-frame .checkout-product,
    .template-checkout section.cart .inner-frame .checkout-product,
    .template-checkout section.checkout .inner-frame .checkout-product {
      margin-bottom: 32px; }
      @media print, screen and (min-width: 30em) {
        .template-cart section.cart .inner-frame .checkout-product,
        .template-cart section.checkout .inner-frame .checkout-product,
        .template-checkout section.cart .inner-frame .checkout-product,
        .template-checkout section.checkout .inner-frame .checkout-product {
          grid-template-columns: minmax(132px, 1fr) 4fr;
          align-items: center; } }
      @media print, screen and (min-width: 53.125em) {
        .template-cart section.cart .inner-frame .checkout-product,
        .template-cart section.checkout .inner-frame .checkout-product,
        .template-checkout section.cart .inner-frame .checkout-product,
        .template-checkout section.checkout .inner-frame .checkout-product {
          gap: 160px; } }
    .template-cart section.cart .inner-frame a.button,
    .template-cart section.checkout .inner-frame a.button,
    .template-checkout section.cart .inner-frame a.button,
    .template-checkout section.checkout .inner-frame a.button {
      width: 100%;
      margin-top: 12px; }

.template-cart section.checkout,
.template-checkout section.checkout {
  padding-bottom: 60px; }
  @media print, screen and (min-width: 53.125em) {
    .template-cart section.checkout,
    .template-checkout section.checkout {
      padding-bottom: 150px; } }
  @media print, screen and (min-width: 53.125em) {
    .template-cart section.checkout .row,
    .template-checkout section.checkout .row {
      flex-direction: row-reverse; } }
  .template-cart section.checkout .column > hr, .template-cart section.checkout .columns > hr,
  .template-checkout section.checkout .column > hr,
  .template-checkout section.checkout .columns > hr {
    margin-top: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #D0D0D0; }
  .template-cart section.checkout .column > h3, .template-cart section.checkout .columns > h3,
  .template-checkout section.checkout .column > h3,
  .template-checkout section.checkout .columns > h3 {
    margin-top: 32px; }
  .template-cart section.checkout .inner-frame,
  .template-checkout section.checkout .inner-frame {
    padding: 32px; }
    .template-cart section.checkout .inner-frame hr,
    .template-checkout section.checkout .inner-frame hr {
      margin-top: 32px;
      margin-bottom: 32px; }
  .template-cart section.checkout .form-buttons-wrapper,
  .template-checkout section.checkout .form-buttons-wrapper {
    margin-bottom: 0; }
  .template-cart section.checkout .shipping .inner-split,
  .template-checkout section.checkout .shipping .inner-split {
    gap: 12px;
    align-items: flex-start;
    font-size: 20px; }
    @media print, screen and (min-width: 53.125em) {
      .template-cart section.checkout .shipping .inner-split,
      .template-checkout section.checkout .shipping .inner-split {
        gap: 20%;
        justify-content: flex-start; } }
  .template-cart section.checkout .pickup .pickup-title,
  .template-checkout section.checkout .pickup .pickup-title {
    margin-top: 42px;
    margin-bottom: 30px; }
  .template-cart section.checkout .pickup .checkbox-wrapper,
  .template-checkout section.checkout .pickup .checkbox-wrapper {
    display: grid;
    gap: 24px; }
    @media print, screen and (min-width: 30em) {
      .template-cart section.checkout .pickup .checkbox-wrapper,
      .template-checkout section.checkout .pickup .checkbox-wrapper {
        grid-template-columns: repeat(2, minmax(0, max-content));
        gap: 32px; } }
    @media print, screen and (min-width: 53.125em) {
      .template-cart section.checkout .pickup .checkbox-wrapper,
      .template-checkout section.checkout .pickup .checkbox-wrapper {
        gap: 80px; } }
  .template-cart section.checkout .address,
  .template-checkout section.checkout .address {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.2; }
    .template-cart section.checkout .address .name,
    .template-checkout section.checkout .address .name {
      color: #727272; }
    .template-cart section.checkout .address img,
    .template-checkout section.checkout .address img {
      display: block; }
  .template-cart section.checkout .additional-info form,
  .template-checkout section.checkout .additional-info form {
    margin-top: 12px; }
  .template-cart section.checkout button.button,
  .template-checkout section.checkout button.button {
    text-align: center;
    width: 100%;
    margin-top: 32px; }
  @media print, screen and (min-width: 53.125em) {
    .template-cart section.checkout form,
    .template-checkout section.checkout form {
      max-width: 420px; } }
  .template-cart section.checkout form [type='checkbox'] + label,
  .template-cart section.checkout form [type='radio'] + label,
  .template-checkout section.checkout form [type='checkbox'] + label,
  .template-checkout section.checkout form [type='radio'] + label {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: #2D2926;
    gap: 10px; }
  .template-cart section.checkout form input[type='submit'],
  .template-checkout section.checkout form input[type='submit'] {
    width: 100%; }
  .template-cart section.checkout form .cc-button label:before,
  .template-cart section.checkout form .cc-button label:after,
  .template-checkout section.checkout form .cc-button label:before,
  .template-checkout section.checkout form .cc-button label:after {
    content: unset;
    display: none; }
  .template-cart section.checkout form .cc-button label,
  .template-checkout section.checkout form .cc-button label {
    font-weight: 400;
    font-size: 20px;
    gap: 32px;
    border: 1px solid #D9D9D6;
    border-radius: 24px;
    padding: 28px;
    align-items: center;
    transition: all 300ms ease-in-out; }
    .template-cart section.checkout form .cc-button label .expiry,
    .template-checkout section.checkout form .cc-button label .expiry {
      font-size: 18px;
      display: block; }
    .template-cart section.checkout form .cc-button label:hover, .template-cart section.checkout form .cc-button label:focus,
    .template-checkout section.checkout form .cc-button label:hover,
    .template-checkout section.checkout form .cc-button label:focus {
      border-color: #2D2926; }
  .template-cart section.checkout form .cc-button input:checked + label,
  .template-checkout section.checkout form .cc-button input:checked + label {
    border-color: #2D2926; }
  .template-cart section.checkout .confirmation .inner-split,
  .template-checkout section.checkout .confirmation .inner-split {
    display: grid;
    gap: 32px;
    align-items: start; }
    @media print, screen and (min-width: 30em) {
      .template-cart section.checkout .confirmation .inner-split,
      .template-checkout section.checkout .confirmation .inner-split {
        gap: 60px;
        grid-template-columns: minmax(275px, 1fr) 4fr; } }
  .template-cart section.checkout .button-wrapper,
  .template-checkout section.checkout .button-wrapper {
    margin-top: 32px;
    display: grid;
    gap: 32px; }
    @media print, screen and (min-width: 30em) {
      .template-cart section.checkout .button-wrapper,
      .template-checkout section.checkout .button-wrapper {
        grid-template-columns: repeat(2, minmax(0, 1fr)); } }

.template-cart section.product-categories,
.template-checkout section.product-categories {
  margin-top: 0;
  padding-top: 90px;
  padding-bottom: 60px; }
  @media print, screen and (min-width: 53.125em) {
    .template-cart section.product-categories,
    .template-checkout section.product-categories {
      padding-top: 150;
      padding-bottom: 150px; } }
  .template-cart section.product-categories h2,
  .template-checkout section.product-categories h2 {
    margin-bottom: 32px; }

.template-cart .button.to-top,
.template-checkout .button.to-top {
  margin-bottom: 60px; }

.template-cart footer,
.template-checkout footer {
  margin-top: 0; }

.template-cart .datepicker-dropdown,
.template-checkout .datepicker-dropdown {
  background: #FFF;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);
  border-radius: 0px 24px 24px 24px;
  overflow: scroll;
  border: none;
  padding: 22px 24px; }
  .template-cart .datepicker-dropdown form [type='checkbox'] + label,
  .template-cart .datepicker-dropdown form [type='radio'] + label,
  .template-checkout .datepicker-dropdown form [type='checkbox'] + label,
  .template-checkout .datepicker-dropdown form [type='radio'] + label {
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    color: #2D2926;
    gap: 9px;
    align-items: center; }

.template-cart .date-picker,
.template-checkout .date-picker {
  position: relative;
  overflow: hidden; }
  .template-cart .date-picker:after,
  .template-checkout .date-picker:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0px;
    background-image: url("../images/icon-new-calendar.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 48px;
    height: 48px;
    cursor: pointer; }

.template-cart .datepicker-close.button,
.template-checkout .datepicker-close.button {
  background-color: #2D2926 !important;
  min-height: initial;
  min-width: 30px;
  color: white !important;
  line-height: 26px; }
  .template-cart .datepicker-close.button:hover, .template-cart .datepicker-close.button:focus,
  .template-checkout .datepicker-close.button:hover,
  .template-checkout .datepicker-close.button:focus {
    text-decoration: underline; }

.template-cart .datepicker-days .date-switch,
.template-checkout .datepicker-days .date-switch {
  font-weight: 400;
  font-size: 18px;
  color: black; }

.template-cart .datepicker td span.active,
.template-cart .datepicker td.active.day,
.template-cart .datepicker td.active.year,
.template-checkout .datepicker td span.active,
.template-checkout .datepicker td.active.day,
.template-checkout .datepicker td.active.year {
  background: #DA291C !important;
  color: white; }

.template-cart .datepicker td,
.template-cart .datepicker th,
.template-checkout .datepicker td,
.template-checkout .datepicker th {
  width: 37px;
  height: 31px;
  padding: 0; }

.template-cart .datepicker th.dow,
.template-checkout .datepicker th.dow {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(60, 60, 67, 0.6); }

.template-cart .datepicker tfoot,
.template-checkout .datepicker tfoot {
  display: none; }

.template-cart .datepicker td.day:hover, .template-cart .datepicker td.day:focus,
.template-checkout .datepicker td.day:hover,
.template-checkout .datepicker td.day:focus {
  background: #727272;
  color: white; }

.template-account main {
  background: #fff; }

.template-account section.hero-generic {
  background: white;
  padding-bottom: 50px;
  margin-top: 50px; }
  @media print, screen and (min-width: 53.125em) {
    .template-account section.hero-generic {
      padding-bottom: 150px;
      margin-top: 124px; } }
  .template-account section.hero-generic h1 {
    margin-bottom: 32px; }

.template-account section.account {
  margin-top: 0;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media print, screen and (min-width: 53.125em) {
    .template-account section.account {
      padding-bottom: 90px; } }
  .template-account section.account h2 {
    margin-bottom: 32px; }
    @media print, screen and (min-width: 53.125em) {
      .template-account section.account h2 {
        margin-bottom: 72px; } }
  .template-account section.account #toggle-account-sidebar {
    font-weight: 400;
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 32px; }
    .template-account section.account #toggle-account-sidebar img {
      transition: all 300ms ease-in-out;
      height: 20px;
      margin-left: 12px; }
    .template-account section.account #toggle-account-sidebar.open img {
      transform: rotate(45deg); }
    .template-account section.account #toggle-account-sidebar:hover, .template-account section.account #toggle-account-sidebar:focus {
      text-decoration: underline; }
  .template-account section.account .has-tip {
    margin-top: -4px; }
  .template-account section.account .account-sidebar {
    list-style-type: none;
    margin-left: 0;
    margin-bottom: 32px;
    display: none;
    opacity: 0;
    transition: all 300ms ease-in-out; }
    .template-account section.account .account-sidebar.open {
      opacity: 1;
      display: block; }
    @media print, screen and (min-width: 30em) {
      .template-account section.account .account-sidebar {
        margin-bottom: 60px;
        opacity: 1;
        display: block; } }
    .template-account section.account .account-sidebar li {
      margin-bottom: 32px;
      position: relative; }
      .template-account section.account .account-sidebar li a {
        display: grid;
        grid-template-columns: 32px minmax(0, 1fr);
        gap: 30px;
        align-items: center;
        color: #2D2926;
        font-weight: 400;
        font-size: 20px;
        line-height: 1; }
      .template-account section.account .account-sidebar li img {
        justify-self: center; }
      .template-account section.account .account-sidebar li.active:before {
        content: '';
        display: block;
        height: 100%;
        width: 3px;
        background: #DA291C;
        position: absolute;
        top: 0;
        left: -3px; }
      .template-account section.account .account-sidebar li.active a {
        font-weight: 600; }
      .template-account section.account .account-sidebar li:hover a, .template-account section.account .account-sidebar li:focus a {
        text-decoration: underline; }
  .template-account section.account h3 {
    margin-bottom: 12px; }
  .template-account section.account .accordion-item {
    background: #F9F9F9;
    padding-top: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid #D0D0D0; }
    .template-account section.account .accordion-item:first-of-type {
      padding-top: 0; }
    .template-account section.account .accordion-item:last-of-type {
      border: none;
      padding-bottom: 0; }
    @media print, screen and (min-width: 53.125em) {
      .template-account section.account .accordion-item.is-active .inner-split .value,
      .template-account section.account .accordion-item.is-active .inner-split .address {
        opacity: 0;
        height: 0; } }
  .template-account section.account .accordion-title {
    padding: 0;
    border: none; }
    .template-account section.account .accordion-title:before {
      content: unset !important;
      display: none; }
    .template-account section.account .accordion-title:hover .inner-split button,
    .template-account section.account .accordion-title:hover .inner-split > a, .template-account section.account .accordion-title:focus .inner-split button,
    .template-account section.account .accordion-title:focus .inner-split > a {
      font-weight: 600; }
  .template-account section.account .accordion-content {
    background: #F9F9F9;
    padding: 24px 0 0;
    border: none; }
    @media print, screen and (min-width: 30em) {
      .template-account section.account .accordion-content form {
        max-width: 50%; }
        .template-account section.account .accordion-content form .checkbox-input label {
          font-weight: 400;
          font-size: 16px;
          align-items: center;
          gap: 10px;
          color: #2D2926; } }
  .template-account section.account .inner-frame-header {
    background: #DA291C;
    padding: 48px 32px;
    border-radius: 24px 24px 0px 0px; }
    .template-account section.account .inner-frame-header h4 {
      font-weight: 600;
      font-size: 40px;
      line-height: 1;
      margin-bottom: 0; }
  .template-account section.account .inner-frame-header-grey {
    background: #D9D9D9;
    padding: 48px 32px;
    border-radius: 24px 24px 0px 0px; }
    .template-account section.account .inner-frame-header-grey h4 {
      font-weight: 600;
      font-size: 40px;
      line-height: 1;
      margin-bottom: 0; }
  .template-account section.account .inner-frame-w-header {
    background: #F9F9F9;
    border-radius: 0px 0px 24px 24px;
    padding: 32px;
    margin-bottom: 32px; }
    @media print, screen and (min-width: 53.125em) {
      .template-account section.account .inner-frame-w-header {
        margin-bottom: 72px; } }
    .template-account section.account .inner-frame-w-header .inner-split {
      display: grid;
      gap: 24px;
      align-items: start; }
      @media print, screen and (min-width: 53.125em) {
        .template-account section.account .inner-frame-w-header .inner-split {
          gap: 30px 64px;
          grid-template-columns: repeat(2, minmax(0, 1fr)); } }
      .template-account section.account .inner-frame-w-header .inner-split .full {
        grid-column: 1/-1; }
        .template-account section.account .inner-frame-w-header .inner-split .full .inner-split button {
          margin-left: 58px;
          text-align: left;
          text-decoration: underline; }
        .template-account section.account .inner-frame-w-header .inner-split .full .inner-split .label {
          font-weight: 400;
          font-size: 20px;
          opacity: 0;
          transition: all 300ms ease-in-out; }
        .template-account section.account .inner-frame-w-header .inner-split .full .accordion-item.is-active .label {
          opacity: 1; }
      .template-account section.account .inner-frame-w-header .inner-split a {
        color: #2D2926; }
    .template-account section.account .inner-frame-w-header h5 {
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;
      margin-bottom: 32px; }
    .template-account section.account .inner-frame-w-header .icon-wrapper {
      display: grid;
      gap: 16px;
      grid-template-columns: 42px 1fr;
      margin-bottom: 32px; }
      .template-account section.account .inner-frame-w-header .icon-wrapper img {
        justify-self: center; }
      .template-account section.account .inner-frame-w-header .icon-wrapper .small-link {
        margin-top: 12px;
        font-weight: 400;
        font-size: 16px;
        text-decoration: underline; }
  .template-account section.account .inner-frame {
    background: #F9F9F9;
    border-radius: 24px;
    padding: 32px;
    margin-bottom: 32px; }
    @media print, screen and (min-width: 53.125em) {
      .template-account section.account .inner-frame {
        margin-bottom: 72px; } }
    .template-account section.account .inner-frame .notification-row:not(:last-of-type) {
      border-bottom: 1px solid #EEEEEE;
      padding-bottom: 32px;
      margin-bottom: 32px; }
    .template-account section.account .inner-frame .inner-split {
      display: grid;
      gap: 12px;
      align-items: start;
      font-weight: 400;
      font-size: 20px;
      line-height: 1.2; }
      @media print, screen and (min-width: 53.125em) {
        .template-account section.account .inner-frame .inner-split {
          grid-template-columns: repeat(3, minmax(0, 1fr)); } }
      .template-account section.account .inner-frame .inner-split .value {
        font-weight: 600;
        word-break: break-word;
        transition: all 300ms ease-in-out; }
        @media print, screen and (min-width: 53.125em) {
          .template-account section.account .inner-frame .inner-split .value {
            word-break: initial; } }
      .template-account section.account .inner-frame .inner-split button,
      .template-account section.account .inner-frame .inner-split > a {
        text-decoration: underline;
        text-align: left; }
        @media print, screen and (min-width: 53.125em) {
          .template-account section.account .inner-frame .inner-split button,
          .template-account section.account .inner-frame .inner-split > a {
            text-align: right;
            margin-left: auto; } }
      .template-account section.account .inner-frame .inner-split > a {
        color: #2D2926; }
        .template-account section.account .inner-frame .inner-split > a:hover, .template-account section.account .inner-frame .inner-split > a:focus {
          font-weight: 600; }
    .template-account section.account .inner-frame hr {
      margin-top: 32px;
      margin-bottom: 32px;
      border-bottom: 1px solid #D0D0D0; }
    .template-account section.account .inner-frame .address {
      font-weight: 600;
      font-size: 18px;
      line-height: 1.2; }
      .template-account section.account .inner-frame .address .name {
        color: #727272; }
      .template-account section.account .inner-frame .address img {
        display: block; }
    .template-account section.account .inner-frame button.button {
      margin-top: 24px; }

.template-account .notification-row.w-padding {
  padding: 0 32px;
  margin-bottom: 10px; }

.template-account .notification-row .label {
  text-align: center; }

.template-account .notification-row p {
  margin-bottom: 0; }

.template-account .notification-row .checkbox-input {
  margin-top: 24px;
  margin-right: 24px;
  display: inline-block; }
  @media print, screen and (min-width: 30em) {
    .template-account .notification-row .checkbox-input {
      margin-top: 0;
      margin-right: 0;
      display: block;
      text-align: center; } }
  .template-account .notification-row .checkbox-input label {
    align-items: center; }

@media print, screen and (min-width: 30em) {
  .template-account .notification-row {
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 118px 118px; }
    .template-account .notification-row label {
      text-indent: -9999px;
      width: 27px;
      margin-left: auto;
      margin-right: auto; } }

.template-account .button.to-top {
  margin-bottom: 60px; }

.template-account footer {
  margin-top: 0; }

.template-orders section.account {
  padding-top: 32px; }
  @media print, screen and (min-width: 30em) {
    .template-orders section.account {
      padding-top: 60px; } }
  .template-orders section.account .order {
    background: #F9F9F9; }

.template-orders button.form-select {
  flex-direction: row;
  align-items: center;
  gap: 30px; }

.template-orders .viewed {
  margin-top: 24px;
  font-size: 16px;
  line-height: 22px; }

.template-orders .filters-wrapper {
  margin-bottom: 24px;
  display: grid;
  gap: 12px;
  justify-content: start; }
  .template-orders .filters-wrapper .label {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0px; }
  @media print, screen and (min-width: 30em) {
    .template-orders .filters-wrapper {
      gap: 42px;
      grid-template-columns: repeat(auto-fill, 150px);
      margin-bottom: 12px; } }

.template-orders .date-picker {
  position: relative; }

.template-orders button.product-filter {
  padding: 0px 19px 0px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.5px;
  color: #2D2926;
  position: relative; }
  @media print, screen and (min-width: 30em) {
    .template-orders button.product-filter {
      font-weight: 600;
      font-size: 20px;
      line-height: 1.2;
      color: #727272;
      text-decoration: underline; } }
  .template-orders button.product-filter:not(.more-filters):before, .template-orders button.product-filter:not(.more-filters):after {
    content: '';
    display: block;
    width: 13px;
    height: 1px;
    background: #2D2926;
    border-radius: 2px;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: center;
    transition: all 300ms ease-in-out; }
    @media print, screen and (min-width: 30em) {
      .template-orders button.product-filter:not(.more-filters):before, .template-orders button.product-filter:not(.more-filters):after {
        background-color: #727272;
        height: 2px; } }
  .template-orders button.product-filter:not(.more-filters):after {
    transform: translateY(-50%) rotate(90deg); }
  .template-orders button.product-filter:not(.more-filters):hover, .template-orders button.product-filter:not(.more-filters):focus {
    color: #2D2926; }
    .template-orders button.product-filter:not(.more-filters):hover:before, .template-orders button.product-filter:not(.more-filters):hover:after, .template-orders button.product-filter:not(.more-filters):focus:before, .template-orders button.product-filter:not(.more-filters):focus:after {
      background: #2D2926; }
  .template-orders button.product-filter:not(.more-filters).hover {
    color: #2D2926; }
    .template-orders button.product-filter:not(.more-filters).hover:after {
      transform: translateY(-50%);
      background: #DA291C; }

.template-orders .dropdown-pane,
.template-orders .datepicker-dropdown {
  background: #FFF;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);
  border-radius: 0px 24px 24px 24px;
  overflow: scroll;
  border: none;
  padding: 22px 24px; }
  .template-orders .dropdown-pane form [type='checkbox'] + label,
  .template-orders .dropdown-pane form [type='radio'] + label,
  .template-orders .datepicker-dropdown form [type='checkbox'] + label,
  .template-orders .datepicker-dropdown form [type='radio'] + label {
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    color: #2D2926;
    gap: 9px;
    align-items: center; }

.template-orders .date-picker {
  position: relative;
  overflow: hidden; }
  .template-orders .date-picker:after {
    content: '';
    position: absolute;
    top: 0;
    right: 15px;
    background-image: url("../images/icon-calendar.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 20px;
    height: 20px;
    cursor: pointer; }
  .template-orders .date-picker input {
    padding: 0;
    background: transparent;
    box-shadow: none;
    border: none;
    margin-bottom: 0;
    width: auto;
    min-width: unset;
    height: auto;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.5px;
    color: #2D2926; }
    @media print, screen and (min-width: 30em) {
      .template-orders .date-picker input {
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2;
        color: #727272;
        text-decoration: underline; } }

.template-orders .datepicker-close.button {
  background-color: #2D2926 !important;
  min-height: initial;
  min-width: 30px;
  color: white !important;
  line-height: 26px; }
  .template-orders .datepicker-close.button:hover, .template-orders .datepicker-close.button:focus {
    text-decoration: underline; }

.template-orders .datepicker-days .date-switch {
  font-weight: 400;
  font-size: 18px;
  color: black; }

.template-orders .datepicker td span.active,
.template-orders .datepicker td.active.day,
.template-orders .datepicker td.active.year {
  background: #DA291C !important;
  color: white; }

.template-orders .datepicker td,
.template-orders .datepicker th {
  width: 37px;
  height: 31px;
  padding: 0; }

.template-orders .datepicker th.dow {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(60, 60, 67, 0.6); }

.template-orders .datepicker tfoot {
  display: none; }

.template-orders .datepicker td.day:hover, .template-orders .datepicker td.day:focus {
  background: #727272;
  color: white; }

.template-notifications section.account {
  padding-top: 32px; }
  @media print, screen and (min-width: 30em) {
    .template-notifications section.account {
      padding-top: 60px; } }
  .template-notifications section.account .inner-frame {
    margin-bottom: 0; }
    .template-notifications section.account .inner-frame .notification-row:not(:last-of-type) {
      border-bottom: 1px solid #EEEEEE;
      padding-bottom: 32px;
      margin-bottom: 32px; }
  .template-notifications section.account form input[type='submit'] {
    margin-left: auto;
    margin-right: unset; }

.template-notifications .notification-row.w-padding {
  padding: 0 32px;
  margin-bottom: 10px; }

.template-notifications .notification-row .label {
  text-align: center; }

.template-notifications .notification-row p {
  margin-bottom: 0; }

.template-notifications .notification-row .checkbox-input {
  margin-top: 24px;
  margin-right: 24px;
  display: inline-block; }
  @media print, screen and (min-width: 30em) {
    .template-notifications .notification-row .checkbox-input {
      margin-top: 0;
      margin-right: 0;
      display: block;
      text-align: center; } }
  .template-notifications .notification-row .checkbox-input label {
    align-items: center; }

@media print, screen and (min-width: 30em) {
  .template-notifications .notification-row {
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 118px 118px; }
    .template-notifications .notification-row label {
      text-indent: -9999px;
      width: 27px;
      margin-left: auto;
      margin-right: auto; } }

.template-projects section.account {
  padding-top: 32px; }
  @media print, screen and (min-width: 30em) {
    .template-projects section.account {
      padding-top: 60px; } }
  .template-projects section.account h3 {
    margin-bottom: 0; }
    .template-projects section.account h3 a {
      color: #2D2926; }
      .template-projects section.account h3 a:hover, .template-projects section.account h3 a:focus {
        text-decoration: underline; }
  .template-projects section.account hr {
    margin-top: 12px;
    margin-bottom: 32px;
    border-bottom: 1px solid #D0D0D0; }
  .template-projects section.account .meta-wrapper button {
    margin-top: 10px;
    font-size: 16px; }
    .template-projects section.account .meta-wrapper button:hover, .template-projects section.account .meta-wrapper button:focus {
      text-decoration: underline; }
  @media print, screen and (min-width: 30em) {
    .template-projects section.account .meta-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .template-projects section.account .meta-wrapper button {
        margin-top: 0; } }
  .template-projects section.account .project-products-wrapper {
    display: grid;
    gap: 32px;
    margin-top: 12px;
    margin-bottom: 48px; }
    .template-projects section.account .project-products-wrapper .product-link:nth-of-type(4) {
      display: none; }
    @media print, screen and (min-width: 30em) {
      .template-projects section.account .project-products-wrapper {
        margin-bottom: 72px;
        grid-template-columns: repeat(3, minmax(0, 1fr)); } }
    @media only screen and (min-width: 1400px) {
      .template-projects section.account .project-products-wrapper {
        grid-template-columns: repeat(4, minmax(0, 1fr)); }
        .template-projects section.account .project-products-wrapper .product-link:nth-of-type(4) {
          display: flex; } }
    .template-projects section.account .project-products-wrapper a.product-link {
      display: block;
      border-radius: 6px;
      background: white;
      overflow: hidden;
      border: 2px solid transparent;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media print, screen and (min-width: 30em) {
        .template-projects section.account .project-products-wrapper a.product-link {
          aspect-ratio: 1/1; } }
      .template-projects section.account .project-products-wrapper a.product-link:hover, .template-projects section.account .project-products-wrapper a.product-link:focus {
        border-color: #000000; }
  .template-projects section.account .project-products-wrapper-list .details-grid {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 8px 24px; }
    .template-projects section.account .project-products-wrapper-list .details-grid > .full {
      grid-column: 1 / -1; }
    .template-projects section.account .project-products-wrapper-list .details-grid form,
    .template-projects section.account .project-products-wrapper-list .details-grid .actions {
      grid-column: 1 / -1; }
      @media print, screen and (min-width: 53.125em) {
        .template-projects section.account .project-products-wrapper-list .details-grid form,
        .template-projects section.account .project-products-wrapper-list .details-grid .actions {
          grid-column: initial; } }
  .template-projects section.account .project-products-wrapper-list .cart-product {
    display: grid;
    gap: 32px; }
    @media print, screen and (min-width: 30em) {
      .template-projects section.account .project-products-wrapper-list .cart-product {
        grid-template-columns: 130px 1fr 100px; } }
    @media only screen and (min-width: 1920px) {
      .template-projects section.account .project-products-wrapper-list .cart-product {
        gap: 132px; } }
    .template-projects section.account .project-products-wrapper-list .cart-product .details h3,
    .template-projects section.account .project-products-wrapper-list .cart-product .details h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 1.2;
      margin-bottom: 12px; }
      .template-projects section.account .project-products-wrapper-list .cart-product .details h3 span.unit-count,
      .template-projects section.account .project-products-wrapper-list .cart-product .details h4 span.unit-count {
        color: #2D2926;
        font-weight: 400; }
      .template-projects section.account .project-products-wrapper-list .cart-product .details h3:hover, .template-projects section.account .project-products-wrapper-list .cart-product .details h3:focus,
      .template-projects section.account .project-products-wrapper-list .cart-product .details h4:hover,
      .template-projects section.account .project-products-wrapper-list .cart-product .details h4:focus {
        text-decoration: underline; }
    .template-projects section.account .project-products-wrapper-list .cart-product .price {
      font-weight: 600;
      font-size: 20px;
      line-height: 1.2;
      margin-bottom: 12px; }
    .template-projects section.account .project-products-wrapper-list .cart-product .quantity {
      width: 100%; }
      @media print, screen and (min-width: 53.125em) {
        .template-projects section.account .project-products-wrapper-list .cart-product .quantity {
          width: 50%; } }
  .template-projects section.account .project-products-wrapper-list .project-card {
    display: grid;
    gap: 32px; }
    @media print, screen and (min-width: 30em) {
      .template-projects section.account .project-products-wrapper-list .project-card {
        grid-template-columns: repeat(3, 1fr); } }
    @media only screen and (min-width: 1920px) {
      .template-projects section.account .project-products-wrapper-list .project-card {
        gap: 132px; } }

.template-project-single section.account {
  padding-top: 32px; }
  @media print, screen and (min-width: 30em) {
    .template-project-single section.account {
      padding-top: 60px; } }
  .template-project-single section.account .medium-9 h2 {
    margin-bottom: 10px; }
  .template-project-single section.account .all-projects {
    color: #2D2926;
    text-decoration: underline; }
  .template-project-single section.account .select-products {
    display: block;
    margin-top: 32px;
    margin-left: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px; }
    .template-project-single section.account .select-products:hover, .template-project-single section.account .select-products:focus {
      text-decoration: underline; }
  .template-project-single section.account .actions-wrapper {
    margin-top: 32px;
    text-align: right;
    display: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px; }
    .template-project-single section.account .actions-wrapper button:hover, .template-project-single section.account .actions-wrapper button:focus {
      text-decoration: underline; }
  @media print, screen and (min-width: 30em) {
    .template-project-single section.account .select-products,
    .template-project-single section.account .actions-wrapper {
      height: 30px; } }
  .template-project-single section.account .card.product [type='checkbox'],
  .template-project-single section.account .card.product [type='radio'] {
    display: none; }
    .template-project-single section.account .card.product [type='checkbox'] + label,
    .template-project-single section.account .card.product [type='radio'] + label {
      line-height: 17px;
      font-size: 12px;
      color: #727272;
      margin: 0;
      position: relative;
      display: flex;
      gap: 4px; }
      .template-project-single section.account .card.product [type='checkbox'] + label:before,
      .template-project-single section.account .card.product [type='radio'] + label:before {
        content: '';
        display: block;
        width: 27px;
        height: 27px;
        min-width: 27px;
        min-height: 27px;
        max-width: 27px;
        max-height: 27px;
        border: 0.75px solid #8D8D8D;
        border-radius: 2px;
        margin-top: -3px;
        transition: all 300ms ease-in-out; }
      .template-project-single section.account .card.product [type='checkbox'] + label:after,
      .template-project-single section.account .card.product [type='radio'] + label:after {
        content: '';
        width: 19px;
        height: 15px;
        display: block;
        opacity: 0;
        background: url("../images/icon-check.png") no-repeat center center;
        background-size: contain;
        transition: all 300ms ease-in-out;
        position: absolute;
        left: 4px;
        top: 3px; }
    .template-project-single section.account .card.product [type='checkbox']:checked + label:before,
    .template-project-single section.account .card.product [type='radio']:checked + label:before {
      border-color: #2D2926;
      background: #2D2926; }
    .template-project-single section.account .card.product [type='checkbox']:checked + label:after,
    .template-project-single section.account .card.product [type='radio']:checked + label:after {
      opacity: 1; }
  .template-project-single section.account .card.product .img-wrapper {
    position: relative; }
    .template-project-single section.account .card.product .img-wrapper img {
      border-radius: 6px; }
    .template-project-single section.account .card.product .img-wrapper .checkbox-wrapper {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 1;
      opacity: 0;
      transition: all 300ms ease-in-out; }
  .template-project-single section.account .card.product .content {
    background: transparent;
    padding: 28px 0 0; }
  .template-project-single section.account .card.product:hover, .template-project-single section.account .card.product:focus {
    cursor: default; }
  .template-project-single section.account .project-products-wrapper {
    display: grid;
    gap: 32px;
    margin-top: 12px;
    margin-bottom: 48px; }
    @media print, screen and (min-width: 30em) {
      .template-project-single section.account .project-products-wrapper {
        margin-bottom: 72px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 72px 32px; } }
    .template-project-single section.account .project-products-wrapper .quantity {
      width: 100%; }
      @media print, screen and (min-width: 53.125em) {
        .template-project-single section.account .project-products-wrapper .quantity {
          width: 50%; } }
    .template-project-single section.account .project-products-wrapper.selection .checkbox-wrapper {
      opacity: 1 !important; }
  .template-project-single section.account .project-products-wrapper-list.selection .checkbox-wrapper {
    opacity: 1 !important; }

.template-2fa section.account {
  padding-top: 32px; }
  @media print, screen and (min-width: 30em) {
    .template-2fa section.account {
      padding-top: 60px; } }
  .template-2fa section.account .authenticate-method {
    display: grid;
    gap: 32px; }
    @media print, screen and (min-width: 53.125em) {
      .template-2fa section.account .authenticate-method {
        grid-template-columns: repeat(2, minmax(0, 1fr)); } }
    .template-2fa section.account .authenticate-method .checkbox-wrapper {
      display: grid;
      gap: 24px;
      margin-bottom: 24px; }
      @media print, screen and (min-width: 30em) {
        .template-2fa section.account .authenticate-method .checkbox-wrapper {
          grid-template-columns: repeat(3, minmax(0, max-content));
          gap: 32px; } }
    .template-2fa section.account .authenticate-method .content {
      font-size: 18px; }
      .template-2fa section.account .authenticate-method .content strong {
        display: block;
        font-size: 20px; }
  .template-2fa section.account .accordion-content form {
    max-width: 100%; }
    @media print, screen and (min-width: 30em) {
      .template-2fa section.account .accordion-content form > .input-wrapper {
        max-width: 50%; } }
  .template-2fa section.account .button-wrapper {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    gap: 32px; }
    .template-2fa section.account .button-wrapper .button {
      width: 100%; }
    @media print, screen and (min-width: 30em) {
      .template-2fa section.account .button-wrapper {
        gap: 12px;
        justify-content: flex-end; }
        .template-2fa section.account .button-wrapper .button {
          width: auto; } }

.template-claims section.account {
  padding-top: 32px; }
  @media print, screen and (min-width: 30em) {
    .template-claims section.account {
      padding-top: 60px; } }
  .template-claims section.account .claims-filters {
    margin-bottom: 32px; }
    .template-claims section.account .claims-filters h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px; }
    @media print, screen and (min-width: 30em) {
      .template-claims section.account .claims-filters {
        margin-bottom: 72px;
        grid-template-columns: repeat(2, minmax(0, 1fr)); } }
  .template-claims section.account .inner-frame {
    margin-bottom: 0px; }
  .template-claims section.account form input[type="submit"] {
    margin-left: auto;
    margin-right: unset; }
  .template-claims section.account .product-details {
    display: grid;
    gap: 12px;
    margin-bottom: 32px; }
    @media print, screen and (min-width: 30em) {
      .template-claims section.account .product-details {
        gap: 32px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        justify-content: space-between; } }
    @media print, screen and (min-width: 53.125em) {
      .template-claims section.account .product-details {
        margin-bottom: 60px;
        grid-template-columns: repeat(5, minmax(0, max-content));
        justify-content: space-between; } }
    .template-claims section.account .product-details .detail .label {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      margin-bottom: 10px; }
  .template-claims section.account .claim-reason {
    display: grid;
    gap: 32px; }
    @media print, screen and (min-width: 53.125em) {
      .template-claims section.account .claim-reason {
        grid-template-columns: repeat(2, minmax(0, 1fr)); } }
    .template-claims section.account .claim-reason .checkbox-wrapper {
      display: grid;
      gap: 24px;
      margin-bottom: 24px; }
      @media print, screen and (min-width: 30em) {
        .template-claims section.account .claim-reason .checkbox-wrapper {
          grid-template-columns: repeat(2, minmax(0, max-content));
          gap: 16px 32px; } }
    .template-claims section.account .claim-reason .content {
      font-size: 18px; }
      .template-claims section.account .claim-reason .content strong {
        display: block;
        font-size: 20px; }

.template-catalogue-landing section.articles-grid .card.article img {
  aspect-ratio: 8.5/11;
  width: 100%;
  object-fit: cover; }

.template-catalogue-landing section.breadcrumbs {
  margin: 100px 0 0;
  padding-top: 32px; }
  @media print, screen and (min-width: 30em) {
    .template-catalogue-landing section.breadcrumbs {
      margin: 0; } }
  .template-catalogue-landing section.breadcrumbs .crumbs {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px; }
    .template-catalogue-landing section.breadcrumbs .crumbs a {
      color: #2D2926; }
    .template-catalogue-landing section.breadcrumbs .crumbs span {
      font-weight: 400; }
    @media print, screen and (min-width: 30em) {
      .template-catalogue-landing section.breadcrumbs .crumbs {
        font-weight: 600; } }

.template-get-started-v2 section.hero-grid {
  margin-top: 100px; }
  @media print, screen and (min-width: 30em) {
    .template-get-started-v2 section.hero-grid {
      margin-top: 11px; } }
  .template-get-started-v2 section.hero-grid .hero-grid-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 11px; }
    .template-get-started-v2 section.hero-grid .hero-grid-wrapper .txt-wrapper {
      grid-column: span 2; }
    .template-get-started-v2 section.hero-grid .hero-grid-wrapper .wide {
      grid-row: span 2; }
    @media print, screen and (min-width: 53.125em) {
      .template-get-started-v2 section.hero-grid .hero-grid-wrapper {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 32px; }
        .template-get-started-v2 section.hero-grid .hero-grid-wrapper .wide {
          grid-column: span 2;
          grid-row: span 1; }
        .template-get-started-v2 section.hero-grid .hero-grid-wrapper .txt-wrapper {
          grid-row: span 2; } }
  .template-get-started-v2 section.hero-grid .txt-wrapper {
    border-radius: 6px;
    background: #292929;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media print, screen and (min-width: 53.125em) {
      .template-get-started-v2 section.hero-grid .txt-wrapper {
        padding: 48px; } }
    @media only screen and (min-width: 1440px) {
      .template-get-started-v2 section.hero-grid .txt-wrapper {
        padding: 100px; } }
    .template-get-started-v2 section.hero-grid .txt-wrapper h1 {
      font-size: 48px;
      font-weight: 600;
      line-height: 110%;
      margin-bottom: 11px; }
      @media print, screen and (min-width: 30em) {
        .template-get-started-v2 section.hero-grid .txt-wrapper h1 {
          margin-bottom: 34px; } }
      @media only screen and (min-width: 1440px) {
        .template-get-started-v2 section.hero-grid .txt-wrapper h1 {
          font-size: 96px;
          line-height: 100%;
          letter-spacing: -0.96px; } }
    .template-get-started-v2 section.hero-grid .txt-wrapper .subtitle {
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.18px;
      color: white;
      margin-bottom: 7px; }
      @media print, screen and (min-width: 30em) {
        .template-get-started-v2 section.hero-grid .txt-wrapper .subtitle {
          font-size: 32px;
          line-height: 40px;
          margin-bottom: 35px; } }
    .template-get-started-v2 section.hero-grid .txt-wrapper .button {
      margin-top: 15px;
      width: 100%;
      max-width: 300px; }
    .template-get-started-v2 section.hero-grid .txt-wrapper .hollow {
      background: transparent;
      color: white;
      border-color: white; }
      .template-get-started-v2 section.hero-grid .txt-wrapper .hollow:hover, .template-get-started-v2 section.hero-grid .txt-wrapper .hollow:focus {
        background: #727272;
        border-color: #727272; }
  .template-get-started-v2 section.hero-grid img {
    border-radius: 6px;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.template-get-started-v2 section.highlights .grid-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 11px; }
  .template-get-started-v2 section.highlights .grid-wrapper .txt-wrapper {
    grid-column: span 2; }
  @media print, screen and (min-width: 53.125em) {
    .template-get-started-v2 section.highlights .grid-wrapper {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-auto-rows: 1fr;
      gap: 32px; } }

.template-get-started-v2 section.highlights h2 {
  font-size: 26px;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.26px; }
  @media print, screen and (min-width: 30em) {
    .template-get-started-v2 section.highlights h2 {
      font-size: 64px; } }

.template-get-started-v2 section.highlights h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.36px; }
  @media print, screen and (min-width: 30em) {
    .template-get-started-v2 section.highlights h3 {
      font-size: 36px;
      font-weight: 600;
      line-height: 42px;
      letter-spacing: -0.36px; } }

.template-get-started-v2 section.highlights .txt-wrapper,
.template-get-started-v2 section.highlights .icon-wrapper,
.template-get-started-v2 section.highlights .cta-wrapper {
  border-radius: 6px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 14px; }
  .template-get-started-v2 section.highlights .txt-wrapper img,
  .template-get-started-v2 section.highlights .txt-wrapper svg,
  .template-get-started-v2 section.highlights .icon-wrapper img,
  .template-get-started-v2 section.highlights .icon-wrapper svg,
  .template-get-started-v2 section.highlights .cta-wrapper img,
  .template-get-started-v2 section.highlights .cta-wrapper svg {
    height: 50px;
    object-fit: contain; }
  @media print, screen and (min-width: 30em) {
    .template-get-started-v2 section.highlights .txt-wrapper,
    .template-get-started-v2 section.highlights .icon-wrapper,
    .template-get-started-v2 section.highlights .cta-wrapper {
      gap: 33px; }
      .template-get-started-v2 section.highlights .txt-wrapper img,
      .template-get-started-v2 section.highlights .txt-wrapper svg,
      .template-get-started-v2 section.highlights .icon-wrapper img,
      .template-get-started-v2 section.highlights .icon-wrapper svg,
      .template-get-started-v2 section.highlights .cta-wrapper img,
      .template-get-started-v2 section.highlights .cta-wrapper svg {
        height: auto; } }

.template-get-started-v2 section.highlights .icon-wrapper,
.template-get-started-v2 section.highlights .cta-wrapper {
  padding: 26.5px 23.5px; }
  @media only screen and (min-width: 1440px) {
    .template-get-started-v2 section.highlights .icon-wrapper,
    .template-get-started-v2 section.highlights .cta-wrapper {
      padding: 110px 72px; } }

.template-get-started-v2 section.highlights .txt-wrapper {
  background: #292929;
  padding: 54px 44px; }
  @media only screen and (min-width: 1440px) {
    .template-get-started-v2 section.highlights .txt-wrapper {
      padding: 72px 80px;
      gap: 26px; } }

.template-get-started-v2 section.highlights .icon-wrapper {
  background: #D9D9D9; }

.template-get-started-v2 section.highlights .cta-wrapper {
  background: #DA291C; }

.template-get-started-v2 section.videos .cbm-container {
  position: relative;
  padding-left: 12px;
  padding-right: 12px; }

.template-get-started-v2 section.videos .slider-arrow {
  opacity: 0;
  left: -20px;
  top: 0;
  transform: none;
  margin-top: -55px;
  padding-top: 15.27149%; }
  .template-get-started-v2 section.videos .slider-arrow.next {
    left: unset;
    right: -20px; }
  .template-get-started-v2 section.videos .slider-arrow:hover svg path:nth-child(2), .template-get-started-v2 section.videos .slider-arrow:focus svg path:nth-child(2) {
    fill: white; }
  @media print, screen and (min-width: 53.125em) {
    .template-get-started-v2 section.videos .slider-arrow {
      opacity: 1; } }

.template-get-started-v2 section.videos .video-wrapper iframe,
.template-get-started-v2 section.videos .video-wrapper video {
  aspect-ratio: 16/9;
  width: 100%;
  height: unset;
  margin-bottom: 22px;
  border-radius: 6px; }

.template-get-started-v2 section.videos .video-wrapper .title {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 22px; }

.template-get-started-v2 section.videos .video-wrapper .copy {
  font-size: 24px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.24px; }

.template-get-started-v2 section.videos .video-slider-wrapper .slick-list {
  margin: 0 -7.5px; }
  @media print, screen and (min-width: 30em) {
    .template-get-started-v2 section.videos .video-slider-wrapper .slick-list {
      margin: 0 -16px; } }

.template-get-started-v2 section.videos .video-slider-wrapper .slick-slide {
  margin: 0 7.5px; }
  @media print, screen and (min-width: 30em) {
    .template-get-started-v2 section.videos .video-slider-wrapper .slick-slide {
      margin: 0 16px; } }

.template-get-started-v2 section.stories h2 {
  font-size: 64px;
  font-weight: 600;
  line-height: 100%; }

.template-get-started-v2 section.stories .column.large-4:nth-child(even) .story-wrapper, .template-get-started-v2 section.stories .large-4.columns:nth-child(even) .story-wrapper {
  background: #292929; }

.template-get-started-v2 section.stories .column.large-4, .template-get-started-v2 section.stories .large-4.columns {
  margin-top: 61px; }

.template-get-started-v2 section.stories .story-wrapper {
  border-radius: 6px;
  overflow: hidden;
  background: #292929;
  padding-bottom: 60px; }
  .template-get-started-v2 section.stories .story-wrapper img {
    border-radius: 6px 6px 0 0;
    width: 100%; }
  .template-get-started-v2 section.stories .story-wrapper .content {
    padding: 55px 61px 0; }
  .template-get-started-v2 section.stories .story-wrapper h3 {
    margin-bottom: 20px; }
    @media print, screen and (min-width: 30em) {
      .template-get-started-v2 section.stories .story-wrapper h3 {
        font-size: 36px;
        font-weight: 600;
        line-height: 42px;
        letter-spacing: -0.36px; } }
  .template-get-started-v2 section.stories .story-wrapper .button {
    margin-left: 61px;
    margin-top: 22px; }
  @media print, screen and (min-width: 30em) {
    .template-get-started-v2 section.stories .story-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: space-between; } }

.template-get-started-v2 section.testimonial img {
  border-radius: 6px; }
  @media print, screen and (min-width: 53.125em) {
    .template-get-started-v2 section.testimonial img {
      height: 100%;
      width: 100%;
      object-fit: cover; } }

.template-get-started-v2 section.testimonial .inner-testimonial {
  background: #D9D9D9;
  border-radius: 6px;
  padding: 32px;
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr 20px;
  align-items: center;
  margin-bottom: 22px; }
  @media print, screen and (min-width: 53.125em) {
    .template-get-started-v2 section.testimonial .inner-testimonial {
      height: 100%;
      margin-bottom: 0; } }
  @media only screen and (min-width: 1440px) {
    .template-get-started-v2 section.testimonial .inner-testimonial {
      padding: 107px 324px 144px 148px; } }
  .template-get-started-v2 section.testimonial .inner-testimonial .quote {
    margin-bottom: 8px; }
    @media print, screen and (min-width: 53.125em) {
      .template-get-started-v2 section.testimonial .inner-testimonial .quote {
        color: #000;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.18px; } }
  .template-get-started-v2 section.testimonial .inner-testimonial .author {
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.12px;
    color: black; }
  .template-get-started-v2 section.testimonial .inner-testimonial .closing-quote {
    color: black;
    font-weight: 600;
    font-size: 80px;
    line-height: 44px;
    letter-spacing: -2.25px;
    align-self: start; }
    @media only screen and (min-width: 1440px) {
      .template-get-started-v2 section.testimonial .inner-testimonial .closing-quote {
        font-size: 300px;
        align-self: unset; } }

.template-get-started-v2 section.sign-up-cta img {
  margin-bottom: 50px; }

.template-get-started-v2 section.sign-up-cta h2 {
  color: #292929;
  font-size: 64px;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 22px; }

.template-get-started-v2 section.sign-up-cta .copy.p1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.24px; }

.template-get-started-v2 section.sign-up-cta .button {
  margin-top: 30px; }

@media print, screen and (min-width: 53.125em) {
  .template-get-started-v2 section.sign-up-cta .copy,
  .template-get-started-v2 section.sign-up-cta .button {
    width: 100%;
    max-width: 416px;
    margin-left: auto;
    margin-right: auto; } }

.template-about section.hero-generic img {
  margin-bottom: 33px; }

.template-about section.projects {
  margin-top: 90px; }
  @media print, screen and (min-width: 53.125em) {
    .template-about section.projects {
      margin-top: 150px; }
      .template-about section.projects .row {
        padding-bottom: 94.5px; } }
  .template-about section.projects h2 {
    font-size: 36px;
    line-height: 1.2;
    font-weight: 600;
    color: #727272;
    transition: all 300ms ease-in-out; }
    .template-about section.projects h2.active {
      color: #2D2926; }
    .template-about section.projects h2:hover, .template-about section.projects h2:focus {
      cursor: pointer;
      color: #2D2926; }
    @media print, screen and (min-width: 53.125em) {
      .template-about section.projects h2 {
        font-size: 64px; } }
    .template-about section.projects h2:last-of-type {
      margin-bottom: 24px; }
      @media print, screen and (min-width: 30em) {
        .template-about section.projects h2:last-of-type {
          margin-bottom: 36px; } }
  .template-about section.projects .column, .template-about section.projects .columns {
    align-self: center; }
  .template-about section.projects .outer-slider-wrapper {
    max-width: 718px;
    margin-top: 32px; }
    @media print, screen and (min-width: 30em) {
      .template-about section.projects .outer-slider-wrapper {
        margin-top: 0;
        position: relative; } }
  .template-about section.projects .projects-slider-wrapper {
    margin-bottom: 24px; }
    @media print, screen and (min-width: 30em) {
      .template-about section.projects .projects-slider-wrapper {
        margin-bottom: 0;
        clip-path: inset(-100vw -100vw -100vw 0); } }
    .template-about section.projects .projects-slider-wrapper .slick-list {
      overflow: visible;
      margin: 0 -7.5px; }
      @media print, screen and (min-width: 30em) {
        .template-about section.projects .projects-slider-wrapper .slick-list {
          margin: 0 -17px; } }
    .template-about section.projects .projects-slider-wrapper .slick-slide {
      margin: 0 7.5px; }
      @media print, screen and (min-width: 30em) {
        .template-about section.projects .projects-slider-wrapper .slick-slide {
          margin: 0 16px; } }
  .template-about section.projects .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin-top: 32px; }
    @media print, screen and (min-width: 53.125em) {
      .template-about section.projects .button-wrapper {
        position: absolute;
        width: 100%; } }
  .template-about section.projects .slider-arrow {
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    transform: none;
    opacity: 0 !important; }
    .template-about section.projects .slider-arrow:hover path:nth-child(2), .template-about section.projects .slider-arrow:focus path:nth-child(2) {
      fill: white; }
    @media print, screen and (min-width: 30em) {
      .template-about section.projects .slider-arrow {
        opacity: 1 !important; }
        .template-about section.projects .slider-arrow.slick-disabled {
          opacity: 1 !important; }
          .template-about section.projects .slider-arrow.slick-disabled svg path {
            fill: #727272; }
            .template-about section.projects .slider-arrow.slick-disabled svg path:nth-child(2) {
              fill: white; } }
  .template-about section.projects .project {
    position: relative;
    border-radius: 6px;
    overflow: hidden; }
    @media screen and (max-width: 29.9375em) {
      .template-about section.projects .project {
        width: 228px;
        height: 349px; } }
    @media print, screen and (min-width: 30em) {
      .template-about section.projects .project {
        aspect-ratio: 718/788; } }
    .template-about section.projects .project img {
      height: 100%;
      object-fit: cover; }
    .template-about section.projects .project .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 1.2;
      text-align: center;
      color: #FFFFFF;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      z-index: 1; }
      @media print, screen and (min-width: 30em) {
        .template-about section.projects .project .title {
          font-size: 48px; } }
    .template-about section.projects .project:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6); }

.template-about section.timeline {
  position: relative; }
  .template-about section.timeline .label {
    font-size: 24px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.24px;
    margin-bottom: 16px; }
  .template-about section.timeline h2 {
    font-size: 48px;
    font-weight: 600;
    line-height: 120%; }
    @media print, screen and (min-width: 30em) {
      .template-about section.timeline h2 {
        font-size: 64px; } }
  .template-about section.timeline h3 {
    font-size: 36px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.48px;
    text-transform: uppercase;
    margin-bottom: 19px; }
    @media print, screen and (min-width: 30em) {
      .template-about section.timeline h3 {
        font-size: 48px; } }
  .template-about section.timeline .copy {
    font-size: 24px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.24px; }
  .template-about section.timeline img {
    border-radius: 6px;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1468/610; }
    @media print, screen and (min-width: 30em) {
      .template-about section.timeline img {
        margin-bottom: 130px; } }
  .template-about section.timeline .entry {
    padding-top: 48px; }
    @media print, screen and (min-width: 30em) {
      .template-about section.timeline .entry {
        padding-top: 154px; } }
    .template-about section.timeline .entry h2 {
      font-size: 64px;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -0.96px;
      color: #DA291C;
      margin-bottom: 19px; }
      @media print, screen and (min-width: 30em) {
        .template-about section.timeline .entry h2 {
          font-size: 96px; } }
  .template-about section.timeline .entry.wide {
    padding-top: 0;
    padding-bottom: 30px; }
    .template-about section.timeline .entry.wide > .column, .template-about section.timeline .entry.wide > .columns {
      position: relative; }
    .template-about section.timeline .entry.wide h2 {
      margin-top: 30px; }
    @media print, screen and (min-width: 30em) {
      .template-about section.timeline .entry.wide {
        text-align: center; } }
  .template-about section.timeline .entry.first {
    padding-top: 48px; }
    .template-about section.timeline .entry.first .line {
      display: none; }
    @media print, screen and (min-width: 30em) {
      .template-about section.timeline .entry.first {
        padding-top: 62px; }
        .template-about section.timeline .entry.first .line {
          position: absolute;
          display: block;
          width: 2px;
          height: 130px;
          background: #D1C5C5;
          left: 50%;
          transform: translateX(-49%) translateY(-130px); }
        .template-about section.timeline .entry.first .column:first-child:after, .template-about section.timeline .entry.first .columns:first-child:after {
          content: '';
          display: block;
          width: 17px;
          height: 17px;
          border-radius: 100%;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-49%);
          background: #DA291C;
          z-index: 1; } }
  .template-about section.timeline .entry.even,
  .template-about section.timeline .entry.odd {
    position: relative; }
    .template-about section.timeline .entry.even .line,
    .template-about section.timeline .entry.odd .line {
      display: none; }
    @media print, screen and (min-width: 30em) {
      .template-about section.timeline .entry.even .line,
      .template-about section.timeline .entry.odd .line {
        display: block;
        width: 2px;
        height: 100%;
        background: #D1C5C5;
        position: absolute;
        top: 0;
        left: calc(50% - 1px); }
      .template-about section.timeline .entry.even:after,
      .template-about section.timeline .entry.odd:after {
        content: '';
        display: block;
        width: 17px;
        height: 17px;
        border-radius: 100%;
        position: absolute;
        top: calc(50% + 77px);
        left: 50%;
        transform: translateX(-49%);
        background: #DA291C;
        z-index: 1; } }
  .template-about section.timeline .entry.last {
    padding-bottom: 62px; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }
