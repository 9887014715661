.button,
input[type="submit"] {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding: 16px 58px;
  margin-bottom: 0;
  position: relative;
  background-color: $black;
  color: $white;
  border-radius: 6px;
  border: solid 1px black;
  min-width: 191px;

  @include transit;

  @include breakpoint(medium) {
    min-width: unset;
    padding: 16px 41px;
    font-size: 16px;
  }

  @include hover-focus {
    background: $silver;
    color: $white;
    border-color: $silver;
  }

  &.white {
    background-color: $white;
    border-color: $white;
    color: $black;

    @include hover-focus {
      background: $silver;
      color: white;
      border-color: $silver;
    }
  }

  &.hollow {
    background-color: $white;
    color: $black;
    border-color: $black;

    @include hover-focus {
      background: $silver;
      color: white;
      border-color: $silver;
    }
  }

  &.red {
    background: $red;
    border-color: $red;

    @include hover-focus {
      background: $silver;
      color: white;
      border-color: $silver;
    }
  }

  &.to-top {
    margin-top: 36px;
  }

}

.button-group {
  a:not(:first-child) {
    margin-top: 24px;

    @include breakpoint(medium) {
      margin-top: 0;
      margin-left: 24px;
    }
  }

  &.small {
    margin-bottom: 32px;

    a {
      margin-top: 12px;
    }

    a:not(:first-child) {
      @include breakpoint(medium) {
        margin-left: 12px;
      }
    }
  }
}

.form-buttons-wrapper {
  display: grid;
  gap: 32px;
  margin-bottom: 32px;

  @include breakpoint(medium) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-bottom: 72px;
  }

  button.form-select {
    background: #FFFFFF;
    border: 1px solid transparent;
    border-radius: 24px;
    padding: 32px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: start;

    strong {
      display: block;
      margin-bottom: 12px;
    }

    &.active,
    &:focus,
    &:hover {
      border-color: #2D2926;
    }
  }
}