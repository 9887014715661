.template-story-landing {

  section.featured-story {

    @include breakpoint(large) {
      .row {
        flex-direction: row-reverse;
        justify-content: flex-end;
      }

      .column {
        align-self: center;
      }

      .large-4 {
        padding-right: 32px;
      }
    }

    .category {
      font-size: 20px;
      line-height: 1.2;
      color: $red;
      margin-bottom: 10px;
    }

    .date {
      margin-top: 12px;
      margin-bottom: 24px;

      font-size: 18px;
      line-height: 1.2;
      color: $silver;
    }

    img {
      margin-bottom: 24px;
      border-radius: 6px;
      width: 100%;

      @include breakpoint(large) {
        margin-bottom: 0;
      }
    }
  }

  section.latest-articles {
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 75%;
      background: $white;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
      pointer-events: none;
    }

    h2 {
      margin-bottom: 32px;

      span {
        color: $silver;
      }

      @include breakpoint(medium) {
        text-align: center;
      }
    }

    .card.article {
      img {
        margin-bottom: 24px;
      }
    }

    .latest-wrapper {
      position: relative;

      .slider-arrow {
        opacity: 0;
        top: 0;
        left: -21px;
        padding-top: calc(34.5177665% - 21px);
        transform: none;

        &.next {
          left: unset;
          right: -21px;
        }

        @include breakpoint(medium) {
          opacity: 1;
        }

        @include hover-focus {
          path:nth-child(2) {
            fill: white;
          }
        }
      }
    }

    .slick-list {
      overflow: visible;
      margin: 0 -7.5px;

      @include breakpoint(medium) {
        margin: 0 -16px;
      }
    }


    .slick-slide {
      margin: 0 7.5px;

      @include breakpoint(medium) {
        margin: 0 16px;
      }
    }

    .slick-current {
      .content {
        opacity: 1 !important;
      }
    }

    .slick-dots {
      // margin-top: -32px;
      transform: translateY(-32px);
    }
  }

  section.articles-grid {
    h2 {
      margin-bottom: 32px;
    }

    .category-filters {

      margin-bottom: 32px;

      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 12px;

      @include breakpoint(large) {
        border: 2px solid #EDEDED;
        border-radius: 31px;
        padding: 7px;
        gap: 28px;
        margin-bottom: 64px;
      }

      button {
        font-weight: 600;
        font-size: 16px;
        line-height: 1;
        color: $silver;
        min-width: 62px;
        padding: 12px 18px;
        border-radius: 31px;
        border: 1px solid $silver;

        @include hover-focus {
          color: white;
          background: $silver;
        }

        @include breakpoint(large) {
          border-color: transparent;
        }
      }

      button.active {
        background: $red;
        border-radius: 31px;
        color: white;
        padding: 12px 18px;
        border-color: $red;
      }
    }

    .viewed {
      margin-top: 24px;
      font-size: 16px;
      line-height: 22px;
    }
  }
}