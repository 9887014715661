.template-get-started {
  h2 {
    font-weight: 600;
  }

  section.discover {
    margin-top: 68px;

    h1 {
      font-weight: 600;
      font-size: 48px;
      line-height: 1;
      margin-top: 36px;

      @include breakpoint(medium) {
        margin-top: 64px;
        font-size: 96px;
      }
    }

    .discovery-images {
      margin-top: 90px;
      position: relative;
      height: 600px;

      @include breakpoint(medium) {
        margin-top: 132px;
        height: 1250px;
      }

      img {
        border-radius: 6px;
        max-width: unset;
      }

      .caption {
        margin-top: 16px;
        font-size: 20px;
        line-height: 28px;
        display: none;

        @include breakpoint(medium) {
          display: block;
        }
      }
    }

    .disc-wrap-1 {
      position: absolute;
      top: 96px;
      left: 0;
      z-index: 1;

      img {
        width: 200px;
        height: 168px;
        object-fit: cover;
      }

      @include breakpoint(medium) {
        top: 162px;
        left: 8%;

        img {
          width: 516px;
          height: 434px;
        }
      }
    }

    .disc-wrap-2 {
      position: absolute;
      top: 30px;
      left: 60px;
      z-index: 4;

      img {
        width: 168px;
        height: 262px;
        object-fit: cover;
      }

      @include breakpoint(medium) {
        top: 46px;
        left: 30%;

        img {
          width: 378px;
          height: 590px;
        }
      }
    }

    .disc-wrap-3 {
      position: absolute;
      top: 0;
      left: 120px;
      z-index: 2;

      img {
        width: 198px;
        height: 283px;
        object-fit: cover;
      }

      @include breakpoint(medium) {
        top: 0;
        left: 50%;
        text-align: right;

        img {
          width: 380px;
          height: 570px;
        }
      }
    }

    .disc-wrap-4 {
      position: absolute;
      top: 176px;
      left: 150px;
      z-index: 3;

      img {
        width: 198px;
        height: 149px;
        object-fit: cover;
      }

      @include breakpoint(medium) {
        top: 434px;
        left: 750px;
        // left: unset;
        // right: 0%;

        img {
          width: 361px;
          height: 272px;
        }
      }
    }
  }

  section.find-need {
    h2 {
      margin-bottom: 12px;
    }

    .copy {
      margin-bottom: 12px;
    }

    a {
      display: inline-block;
    }

    .find-slider-wrapper {
      margin-top: 55px;
      clip-path: inset(-100vw -100vw -100vw 0);

      .slick-track {
        display: flex;
        align-items: center;
      }

      img {
        border-radius: 6px;
      }

      .slick-list {
        overflow: visible;
      }

      .slick-slide {
        margin: 0 16px;
      }
    }

    .slider-buttons {
      margin-top: 20px;
      text-align: center;
    }

    .slider-arrow {
      display: none;
      position: relative;
      top: unset;
      left: unset;
      transform: unset;

      @include breakpoint(medium) {
        display: inline-block;
      }

      &.slick-disabled {
        opacity: 1 !important;

        svg path {
          fill: #75787B;
        }
      }
    }
  }

  section.save {
    h2 {
      margin-bottom: 48px;

      @include breakpoint(medium) {
        margin-bottom: 113px;
      }
    }

    .products-wrapper {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      gap: 12px;
      margin-bottom: 48px;
      margin-top: 8px;

      img:last-of-type {
        display: none;
      }

      @include breakpoint(large) {
        grid-template-columns: repeat(5, minmax(0, 173px));
        // gap: 161px;
        justify-content: space-between;
        margin-bottom: 98px;
        margin-top: 0;

        img:last-of-type {
          display: block;
        }
      }
    }

    .button {
      margin-top: 26px;

      @include breakpoint(medium) {
        margin-top: 42px;
      }
    }
  }

  section.projects {
    h2 {
      font-size: 36px;
      line-height: 42px;
      margin-bottom: 24px;

      span {
        color: $black;
      }

      br {
        display: block;
      }

      @include breakpoint(large) {
        margin-bottom: 36px;
        font-size: 64px;
        line-height: 72px;
      }
    }

    .column {
      align-self: center;
    }

    .outer-slider-wrapper {
      max-width: 718px;
    }

    .projects-slider-wrapper {
      margin-bottom: 24px;

      @include breakpoint(medium) {
        margin-bottom: 0;
        clip-path: inset(-100vw -100vw -100vw 0);
      }

      .slick-list {
        // padding-left: 90px;
        overflow: visible;
        margin: 0 -7.5px;

        @include breakpoint(medium) {
          margin: 0 -16px;
          // padding-left: 0;
        }
      }

      .slick-slide {
        margin: 0 7.5px;

        @include breakpoint(medium) {
          margin: 0 16px;
        }
      }
    }

    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 32px;
      margin-top: 32px;
    }

    .slider-arrow {
      position: relative;
      top: unset;
      left: unset;
      right: unset;
      transform: none;

      opacity: 0 !important;

      @include hover-focus {
        path:nth-child(2) {
          fill: white;
        }
      }

      @include breakpoint(medium) {
        opacity: 1 !important;

        &.slick-disabled {
          opacity: 1 !important;

          svg path {
            fill: $silver;

            &:nth-child(2) {
              fill: white;
            }
          }
        }
      }
    }

    .project {
      position: relative;
      border-radius: 6px;
      overflow: hidden;

      @include breakpoint(small down) {
        width: 228px;
        height: 349px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      @include breakpoint(medium) {
        aspect-ratio: 718/788;
      }

      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 26px;
        text-align: center;
        color: #FFFFFF;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        z-index: 1;

        @include breakpoint(medium) {
          font-size: 48px;
          line-height: 36px;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
      }
    }
  }

  section.sign-up {

    @include breakpoint(large) {
      .column {
        align-self: center;
      }
    }

    h2 {
      margin-bottom: 12px;
      font-size: 36px;

      @include breakpoint(large) {
        font-size: 64px;
        line-height: 72px;
      }

      br {
        display: block;
      }
    }

    .subtitle {
      font-weight: 600;
      font-size: 36px;
      line-height: 40px;

      @include breakpoint(medium) {
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
      }
    }

    img {
      margin-bottom: 36px;

      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }

    form {
      margin-top: 42px;
      margin-bottom: 0;

      @include breakpoint(medium) {
        margin-top: 56px;
      }

      .form-copy {

        font-size: 16px;
        line-height: 21px;
        color: #383637;

        @include breakpoint(medium) {
          text-align: center;
        }
      }

      input[type="submit"] {
        width: 100%;
      }
    }
  }
}