.template-story-single {

  h1 {
    @include breakpoint(large) {
      font-size: 48px;
    }
  }

  section.featured-image {

    img {
      border-radius: 6px;
      width: 100%;
    }
  }

  .post-social-wrapper {
    margin-top: 24px;

    .social-links {
      display: flex;
      gap: 12px;
      justify-content: center;
    }

    a {
      @include hover-focus {
        svg path {
          fill: $silver;
        }
      }
    }

    &.vertical {
      margin-top: 0;

      .social-links {
        flex-direction: column;
      }
    }
  }

  article {
    margin-top: 32px;

    @include breakpoint(large) {
      margin-top: 90px;
    }

    .inner-article {
      display: grid;
      gap: 20px 0;
      grid-template-rows: auto;
      grid-template-columns: 6% minmax(0, 1fr) 6%;

      @include breakpoint(medium) {
        grid-template-columns: 12.5% minmax(0, 1fr) 12.5%;
      }

      >*:first-child {
        margin-top: 0;
      }

      >* {
        grid-column: 2 / -2;
      }

      img,
      .video-wrapper,
      blockquote,
      >figure,
      .two-images,
      .story-slider {
        grid-column: 1 / -1;
      }

      .video-wrapper {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;
        margin-top: 32px;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }
      }

      figure {
        figcaption {
          font-size: 18px;
          line-height: 1.2;
        }

        @include breakpoint(medium) {
          margin-top: 32px;
          margin-bottom: 32px;
        }
      }

      img {
        width: 100%;
        border-radius: 6px;
        margin-bottom: 12px;
      }

      h2 {
        margin-top: 24px;
        font-weight: 600;
        font-size: 24px;
        line-height: 1.2;
      }

      h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2;
      }

      blockquote {
        border-left: 7px solid $red;
        padding: 20px 0 20px 32px;
        margin-top: 32px;
        margin-bottom: 32px;

        p {
          color: $black;
          font-size: 26px;
          line-height: 1.2;
        }

        .author {
          font-size: 18px;
          line-height: 1.2;
          color: $black;
        }
      }

      .two-images {
        display: grid;
        gap: 32px;

        @include breakpoint(medium) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }
	  
	  div.story-product {
        margin-top: 32px;
        position: relative;
        .slider-wrapper .slick-list {
          padding-right: 32px;
        }
        .slick-list {
          // overflow: visible;
          // margin: 0 -7.5px;
          margin-left: -7.5px;
          @include breakpoint(medium) {
            // margin: 0 -16px;
            margin-left: -16px;
          }
        }
        .slick-slide {
          margin: 0 7.5px;
          @include breakpoint(medium) {
            margin: 0 16px;
          }
        }
        .slider-arrow {
          opacity: 0;
          top: calc(50% - 70px);
          transform: none;
          &.prev {
            left: -31.5px;
          }
          @include breakpoint(medium) {
            opacity: 1;
          }
        }
        .product-category {
          color: $black;
          text-decoration: none;
          .img-wrapper {
            border-radius: 6px;
            margin-bottom: 12px;
            overflow: hidden;
          }
          img {
            width: 100%;
            border-radius: 6px;
            // height: 418px;
            aspect-ratio: 1/1;
            object-fit: cover;
            @include transit;
            margin-bottom: 0;
          }
          .title {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: 0.015em;
          }
          .desc {
            font-size: 18px;
          }
          .promo {
            background: $red;
            padding: 41px;
            border-radius: 6px;
            // height: 418px;
            aspect-ratio: 1/1;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .header {
              font-weight: 600;
              font-size: 24px;
              line-height: 34px;
              color: #FFFFFF;
              margin-bottom: 36px;
              text-decoration: none;
            }
          }
          @include hover-focus {
            .title {
              text-decoration: underline;
            }
            img {
              transform: scale(1.1);
            }
          }
        }
      }

      .story-slider {
        margin-top: 32px;
        position: relative;

        .slider-arrow {
          display: none;

          @include breakpoint(medium) {
            margin-top: calc((788 / 1168 * 100%) + 32px);
            top: 0;
            right: 0;
            left: unset;
            transform: none;

            &.slick-disabled {
              opacity: 1 !important;

              path {
                stroke: $silver;
              }

              @include hover-focus {
                cursor: not-allowed;
              }
            }

            @include hover-focus {
              path {
                stroke: $red;
                fill: none;
              }
            }
          }
        }

        .prev {
          right: 50px;
        }
      }

      .slider-counter {
        display: none;

        @include breakpoint(medium) {
          display: block;
        }

        position: absolute;
        right: 0;
        bottom: 12px;

        font-weight: 400;
        font-size: 16px;
        color: $silver;
      }

      .story-slider-wrapper {
        // margin-top: 32px;

        h2 {
          margin-bottom: 20px;
        }

        img {
          margin-bottom: 32px;

          @include breakpoint(medium) {
            margin-bottom: 90px;
          }
        }

        figure {
          margin-top: 0;
        }

        figcaption {
          padding-left: 6%;
          padding-right: 6%;

          @include breakpoint(medium) {
            padding-left: 12.5%;
            padding-right: 12.5%;
          }
        }
      }

      .article-footer {
        margin-top: 32px;
        padding-top: 32px;
        border-top: 1px solid #000000;

        @include breakpoint(large) {
          margin-top: 64px;
          padding-top: 64px;
        }

        h2 {
          margin-top: 0;
          margin-bottom: 12px;
        }

        .bio {
          font-weight: 400;
          font-size: 16px;
          line-height: 1.5;
        }
      }
    }
  }


  section.articles-grid {
    h2 {
      margin-bottom: 32px;
    }
  }
  
  section.gallery {
    .gallery-wrapper {
      column-count: 2;
      column-gap: 12px;
      margin-top: 24px;

      @include breakpoint(medium) {
        column-count: 3;
        column-gap: 32px;
        margin-top: 0px;
      }

      .gallery-item {
        margin-bottom: 12px;

        @include breakpoint(medium) {
          margin-bottom: 32px;
        }

        .hotspot-container {
          padding-bottom: 602px / 568px * 100%;
        }

        &.tall {
          .hotspot-container {
            padding-bottom: 796px / 570px * 100%;
          }
        }

        .hotspot {
          display: none;

          @include breakpoint(large) {
            display: block;
          }
        }
      }
    }
	
	.gallery-wrapper-first {
	  @include breakpoint(medium) {
        margin-top: 50px !important;
      }
	}
  }
}