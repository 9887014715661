.off-canvas-content .off-canvas {
  z-index: 9999;
  width: 100vw;
  padding: 24px;
  background: $white;
  box-shadow: none !important;

  @include breakpoint(medium) {
    width: 420px;
    border-radius: 0px 24px 24px 0px;
  }

  .close-button {
    border-radius: 100%;

    @include hover-focus {
      background: $light-grey;
    }
  }

  hr {
    margin-bottom: 32px;
    width: 100%;
    border-bottom: 1px solid #D0D0D0;
  }

  .menu li {
    // margin-bottom: 32px;
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }

    a {
      font-size: 18px;
      line-height: 25px;
      color: $grey;
      background: transparent;
      border-bottom: 2px solid transparent;
      padding: 0;
      display: inline;

      @include hover-focus {
        border-color: $black;
      }
    }
  }

  .social-icons-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 36px;

    a {
      @include hover-focus {
        svg path {
          fill: $black;
        }
      }
    }
  }

  .inner {

    @include breakpoint(medium) {
      padding: 85px 52px 0;
    }

    .inner-menu {
      display: block;
      font-weight: 600;
      color: $grey;

      &.primary {
        font-size: 24px;
        line-height: 32px;

        border-bottom: 3px solid transparent;
        margin-bottom: 32px;

        @include breakpoint(medium) {
          font-size: 32px;
          line-height: 36px;
        }
      }

      &.secondary {
        font-size: 18px;
        line-height: 25px;

        border-bottom: 2px solid transparent;
        margin-bottom: 32px;
      }

      @include hover-focus {
        border-color: $red;
      }
    }

    a.inner-menu {
      display: inline-block;
    }

    .content {
      margin-top: 32px;

      @include breakpoint(medium) {
        margin-top: 48px;
      }

      .button {
        margin-top: 12px;
      }
    }
  }

  .locations-wrapper {

    .location {
      margin-bottom: 40px;

      @include breakpoint(medium) {
        margin-bottom: 55px;
      }
    }

    .city {
      font-size: 16px;
      font-weight: 600;
      color: $black;
      // display: flex;
      // align-items: center;
      // gap: 8px;

      @include hover-focus {
        text-decoration: underline;
      }

      &:after {
        content: '';
        display: inline-block;
        width: 14px;
        height: 12px;
        margin-left: 8px;
        background: url('../images/icon-arrow-right.svg') no-repeat center center;
        background-size: contain;
      }
    }

    .desc {
      font-size: 16px;
      line-height: 1.2;
    }
  }
}

// Top Offcanvas
.off-canvas-content .off-canvas.position-top {
  transform: translateY(-625px);
  height: 534px;
  width: 100vw;
  padding: 20px 2vw;
  z-index: 9995;
  border-radius: 0px 0px 24px 24px;

  .master-close {
    margin-left: auto;
    margin-right: 8px;
    display: block;
  }

  &.is-open {
    transform: translate(0, 91px) !important;
  }

  &+.js-off-canvas-overlay {
    z-index: 9994;
  }

  .cbm-container {
    position: relative;

    >.row {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 100%;
    }
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 32px;
  }

  .menu li {
    margin-bottom: 24px;

    a {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }

  a.button {
    margin-top: 40px;
  }
}

// Left Offcanvas
.off-canvas-content .off-canvas.position-left {
  transform: translateX(100vw);
  padding: 20px;
  z-index: 10001;

  .master-close {
    margin-left: auto;
    display: block;
  }

  @include breakpoint(medium) {
    transform: translateX(-420px);

    .master-close {
      margin-left: unset;
    }
  }
}

// Right Offcanvas
.off-canvas-content .off-canvas.position-right {
  transform: translateX(100vw);
  padding: 13px 20px;

  @include breakpoint(medium) {
    transform: translateX(420px);
    border-radius: 24px 0px 0px 24px;
    padding: 24px 50px;
  }

  .close-button {
    margin-left: auto;
    display: block;
    margin-bottom: 12px;
  }

  h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 45px;
    margin-bottom: 32px;

    @include breakpoint(medium) {
      margin-bottom: 40px;
    }
  }

  .menu-cta {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 42px;
    margin-bottom: 36px;

    @include breakpoint(medium) {
      margin-bottom: 44px;
    }

    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: $black;
      margin-bottom: 20px;
      display: inline-block;
      border-bottom: 2px solid transparent;

      @include transit;
    }

    .copy {
      font-size: 18px;
      line-height: 25px;
    }

    svg {
      transform: rotate(180deg);
    }

    @include hover-focus {
      .title {
        border-color: $red;
      }

      svg circle:first-of-type,
      svg path {
        fill: #D9D9D6;
      }
    }
  }

}

// Nested Offcanvas
.off-canvas-content .off-canvas.off-canvas-nested {
  transform: translateX(100vw);
  background: $off-white;

  .master-close {
    position: absolute;
    top: 20px;
    right: 20px;

    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  @include breakpoint(medium) {
    transform: translateX(-850px);
    width: 850px;
    z-index: 9997;
    padding: 142px 76px 109px 500px;
  }

  .close-button,
  .close-drilldown {
    width: 42px;
    height: 42px;
    background: transparent;
    margin-bottom: 36px;

    @include breakpoint(medium) {
      margin-bottom: 45px;
    }

    svg {
      width: 42px;
      height: 42px;
    }

    @include hover-focus {
      animation: 0.5s linear 0.350s infinite alternate wobble;

      svg path,
      svg circle:first-of-type {
        fill: #D9D9D6;
      }
    }
  }

  li.subtitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.01em;
  }

  .drilldown .is-drilldown-submenu {
    background: $off-white;
  }

  .drilldown .is-drilldown-submenu-parent>a::after {
    content: unset;
    display: none;
  }

  .js-drilldown-back {
    margin-bottom: 45px;
    display: none;

    a {
      border: none;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      align-items: center;
      gap: 24px;

      &:before {
        border: none;
        width: 50px;
        height: 50px;
        margin: 0;
        background: url('../images/icon-back.svg') no-repeat center center;
        background-size: contain;
      }

      @include hover-focus {
        &:before {
          animation: 0.5s linear 0.350s infinite alternate wobble;
        }
      }

      @keyframes wobble {
        from {
          transform: translateX(0px);
        }

        to {
          transform: translateX(10px);
        }
      }
    }
  }

  .products-wrapper {
    display: grid;
    justify-content: center;
    gap: 14px;

    a.product-link {
      display: block;
      width: 150px;
      border-radius: 6px;
      background: white;
      overflow: hidden;
      border: 2px solid transparent;

      @include breakpoint(medium) {
        width: 200px;
      }

      img {
        width: 150px;
        height: 150px;
        object-fit: contain;

        @include breakpoint(medium) {
          width: 200px;
          height: 200px;
        }
      }

      @include hover-focus {
        border-color: #000000;
      }
    }

    a.brands-link {
      display: block;
      width: 150px;
      border-radius: 6px;
      background: white;
      overflow: hidden;
      border: 2px solid transparent;

      @include breakpoint(medium) {
        width: 200px;
      }

      img {
        max-width: 90%;
      }

      @include hover-focus {
        border-color: #000000;
      }
    }
  }

  .featured-wrapper {
    display: grid;
    justify-content: center;
    gap: 40px;

    @include breakpoint(medium) {
      gap: 55px;
    }

    a {
      display: block;
      width: 200px;
      overflow: hidden;

      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.015em;
        color: $black;
      }

      img {
        max-height: 250px;
        object-fit: cover;
        border-radius: 6px;
        background: $off-white;
        border: 2px solid $off-white;
        margin-bottom: 15px;
        @include transit;

        @include breakpoint(medium) {
          max-height: unset;
        }
      }

      @include hover-focus {
        img {
          border-color: #000000;
        }

        .title {
          text-decoration: underline;
        }
      }
    }
  }
}

.off-canvas-content .off-canvas.off-canvas-nested#locations,
.off-canvas-content .off-canvas.off-canvas-nested#recent,
.off-canvas-content .off-canvas.off-canvas-nested#featured,
.off-canvas-content .off-canvas.off-canvas-nested#brands {
  @include breakpoint(medium) {
    transform: translateX(-761px);
    width: 761px;
    padding-right: 61px;
  }

  &.is-open {
    transform: translate(0, 0);
  }
}

// Brands Offcanvas
#brands .products-wrapper {
  // gap: 40px;
  gap: 14px;

  // @include breakpoint(medium) {
  //   gap: 56px;
  // }

  a {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// Footer Offcanvas
.off-canvas-content .off-canvas.off-canvas-footer {
  width: 100%;
  border-radius: 24px 24px 0px 0px;
  padding: 52px 21px 21px;

  @include breakpoint(medium) {
    height: 350px;
    transform: translateY(350px);
  }

  @include breakpoint(large) {
    padding: 56px 24px 24px;
  }

  &.is-open {
    transform: translate(0, -113px) !important;

    @include breakpoint(large) {
      transform: translate(0, 0) !important;
    }
  }

  @include breakpoint(medium down) {
    .menu li a {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .close-button {
    position: absolute;
    top: 21px;
    right: 21px;

    @include breakpoint(large) {
      top: 11px;
      left: 11px;
      right: unset;
    }
  }

  .copy {
    font-size: 16px;
  }
}

// Resource Footer
#resources-footer {
  height: 450px;
  transform: translateY(450px);

  .button {
    margin-top: 32px;
    min-width: 157px;

    &.hollow {
      margin-top: 17px;
    }
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 34px;
  }

  .menu li a {
    font-size: 16px;
    line-height: 22px;
  }
}

// Locations Footer
#locations-footer {
  height: 325px;
  transform: translateY(325px);

  .button {
    margin-top: 32px;
  }

  p a {
    color: $grey;
    text-decoration: none;

    @include hover-focus {
      text-decoration: underline;
    }
  }

  .locations-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 50px;

    .location {
      margin-bottom: 0;
    }
  }

}

// Mobile Product Filters
#filter-footer {

  padding: 27px 0 0;
  height: 670px;
  transform: translateY(670px);

  &.is-open {
    transform: translate(0, 0) !important;
  }

  .close-button {
    top: 15px;
  }

  .title {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 24px;
  }

  hr {
    margin-bottom: 0;
  }

  form {
    margin-bottom: 0;
  }

  .accordion-title {
    padding: 50px 20px;
  }
}

// Search
#search-header {
  @include breakpoint (small down) {
    height: calc(100vh - 137px);
    border-radius: 0;
    transform: translateY(-100%);
    z-index: 10000;

    &.is-open {
      transform: translate(0, 137px) !important;
    }

    .title {
      margin-top: 24px;
    }

    .row {
      position: relative;
      left: unset;
      top: unset;
    }

    &+.js-off-canvas-overlay {
      display: none !important;
    }
  }
}

// Salesrep
#salesrep-list {
  @include breakpoint (small down) {
    height: calc(100vh - 137px);
    border-radius: 0;
    transform: translateY(-100%);
    z-index: 10000;

    &.is-open {
      transform: translate(0, 137px) !important;
    }

    .title {
      margin-top: 24px;
    }

    .row {
      position: relative;
      left: unset;
      top: unset;
    }

    &+.js-off-canvas-overlay {
      display: none !important;
    }
  }
}

// Login
#login {
  form {
    margin-bottom: 32px;
  }
}

// Favourites/Projects
#favourite {
  button.favourites-grid {
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: 88px 1fr;
    gap: 30px;
    align-items: center;

    img {
      justify-self: center;
    }

    @include hover-focus {
      text-decoration: underline;
    }
  }

  #create-project-btn {
    img {
      @include transit;
    }

    &.open {
      img {
        transform: rotate(45deg);
      }
    }
  }

  .create-project {
    margin-bottom: 32px;
    display: none;
    opacity: 0;
    @include transit;

    input[type="submit"] {
      width: 100%;
    }

    &.open {
      opacity: 1;
      display: block;
    }
  }
}

// Addresses
#addresses {
  h2 {
    font-size: 24px;
    line-height: 1.2;
  }

  .address {
    border: 1px solid #D9D9D6;
    border-radius: 24px;
    padding: 32px;

    width: 100%;
    margin-bottom: 20px;
    text-align: left;

    font-weight: 600;
    font-size: 18px;
    line-height: 1.2;

    .name {
      color: $silver;
    }

    &.active,
    &:hover,
    &:focus {
      border-color: $black;
    }
  }
}

// Two Factor Auth
#two-factor-auth {
  .sent-to {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 12px;
  }
  .destination {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 16px;
  }
  .form-footer {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-bottom: 46px;
    >* {
      min-width: unset;
      width: 100%;
    }
  }
  .authenticate-method {
    .checkbox-wrapper {
      display: grid;
      gap: 24px;
      margin-top: 18px;
      @include breakpoint(medium) {
        grid-template-columns: repeat(3, minmax(0, max-content));
        gap: 32px;
      }
    }
    .content {
      font-size: 18px;
      strong {
        display: block;
        font-size: 20px;
      }
    }
  }
  input.hollow {
    width: 100%;
  }
}

// Product Details
.off-canvas-content .off-canvas.position-right.product-canvas {

  @include breakpoint(medium) {
    width: 534px;
    transform: translateX(534px);
    padding-left: 40px;
    padding-right: 40px;
  }

  &.is-open {
    transform: translate(0, 0);
  }

  h2 {
    margin-bottom: 12px;
  }

  .item-num {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 24px;
  }

  .details-desc {
    font-size: 18px;
    line-height: 1.5;
    color: #272324;
    margin-bottom: 24px;

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  .brand-details {
    font-size: 18px;
    line-height: 1.2;
  }

  .specs-grid {
    margin-top: 24px;
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    gap: 24px;

    font-size: 18px;
  }

  .price-grid {
    margin-top: 24px;
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    align-items: center;
    gap: 12px 18px;

    .range {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;

      padding: 10px 28px;
      border: 0.5px solid $silver;
      border-radius: 2px;
    }

    .ppu {
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      color: #272324;
    }
	
	.selectedBreak {
	  color: $red;
	  border: 0.5px solid $red;
	}
  }

  .warranty-header {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5;
    color: #272324;
    margin-bottom: 12px;
    margin-top: 24px;
  }

  .accordion {
    margin-top: 24px;
    border-top: 1px solid #D9D9D6;

    .accordion-title,
    .accordion-content {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 24px;
      padding-top: 0;
    }

    .accordion-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 1.2;
      padding-top: 24px;
    }

    .accordion-content {
      h3 {
        margin-top: 24px;
        margin-bottom: 12px;
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2;
      }

      p {
        font-size: 18px;
        line-height: 1.2;
        color: #272324;
        margin-bottom: 12px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      a {
        font-size: 18px;
        line-height: 1.2;
        text-decoration-line: underline;
        color: #272324;
        display: block;
        margin-bottom: 12px;

        @include hover-focus {
          text-decoration: none;
          color: $red;
        }
      }
    }
  }
}

// Location Details
.off-canvas-content .off-canvas.position-right.location-canvas {

  .notice {
    display: grid;
    gap: 12px;
    grid-template-columns: auto minmax(0, 1fr);
    margin-top: 48px;
  }

  .hours-grid {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    gap: 24px;
    margin-top: 12px;

    @include breakpoint(medium) {
      gap: 4px 64px;
    }
  }
}

.js-off-canvas-overlay {
  background: rgba(0, 0, 0, 0.4);
  opacity: 0.4 !important;
  z-index: 9997;
}