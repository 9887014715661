.template-2fa {

  section.account {
    padding-top: 32px;

    @include breakpoint(medium) {
      padding-top: 60px;
    }

    .authenticate-method {
      display: grid;
      gap: 32px;

      @include breakpoint(large) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      .checkbox-wrapper {
        display: grid;
        gap: 24px;
        margin-bottom: 24px;

        @include breakpoint(medium) {
          grid-template-columns: repeat(3, minmax(0, max-content));
          gap: 32px;
        }
      }

      .content {
        font-size: 18px;

        strong {
          display: block;
          font-size: 20px;
        }
      }
    }

    .accordion-content form {
      max-width: 100%;

      @include breakpoint(medium) {
        >.input-wrapper {
          max-width: 50%;
        }
      }
    }

    .button-wrapper {
      margin-top: 32px;
      display: flex;
      flex-wrap: wrap;
      gap: 32px;

      .button {
        width: 100%;
      }

      @include breakpoint(medium) {
        gap: 12px;
        justify-content: flex-end;

        .button {
          width: auto;
        }
      }
    }
  }
}