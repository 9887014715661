header {
  z-index: 9996;

  .logo {
    width: 144px;
    // height: 15px;
    // object-fit: contain;

    @include breakpoint(medium) {
      width: auto;
      // height: 23px;
    }
  }

  nav {
    background-color: $white;
    @include transit;
  }

  .sticky.is-stuck {
    // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.05));
  }

  .top-bar {
    height: 47px;
    background: $white;
    padding: 0;
    // display: block;
    @include transit;

    @include breakpoint(medium) {
      height: $top-bar-height;
    }

    .row {
      display: flex;
      align-items: center;
      width: 100%;

      .medium-6 {
        justify-content: center;
        display: flex;
      }
    }

    .search-form,
    .search-form input[type="text"],
    .meta a,
    .meta a span,
    .meta button,
    .meta button span {
      @include transit;
    }


    &.search-open {
      .search-form {
        max-width: 100%;

        input {
          text-align: left;
        }
      }

      .meta a,
      .meta button:not(.hamburger) {

        span {
          margin-left: 0;
          max-width: 0;
          opacity: 0;
        }
      }
    }
  }

  .quote-btn,
  .quote-btn a {
    width: 100%;
  }

  .meta {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;

    a,
    button:not(.hamburger) {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: $grey;

      display: flex;
      align-items: center;

      img {
        height: 21px;

        @include breakpoint(medium) {
          height: initial;
        }
      }

      .icon-wrapper {
        position: relative;
      }
	  
	  .cart-qty {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background: $red;
        position: absolute;
        top: -10px;
        right: -10px;
        color: white;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        padding-top: 2px;
      }

      &.w-notice .icon-wrapper:after {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        background: $red;
        position: absolute;
        top: -7px;
        right: -4px;
      }

      >span {
        margin-left: 12px;
        overflow: hidden;
      }

      @include hover-focus {
        text-decoration: underline;
      }
    }
  }

  .hamburger-bun {
    height: 17px;

    @include breakpoint(medium) {
      display: flex;
      align-items: center;
      gap: 10px;

      @include hover-focus {
        cursor: pointer;
      }

      >span {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.015em;
      }

      .hamburger {
        line-height: 21px;
      }
    }
  }

  .bottom-bar {
    height: 47px;
    display: flex;
    align-items: center;

    .menu {
      button {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.015em;
        color: $grey;
        margin-left: 64px;

        @include hover-focus {
          color: $red;
          text-decoration: underline;
        }
      }
    }

    .hamburger-bun>span {
      line-height: 1;
      display: block;
    }
  }
}