.template-contact {
  main {
    background: $white;
  }

  section.hero {
    background: white;
    padding-bottom: 90px;

    @include breakpoint(large) {
      padding-bottom: 138px;
    }
  }

  section.contact,
  section.register {
    margin-top: 50px;

    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 1.2;
      margin-bottom: 12px;
    }

    form {
      background: #FFFFFF;
      border-radius: 24px;
      padding: 50px;

      .checkbox-wrapper {
        display: grid;
        gap: 32px;
        margin-bottom: 24px;

        @include breakpoint(medium) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
          gap: 56px;
        }
      }
	  
	  .checkbox-wrapper-3 {
        display: grid;
        gap: 32px;
        margin-bottom: 24px;

        @include breakpoint(medium) {
          grid-template-columns: repeat(3, minmax(0, 1fr));
          gap: 56px;
        }
      }

      .checkbox-input label {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: $black;

        gap: 14px;
        align-items: flex-start;

        strong {
          display: block;
          margin-bottom: 4px;

          @include breakpoint(medium) {
            margin-bottom: 8px;
          }
        }

        a {
          text-decoration: underline;
          color: $black;

          @include hover-focus {
            color: $red;
          }
        }

      }

      .checkbox-input.small label {
        font-size: 16px;
        line-height: 1.5;
      }
    }

    .form-copy {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }

  section.locations {

    @include breakpoint(large) {
      padding-bottom: 150px;
    }

    .location {
      margin-bottom: 32px;

      >a {
        margin-top: 0;
        text-decoration: underline;
      }
    }
  }

  section.register {
    margin-top: 0;
    padding-bottom: 60px;

    @include breakpoint(large) {
      padding-top: 150;
      padding-bottom: 150px;
    }

    input[type='submit'] {
      margin-top: 12px;
    }

    .sectioned-form-wrapper {
      margin-top: 32px;

      @include breakpoint(medium) {
        margin-top: 72px;
      }

      h2 {
        margin-bottom: 10px;
      }

      form {
        padding: 0;
        background-color: transparent;
        border-radius: unset;

        gap: 0;
        display: block;

        .form-section {
          background: #FFFFFF;
          border-radius: 24px;
          padding: 32px;
          margin-bottom: 32px;

          display: grid;
          grid-gap: 32px;

          hr {
            margin: 0;
          }

          @include breakpoint(medium) {
            padding: 50px;
          }
        }
      }
    }
  }

  .button.to-top {
    margin-bottom: 60px;
  }

  footer {
    margin-top: 0;
  }
}