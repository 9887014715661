.slider-wrapper {
  opacity: 0;
  @include transit;
}

.slider-wrapper.slick-initialized {
  opacity: 1;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  left: -15.5px;
  transform: translateY(-50%);
  // opacity: 0;
  @include transit;

  &.next {
    left: unset;
    right: -15.5px;
  }

  &.slick-disabled {
    opacity: 0 !important;
  }

  &.slick-hidden {
    display: none !important;
    opacity: 0 !important;
  }

  @include hover-focus {
    svg path {
      fill: #75787B;
    }
  }
}

.slick-dots {
  margin: 28px 0 0;
  list-style-type: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  li {
    height: 10px;
  }

  button {
    background: #D9D9D6;
    border-radius: 1px;
    width: 25px;
    height: 2px;
    text-indent: -99999px;
  }

  li.slick-active button,
  li:hover button,
  li:focus button {
    background: $red;
  }

  @include breakpoint(large) {
    margin: 20px 0 0;
  }
}