.template-projects {

  section.account {
    padding-top: 32px;

    @include breakpoint(medium) {
      padding-top: 60px;
    }

    h3 {
      margin-bottom: 0;

      a {
        color: $black;

        @include hover-focus {
          text-decoration: underline;
        }
      }
    }
	
	hr {
	  margin-top: 12px;
	  margin-bottom: 32px;
	  border-bottom: 1px solid #D0D0D0;
    }

    .meta-wrapper {
      button {
        margin-top: 10px;
        font-size: 16px;

        @include hover-focus {
          text-decoration: underline;
        }
      }

      @include breakpoint(medium) {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
          margin-top: 0;
        }
      }
    }

    .project-products-wrapper {
      display: grid;
      gap: 32px;
      margin-top: 12px;
      margin-bottom: 48px;

      .product-link:nth-of-type(4) {
        display: none;
      }

      @include breakpoint(medium) {
        margin-bottom: 72px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      @media only screen and (min-width: 1400px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));

        .product-link:nth-of-type(4) {
          display: flex;
        }
      }

      a.product-link {
        display: block;
        border-radius: 6px;
        background: white;
        overflow: hidden;
        border: 2px solid transparent;
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(medium) {
          aspect-ratio: 1/1;
        }

        // img {
        //   object-fit: contain;
        // }

        @include hover-focus {
          border-color: #000000;
        }
      }
    }
	
	.project-products-wrapper-list {
		.details-grid {
		  font-weight: 400;
		  font-size: 18px;
		  line-height: 1.2;

		  display: grid;
		  grid-template-columns: max-content 1fr;
		  gap: 8px 24px;

		  >.full {
			grid-column: 1 / -1;
		  }

		  form,
		  .actions {
			grid-column: 1 / -1;

			@include breakpoint(large) {
			  grid-column: initial;
			}
		  }
		}
		
		.cart-product {
		  display: grid;
		  gap: 32px;

		  @include breakpoint(medium) {
			// grid-template-columns: 1fr 3fr 1fr;
			grid-template-columns: 130px 1fr 100px;
		  }

		  @media only screen and (min-width: 1920px) {
			gap: 132px;
		  }

		  .details {

			h3,
			h4 {
			  font-weight: 600;
			  font-size: 20px;
			  line-height: 1.2;
			  margin-bottom: 12px;

			  span.unit-count {
				color: $black;
				font-weight: 400;
			  }
			  
			  @include hover-focus {
			    text-decoration: underline;
			  }
			}
		  }

		  .price {
			font-weight: 600;
			font-size: 20px;
			line-height: 1.2;
			margin-bottom: 12px;
		  }
		  
		  .quantity {
			width: 100%;
			
			@include breakpoint(large) {
			  width: 50%
			}
		  }
		}
		
		.project-card {
		  display: grid;
		  gap: 32px;

		  @include breakpoint(medium) {
			// grid-template-columns: 1fr 3fr 1fr;
			grid-template-columns: repeat(3, 1fr);
		  }

		  @media only screen and (min-width: 1920px) {
			gap: 132px;
		  }
		}
	}
  }
}