.template-claims {

  section.account {
    padding-top: 32px;

    @include breakpoint(medium) {
      padding-top: 60px;
    }

    .claims-filters {
      margin-bottom: 32px;

      h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
      }

      @include breakpoint(medium) {
        margin-bottom: 72px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }

    .inner-frame {
      margin-bottom: 0px;
    }

    form {
      input[type="submit"] {
        margin-left: auto;
        margin-right: unset;
      }
    }

    .product-details {
      display: grid;
      gap: 12px;
      margin-bottom: 32px;

      @include breakpoint(medium) {
        gap: 32px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        justify-content: space-between;
      }

      @include breakpoint(large) {
        margin-bottom: 60px;
        grid-template-columns: repeat(5, minmax(0, max-content));
        justify-content: space-between;
      }

      .detail {
        .label {
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          margin-bottom: 10px;
        }
      }
    }

    .claim-reason {
      display: grid;
      gap: 32px;

      @include breakpoint(large) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      .checkbox-wrapper {
        display: grid;
        gap: 24px;
        margin-bottom: 24px;

        @include breakpoint(medium) {
          grid-template-columns: repeat(2, minmax(0, max-content));
          gap: 16px 32px;
        }
      }

      .content {
        font-size: 18px;

        strong {
          display: block;
          font-size: 20px;
        }
      }
    }
  }
}