section.locations {
  .location {
    margin-bottom: 64px;

    @include breakpoint(large) {
      margin-bottom: 100px;
    }
  }

  .img-wrapper {
    margin-bottom: 24px;
    position: relative;
    padding-top: 418/568 * 100%;

    img {
      border-radius: 6px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 12px;

    &[data-toggle] {
      @include hover-focus {
        text-decoration: underline;
      }
    }
  }

  img,
  .title[data-toggle] {
    @include hover-focus {
      cursor: pointer;
    }
  }

  a {
    color: $black;

    @include hover-focus {
      text-decoration: underline;
    }
  }

  .location-detail {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 4px;

    &.highlight,
    &.highlight a {
      color: #0a6192;
    }
  }

  .location>a {
    margin-top: 12px;
    display: flex;
    align-items: center;
    gap: 8px;

    @include hover-focus {

      svg path:nth-child(1),
      svg path:nth-child(3) {
        fill: #D9D9D6;
      }
    }
  }
}