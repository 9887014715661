.card {
    border-radius: $rounded-sm;
    background-color: #ffffff;
    display: block;
    position: relative;
    overflow: hidden;
    @include transit;

    &-approval {
		&:after {
		  content: '';
		  position: absolute;
		  top: 0;
		  left: 0;
		  right: 0;
		  bottom: 0;
		  border: solid 2px $light-grey-50;
		  border-radius: $rounded-sm;
		  @include transit;
		}
	}

    .row {
      position: relative;
      z-index: 10;
    }

    .inner-content {
        padding: $h-w-4 $h-w-5;
    }

    &-account {
        display: block;
        font-weight: $font-heavy;
        position: relative;

        &-image {
          display: flex;

          @include breakpoint(small down) {
            flex-wrap: wrap;
          }
        }

        .inner-image {
          top: 0;
          left: 2px;
          bottom: 0;
          right: 2px;
          background-size: contain !important;
        }

        textarea {
          height: 140px !important;
        }

        .top,
        .bottom {
            display: flex;
            flex-wrap: wrap;

            > div {
                padding: $h-w-1;
            }

            .first {
                width: 25%;
            }
        }

        .top {
            font-size: $text-base;
            padding: $h-w-3;
        }

        .bottom {
            background-color: $salt-grey;
            font-size: $text-sm;
            padding: $h-w-2 $h-w-3;
            text-transform: uppercase;
        }

        .left {
            position: relative;
            width: 450px !important;

            @include breakpoint(small down) {
              width: 100%;
              max-width: 350px !important;
              min-height: 350px;
              margin: 0 auto;
            }
        }

        .right {
            width: 100%;
            font-size: $text-base;
            padding: $h-w-4;
        }

        &-delete {
          .right {
            padding: $h-w-4 $h-w-4 $h-w-10;
          }
        }

        .title {
            font-weight: $font-normal;
            font-size: $text-xxs;
            text-transform: uppercase;
        }

        .delete {
            position: absolute;
            bottom: $h-w-4;
            right: $h-w-4;

            img {
              width: $h-w-2;
            }
        }

        .has-checkbox {
            padding-right: 120px;

            .checkbox {
                width: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 2px;
                right: 6px;
                bottom: 2px;
                background-color: $salt-grey;
                padding: $h-w-3 $h-w-4 !important;

                label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 !important;
                    height: $h-w-3;
                    width: $h-w-3;

                    &:before {
                        position: static;
                        transform: none;
                        left: -30px;
                        z-index: 10;
                    }
                }
            }
        }
    }
}

.card.product {
  position: relative;

  @include breakpoint(medium) {
    display: flex;
    flex-direction: column;
  }

  .img-wrapper {
    height: 0;
    position: relative;
    padding-top: 100%;
    border-radius: 6px 6px 0px 0px;
    overflow: hidden;

    >img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .product-images-slider {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    >img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .slider-arrow {
    display: none;
    left: 26.75px;
    // top: 150px;
    // top: 20%;
    // transform: none;
    opacity: 0;

    &.next {
      left: unset;
      right: 26.75px;
    }

    @include breakpoint(medium) {
      display: block;
    }

    @include hover-focus {
      path:nth-child(2) {
        fill: white;
      }
    }
  }

  .slider-counter {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    position: absolute;
    bottom: 12px;
    right: 12px;

    color: $silver;

    opacity: 1;
    @include transit;

    @include breakpoint(medium) {
      opacity: 0;
      // bottom: 27px;
      right: 27px;
    }

    @include breakpoint(large) {
      bottom: 110px;
    }
  }

  .content {
    padding: 20px 11px 20px 16px;
    background: #FFFFFF;
    border-radius: 0px 0px 6px 6px;

    @include breakpoint(medium) {
      flex-grow: 1;
    }

    @include breakpoint(large) {
      position: relative;
      z-index: 2;
    }
  }

  .slick-dots {
    position: absolute;
    z-index: 3;
    width: 100%;
    margin: 0;
    bottom: 0;


    button {
      width: 25px;
    }
  }

  .variations-wrapper {
    background: #FFFFFF;
    padding: 17px 11px 17px 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%);

    @include transit;

    a.variation {
      border: 1px solid #D9D9D6;

      @include hover-focus {
        border-color: $red;
      }
    }

    img {
      width: 62px;
      height: 62px;
      object-fit: contain;
    }

    .remaining {
      font-weight: 400;
      font-size: 16px;
      color: $silver;

      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .favourite {
    position: absolute;
    top: 12px;
    right: 12px;

    @include breakpoint(medium) {
      top: 22px;
      right: 11px;
    }

    path {
      transition: none;
    }

    &:hover,
    &:focus,
    &.active {
      path {
        fill: $red;
        stroke: $red;
      }
    }
  }

  .featured-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    // line-height: 22px;
    color: $red;
    margin-bottom: 6px;
    position: absolute;
    background: white;
    border-radius: 6px;
    padding: 8px;
    left: 20px;
    top: 20px;
    z-index: 1;

    @include breakpoint(medium) {
      font-size: 16px;
    }
  }

  .product-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    color: $black;

    @include breakpoint(medium) {
      font-size: 20px;
    }

    @include hover-focus {
      text-decoration: underline;
    }
  }

  .price {
    margin-top: 12px;
    font-size: 14px;
    line-height: 1;

    @include breakpoint(medium) {
      font-size: 20px;
      line-height: 1.2;
    }
  }

  .category {
    margin-top: 12px;
    margin-bottom: 6px;
  }

  .category,
  .meta {
    font-size: 14px;
    line-height: 1;
    color: $silver;

    @include breakpoint(medium) {
      font-size: 16px;
    }
  }

  @include hover-focus {
    cursor: pointer;

    .slick-arrow,
    .slider-counter {
      opacity: 1;
    }

    .variations-wrapper {
      transform: translate(0, 0);
    }
  }
}

.card.article {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-bottom: 32px !important;

  @include breakpoint(medium) {
    display: block;
  }

  @include breakpoint(large) {
    margin-bottom: 90px !important;
  }

  img {
    border-radius: 6px;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include breakpoint(medium) {
      margin-bottom: 32px;
      height: auto;
    }
  }

  .category {
    font-size: 18px;
    line-height: 1.2;
    color: $red;
    margin-bottom: 12px;
  }

  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.2;

    @include breakpoint(medium) {
      font-size: 24px;
      line-height: 1.25;
    }
  }

  .date {
    margin-top: 12px;
    font-size: 18px;
    line-height: 1.2;
    color: $silver;
  }

  @include hover-focus {
    h3 {
      text-decoration: underline;
    }
  }

  &.white {

    .content {
      background: $white;
      opacity: 0;
      @include transit;
    }

    h3 {
      font-weight: 400;
      color: black;

      @include breakpoint(medium) {
        font-size: 36px;
        line-height: 1.2;
      }
    }

    .date,
    .category {
      font-size: 20px;
      color: #727272;
    }
  }
}

.card.search {
  .img-wrapper {
    height: 0;
    position: relative;
    padding-top: 218 / 268 * 100%;

    @media only screen and (min-width: 1400px) {
      padding-top: 60%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .content {
    padding: 0 4px;
  }

  .product-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    color: $black;
  }

  .product-title,
  .category {
    margin-bottom: 12px;
  }

  .category,
  .meta {
    font-size: 14px;
    line-height: 1;
    color: $silver;
  }

  @include hover-focus {
    .product-title {
      text-decoration: underline;
    }
  }
}

.card.order {
  background: #FFFFFF;
  border-radius: 24px;
  padding: 32px;
  margin-bottom: 32px;
  position: relative;

  display: grid;
  gap: 24px;

  @include breakpoint(large) {
    gap: 42px;
    margin-bottom: 72px;
    grid-template-columns: 1fr 72px;
    align-items: center;
  }

  .alert {
    position: absolute;
    top: -16px;
    right: -16px;
  }

  .inner-row {
    display: grid;
    gap: 24px;
    margin-bottom: 24px;

    @include breakpoint(medium) {
      padding-bottom: 24px;
      border-bottom: 1px solid #EEEEEE;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
      border: none;
    }
  }

  .value.red {
    color: $red;
  }

  .label {
    font-size: 16px;
  }

  .actions {
    display: flex;
    gap: 16px;
    align-items: flex-end;

    a {
      line-height: 1;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 43px;
        height: 43px;
        border-radius: 100%;
        background: transparent;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      img {
        position: relative;
        z-index: 1;
      }

      @include hover-focus {
        &:before {
          background: #D9D9D9;
        }
      }

    }
  }
}