.template-catalogue-landing {

  section.articles-grid {
    .card.article img {
      aspect-ratio: 8.5/11;
      width: 100%;
      object-fit: cover;
    }
  }
  
  section.breadcrumbs {
    margin: 100px 0 0;
    padding-top: 32px;

    @include breakpoint(medium) {
      margin: 0;
    }

    .crumbs {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      a {
        color: $black;
      }

      span {
        font-weight: 400;
      }

      @include breakpoint(medium) {
        font-weight: 600;
      }
    }
  }
}