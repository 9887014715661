@mixin transit($speed: 300ms) {
    transition: all $speed ease-in-out;
}

@mixin hover-focus {
    &:hover,
    &:focus {
        @content;
    }
}

@mixin hover-focus-active {
    &:hover,
    &:focus,
    &active {
        @content;
    }
}

@mixin flex-center {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}
