.template-thanks {
  section.more {

    a {
      position: relative;
      border-radius: 6px;
      overflow: hidden;
      display: block;
      margin-bottom: 24px;

      img {
        width: 100%;
        max-height: 651px;
        object-fit: cover;
      }

      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 26px;
        text-align: center;
        color: #FFFFFF;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        z-index: 1;

        @include breakpoint(large) {
          font-size: 48px;
          line-height: 1;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
      }

      @include hover-focus {
        .title {
          text-decoration: underline;
        }
      }
    }
  }
}