.range-wrapper {
  margin-top: 30px;
}

.slider {
  height: 2px;
  margin-top: 16px;
  margin-bottom: 16px;
  background: rgba(120, 120, 120, 0.2);

  .slider-fill {
    height: 2px;
    background: $silver;
  }

  .slider-handle {
    background: white;
    height: 35px;
    width: 35px;
    border-radius: 100%;
  }
}

.slider-inputs {
  margin-top: 46px;
  display: flex;
  align-items: center;
  gap: 12px;

  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
  }

  .range-input-wrapper {
    border: 0.5px solid $silver;
    border-radius: 2px;
    padding: 8px;
    position: relative;

    input {
      margin: 0;
      background: transparent;
      border: none;
      box-shadow: none;
      // width: auto;
      height: auto;
      padding: 0;

      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #272324;
    }

    span {
      position: absolute;
      top: 24px;
      right: 8px;
    }
  }
}