.template-brand {

  section.brand-details {
    h2 {
      font-weight: 600;
      font-size: 36px;
      line-height: 42px;
      margin-bottom: 12px;

      @include breakpoint(medium) {
        margin-bottom: 24px;
        font-size: 64px;
        line-height: 72px;
      }
    }

    a.button {
      margin-top: 24px;
    }

    .column {
      align-self: center;
      position: relative;
    }

    .projects-slider-wrapper {
      margin-bottom: 24px;

      @include breakpoint(medium) {
        margin-top: 32px;
        margin-bottom: 0;
        clip-path: inset(-100vw -100vw -100vw 0);
      }

      @include breakpoint(large) {
        margin-top: 0;
      }

      .slick-list {
        // padding-left: 90px;
        overflow: visible;
        margin: 0 -7.5px;

        @include breakpoint(medium) {
          margin: 0 -16px;
          // padding-left: 0;
        }
      }

      .slick-slide {
        margin: 0 7.5px;

        @include breakpoint(medium) {
          margin: 0 16px;
        }
      }
    }

    .slider-arrow {
      left: -6px;
      opacity: 0;

      @include hover-focus {
        path:nth-child(2) {
          fill: white;
        }
      }

      @include breakpoint(medium) {
        opacity: 1;
      }
    }

    .slider-arrow.next {
      left: unset;
      right: -6px;
    }

    .project {
      position: relative;
      border-radius: 6px;
      overflow: hidden;

      @include breakpoint(small down) {
        width: 228px;
        height: 349px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      img {
        width: 100%;
        max-height: 610px;
        object-fit: cover;
        aspect-ratio: 718/610;
      }

      .fake-hotspot {
        position: absolute;
        left: 18px;
        bottom: 18px;
        z-index: 2;

        .content {
          padding: 16px;
          background: #FFFFFF;
          border-radius: 6px;
          // box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25);

          min-width: 157px;

          .hotspot-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 1;
            margin-bottom: 12px;
          }

          .desc {
            font-size: 16px;
            line-height: 1;
            margin-bottom: 24px;
          }

          .favourite {
            path {
              transition: none;
            }

            &:hover,
            &:focus,
            &.favourited {
              path {
                fill: $red;
                stroke: $red;
              }
            }
          }

          a:hover {
            svg path {
              fill: #75787B;
            }
          }
        }
      }

      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 26px;
        text-align: center;
        color: #FFFFFF;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        z-index: 1;

        @include breakpoint(medium) {
          font-size: 48px;
          line-height: 36px;
        }
      }
    }
  }
  
  article {
    margin-top: 32px;

    @include breakpoint(large) {
      margin-top: 90px;
    }

    .inner-article {
      margin: auto;
      gap: 20px 0;
      grid-template-rows: auto;
      grid-template-columns: 6% minmax(0, 1fr) 6%;

      @include breakpoint(medium) {
        grid-template-columns: 12.5% minmax(0, 1fr) 12.5%;
      }

      >*:first-child {
        margin-top: 0;
      }

      >* {
        grid-column: 2 / -2;
      }

      img,
      .video-wrapper,
      blockquote,
      >figure,
      .two-images,
      .story-slider {
        grid-column: 1 / -1;
      }

      .video-wrapper {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;
        margin-top: 32px;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }
      }

      figure {
        figcaption {
          font-size: 18px;
          line-height: 1.2;
        }

        @include breakpoint(medium) {
          margin-top: 32px;
          margin-bottom: 32px;
        }
      }

      img {
        width: 100%;
        border-radius: 6px;
        margin-bottom: 12px;
      }

      h2 {
        margin-top: 24px;
        font-weight: 600;
        font-size: 24px;
        line-height: 1.2;
      }

      h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2;
      }

      blockquote {
        border-left: 7px solid $red;
        padding: 20px 0 20px 32px;
        margin-top: 32px;
        margin-bottom: 32px;

        p {
          color: $black;
          font-size: 26px;
          line-height: 1.2;
        }

        .author {
          font-size: 18px;
          line-height: 1.2;
          color: $black;
        }
      }

      .two-images {
        display: grid;
        gap: 32px;

        @include breakpoint(medium) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }

      .story-slider {
        margin-top: 32px;
        position: relative;

        .slider-arrow {
          display: none;

          @include breakpoint(medium) {
            margin-top: calc((788 / 1168 * 100%) + 32px);
            top: 0;
            right: 0;
            left: unset;
            transform: none;

            &.slick-disabled {
              opacity: 1 !important;

              path {
                stroke: $silver;
              }

              @include hover-focus {
                cursor: not-allowed;
              }
            }

            @include hover-focus {
              path {
                stroke: $red;
                fill: none;
              }
            }
          }
        }

        .prev {
          right: 50px;
        }
      }

      .slider-counter {
        display: none;

        @include breakpoint(medium) {
          display: block;
        }

        position: absolute;
        right: 0;
        bottom: 12px;

        font-weight: 400;
        font-size: 16px;
        color: $silver;
      }

      .story-slider-wrapper {
        // margin-top: 32px;

        h2 {
          margin-bottom: 20px;
        }

        img {
          margin-bottom: 32px;

          @include breakpoint(medium) {
            margin-bottom: 90px;
          }
        }

        figure {
          margin-top: 0;
        }

        figcaption {
          padding-left: 6%;
          padding-right: 6%;

          @include breakpoint(medium) {
            padding-left: 12.5%;
            padding-right: 12.5%;
          }
        }
      }

      .article-footer {
        margin-top: 32px;
        padding-top: 32px;
        border-top: 1px solid #000000;

        @include breakpoint(large) {
          margin-top: 64px;
          padding-top: 64px;
        }

        h2 {
          margin-top: 0;
          margin-bottom: 12px;
        }

        .bio {
          font-weight: 400;
          font-size: 16px;
          line-height: 1.5;
        }
      }
    }
  }
  
  section.gallery {
    .gallery-wrapper {
      column-count: 2;
      column-gap: 12px;
      margin-top: 24px;

      @include breakpoint(medium) {
        column-count: 3;
        column-gap: 32px;
        margin-top: 0px;
      }

      .gallery-item {
        margin-bottom: 12px;

        @include breakpoint(medium) {
          margin-bottom: 32px;
        }

        .hotspot-container {
          padding-bottom: 602px / 568px * 100%;
        }

        &.tall {
          .hotspot-container {
            padding-bottom: 796px / 570px * 100%;
          }
        }

        .hotspot {
          display: none;

          @include breakpoint(large) {
            display: block;
          }
        }
      }
    }
	
	.gallery-wrapper-first {
	  @include breakpoint(medium) {
        margin-top: 50px !important;
      }
	}
  }

  section.products-filters,
  section.mobile-filters {
    margin-top: 50px;
  }

  section.content-blocks {
    background: $white;
    margin-top: 0;
    padding-bottom: 90px;
    padding-top: 90px;

    @include breakpoint(medium) {
      padding-top: 150px;
    }
  }

  section.shop-more,
  .back-to-top {
    background: white;
  }
}