body {
  background-color: $white;
}

:focus {
  // outline: 3px solid $red;
  // outline-offset: 2px;
  outline: none !important;
  transition: none;
}

.cbm-container {
  max-width: 85vw;
  width: 100%;
  margin: 0 auto;
}

.cbm-container-hero {
  max-width: 95vw;
  width: 100%;
  margin: 0 auto;
}

.row {
  max-width: 96vw;
}

// Chrome image quality bug when resizing
@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
  img {
    image-rendering: -webkit-optimize-contrast !important;
  }
}

/* Unset for Safari 11+ */
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    img {
      image-rendering: unset !important;
    }
  }
}

hr {
  margin: 0;
  margin-bottom: 32px;
  max-width: 100%;
  border-color: rgba($light-grey, 0.2);
}

a {
  color: $red;

  @include hover-focus {
    color: $black;
  }
}

section.hero+section {
  margin-top: 48px;
}

section:not(.hero) {
  margin-top: 90px;

  @include breakpoint(large) {
    margin-top: 100px;
  }
}

a,
button,
.button,
svg,
svg path,
svg circle,
svg {
  transition: all 150ms ease-in;
}

.accessible {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  border: 0 none !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px;
}

.skipnav {

  &:focus,
  &:active {
    position: absolute !important;
    top: 5px !important;
    left: 42% !important;
    width: 16%;
    background-color: $black;
    padding: 10px;
    color: $white !important;
    z-index: 9999;
    text-align: center;
    clip: inherit;
    overflow: hidden;
    border: 0 none !important;
    width: auto !important;
    height: auto !important;
    margin: inherit;
  }
}

.sticky-content {
  // @include transit;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);

  &.stuck {
    z-index: 999;
    opacity: 1;
  }

  &.unstuck {
    opacity: 0;
    pointer-events: none;
  }
}

.mb-5 {
  margin-bottom: 2.5rem !important;
}

.text-lg {
  font-size: 2rem !important;	
}

section.mobile-search-wrapper {
  padding: 18px 0;
  margin-top: 0;
  top: 47px;
  width: 100%;
  background-color: white;
}

@media screen and (max-width: 1279px) {
  .show-for-xlarge {
    display: none !important;
  }
}