.template-product-single {
  main {
    background: $white;
  }

  section.breadcrumbs {
    margin: 100px 0 0;
    padding-top: 32px;

    @include breakpoint(medium) {
      margin: 0;
    }

    .crumbs {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      a {
        color: $black;
      }

      span {
        font-weight: 400;
      }

      @include breakpoint(medium) {
        font-weight: 600;
      }
    }
  }

  .product-wrapper-grid {
    >div {
      margin-bottom: 24px;
    }

    @include breakpoint(medium) {
      margin: 0 -12px;

      >div {
        padding: 0 12px;
        margin-bottom: 0;
        width: 50%;
        max-width: 50%;
      }

      .product-gallery,
	  .products-wrapper,
      .product-information {
        float: left;
      }

      .product-header,
      .product-ordering {
        float: right;
      }
    }

    @include breakpoint(large) {

      .product-gallery,
	  .products-wrapper,
      .product-information {
        width: 66.66667%;
        max-width: 66.66667%;
      }

      .product-header,
      .product-ordering {
        width: 33.33333%;
        max-width: 33.33333%;
      }
    }
  }

  section.product-details {
    margin-top: 0;
    padding-top: 32px;

    .product-gallery {
      .slick-dots {
        margin-top: 12px;
      }

      @include breakpoint(medium) {
        display: grid;
        gap: 30px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      .img-wrapper {
        border-radius: 6px;
        overflow: hidden;

        img,
        video {
          border-radius: 6px;
          width: 100%;
          height: 375px;
          object-fit: cover;
        }

        @include breakpoint(medium) {
          height: 0;
          padding-top: 613px / 568px * 100%;
          position: relative;
          width: 100%;

          img,
          video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .item-num {
      margin-top: 24px;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;

      @include breakpoint(medium) {
        margin-top: 12px;
        font-size: 18px;
        line-height: 24px;
      }
    }

    .product-desc {
      margin-top: 12px;
      margin-bottom: 24px;
      line-height: 24px;
      color: #272324;
    }

    button.product-specs {
      padding-top: 24px;
      padding-bottom: 24px;
      border-top: 1px solid #D0D0D0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-weight: 600;
      font-size: 18px;
      line-height: 24px;

      @include breakpoint(medium) {
        font-size: 24px;
        line-height: 34px;
      }

      &:last-of-type {
        border-bottom: 1px solid #D0D0D0;
      }

      @include hover-focus {
        svg path {
          fill: #75787B;

          &:nth-child(2) {
            fill: white;
          }
        }
      }
    }

    h1 {
      font-weight: 600;
      font-size: 26px;
      line-height: 28px;
      letter-spacing: 0.5px;

      @include breakpoint(medium) {
        font-weight: 400;
        font-size: 36px;
        line-height: 1.2;
        // margin-bottom: 12px;
        letter-spacing: normal;
      }
    }

    .brand-link {
      font-size: 16px;
      line-height: 1.2;
      margin-bottom: 24px;
      text-decoration-line: underline;
      color: $black;

      @include hover-focus {
        color: $red;
      }
    }
	
	.part-num {
      font-size: 20px;
      line-height: 1.2;
      color: $black;
    }
	
	.alt-part-num {
      font-size: 16px;
      line-height: 1.2;
      color: $black;
    }

    .pricing-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;

      .price {
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2;

        span {
          &.silver {
            color: $silver;
          }

          &.sale {
            color: $red;
          }
        }

        s {
          color: $silver;
        }
      }
    }

    .pricing-notice,
    .pricing-notice button,
    .pricing-wrapper button {
      font-size: 20px;
      line-height: 26px;
      color: $silver;

      @include breakpoint(medium) {
        font-size: 16px;
        line-height: 1;
        color: $black;
      }
    }

    .pricing-notice button,
    .pricing-wrapper button {
      text-decoration: underline;

      @include hover-focus {
        color: $red;
      }
    }

    .create-account-cta,
    .create-account-cta a {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    .pricing-notice+.pricing-wrapper,
    .create-account-cta+.pricing-wrapper {
      margin-top: 12px;
    }

    hr {
      border-color: #D0D0D0;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .variation-name,
    .error-message {
      font-weight: 600;
      font-size: 16px;
      line-height: 1;
      margin-bottom: 12px;

      &.error {
        color: $red;
      }

      span {
        color: $silver;
      }
    }

    .error-message {
      margin-top: 12px;
      color: $red;
    }

    .finishes-wrapper {
      margin-top: 12px;
      position: relative;

      .slick-prev {
        display: none !important;
      }

      .slider-arrow.next {
        right: 0;

        @include hover-focus {
          svg path:nth-child(2) {
            fill: white;
          }
        }
      }

      .slick-list {
        margin: 0 -3.5px;
      }

      .slick-slide {
        margin: 0 3.5px;
      }

      @include breakpoint(medium) {
        padding-right: 50px;
      }
    }

    .finishes-slider {
      .finish {
        border: 1.5px solid transparent;
        border-radius: 6px;
        overflow: hidden;
        background: white;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &:hover,
        &:focus {
          border-color: $light-grey;
        }


        &.active {
          border-color: $red;
        }
      }
    }
	
	.products-wrapper {
      margin-top: 12px;
      position: relative;

      .slick-prev {
        display: none !important;
      }

      .slider-arrow.next {
        right: 0;

        @include hover-focus {
          svg path:nth-child(2) {
            fill: white;
          }
        }
      }

      .slick-list {
        margin: 0 -3.5px;
      }

      .slick-slide {
        margin: 0 3.5px;
      }

      @include breakpoint(medium) {
        padding-right: 50px;
      }
    }

    .products-slider {
      .product {
        border: 1.5px solid transparent;
        border-radius: 6px;
        overflow: hidden;
        background: white;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &:hover,
        &:focus {
          border-color: $light-grey;
        }


        &.active {
          border-color: $red;
        }
      }
    }

    .meta {
      display: flex;
      align-items: center;
      gap: 12px;

      font-weight: 400;
      font-size: 16px;
      line-height: 1;

      >div {
        padding: 7px 7px 5px 0px;
      }

      .stock {
        padding: 7px 7px 5px 7px;
        background: #ECECEC;
      }
    }

    .unit-toggle {
      display: flex;
      margin-bottom: 24px;

      button {
        padding: 9px 4px;
        min-width: 58px;
        border: 1px solid $silver;
        border-radius: 2px 0px 0px 2px;

        font-weight: 400;
        font-size: 12px;
        line-height: 1;

        &:last-of-type {
          border-radius: 0px 2px 2px 0px;
        }

        &:hover,
        &:active {
          border-color: $red;
        }

        &.active {
          border-color: $black;
        }
      }
    }

    .button-grid {

      &.small {
        display: grid;
        gap: 12px;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        @include breakpoint(medium) {
          gap: 18px;
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
      }

      &.large {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;

        button {
          padding: 12px;
        }

        @include breakpoint(medium) {
          gap: 18px;
        }
      }

      button {
        position: relative;
        // border: 1px solid $silver;
        box-shadow: inset 1px 1px 0 0 $silver, inset -1px -1px 0 0 $silver;
        border-radius: 2px;
        padding: 16px 12px;
        overflow: hidden;

        font-weight: 400;
        font-size: 16px;
        line-height: 1;
        color: $silver;

        @include breakpoint(medium) {
          padding: 6px 12px;
        }

        &:hover,
        &:focus {
          color: $red;
          box-shadow: inset 2px 2px 0 0 $red, inset -2px -2px 0 0 $red;
          // border-color: $red;
        }

        &.active {
          color: $red;
          box-shadow: inset 2px 2px 0 0 $red, inset -2px -2px 0 0 $red;
          // border-color: $black;
        }


        &.disabled {
          border-color: $silver;
          color: $silver;
          background: linear-gradient(to bottom right, transparent, transparent 48%, #D9D9D6 48%, #D9D9D6 52%, transparent 52%, transparent);
        }
      }
    }

    a {
      font-weight: 400;
      font-size: 18px;
      line-height: 1.2;
      text-decoration-line: underline;
      color: #272324;

      @include hover-focus {
        color: $red;
      }
    }

    .cart-buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      margin-bottom: 24px;

      .favourite {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 63px;
        height: 52px;

        background: #FFFFFF;
        border: 1px solid $black;
        border-radius: 6px;

        svg path {
          transition: none;
        }

        &:hover,
        &:focus,
        &.active {
          svg path {
            stroke: $red;
            fill: $red;
          }
        }
      }

      .quantity {
        padding: 14px 15px;

        width: 86px;
        height: 52px;

        background: #FFFFFF;
        border: 1px solid $black;
        border-radius: 6px;

        font-weight: 600;
        font-size: 16px;
        line-height: 1;
        color: #000000;
      }

      form {
        flex-grow: 1;
        display: flex;
        align-items: center;
        gap: 12px;

        input[type="submit"], a {
          flex-grow: 1;
          padding: 16px 20px;
        }
		
		a {
		  font-weight: 600;
		  font-size: 16px;
		  line-height: 18px;
		  text-decoration-line: none;
		  color: #fff;
		}
      }
    }

    .mobile-add-to-cart {
      margin-top: 12px;
      padding-top: 12px;
      padding-bottom: 12px;
      background: $white;
      z-index: 99;

      input {
        width: 100%;
      }
    }

    .negative-pin-spacer {
      margin-top: -66px;

      @include breakpoint(medium) {
        margin-top: 0;
      }
    }

    .ordering-info {
      border: 1px solid #D9D9D6;
      border-radius: 24px;
      padding: 15px 20px;

      button,
      a {
        display: grid;
        gap: 24px;
        grid-template-columns: 26px 1fr 10px;
        text-align: left;
        width: 100%;
        margin-bottom: 24px;
        text-decoration: none;

        @include hover-focus {
          color: $black;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .title {
          font-weight: 600;
          font-size: 16px;
          line-height: 1.2;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 1.2;
          margin-bottom: 0;
        }

        @include hover-focus {
          .title {
            text-decoration: underline;
          }
        }
      }
    }
  }

  section.related-products {
    margin-top: 0;
    padding-top: 124px;
    padding-bottom: 60px;

    @include breakpoint(large) {
      padding-top: 187px;
      padding-bottom: 150px;
    }

    .row {
      position: relative;
    }

    h2 {
      margin-bottom: 50px;
    }

    .related-wrapper {
      display: grid;
      gap: 24px;

      @include breakpoint(medium) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @include breakpoint(large) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }

    .product-category {
      align-self: flex-start;

      .inner {
        height: 0;
        padding-top: 100%;
        position: relative;
      }

      .promo {
        background: $red;
        padding: 41px;
        border-radius: 6px;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;

        .header {
          font-weight: 600;
          font-size: 24px;
          line-height: 34px;
          color: #FFFFFF;
          margin-bottom: 36px;
        }
      }
    }
  }

  .button.to-top {
    margin-bottom: 60px;
  }

  footer {
    margin-top: 0;
  }
}