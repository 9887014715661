.template-get-started-v2 {
  section.hero-grid {
    margin-top: 100px;

    @include breakpoint(medium) {
      margin-top: 11px;
    }

    .hero-grid-wrapper {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 11px;

      .txt-wrapper {
        grid-column: span 2;
      }

      .wide {
        grid-row: span 2;
      }

      @include breakpoint(large) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        // grid-auto-rows: 1fr;
        gap: 32px;

        .wide {
          grid-column: span 2;
          grid-row: span 1;
        }

        .txt-wrapper {
          grid-row: span 2;
        }
      }
    }

    .txt-wrapper {
      border-radius: 6px;
      background: $grey;
      padding: 32px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include breakpoint(large) {
        padding: 48px;
      }

      @media only screen and (min-width: 1440px) {
        padding: 100px;
      }

      h1 {
        font-size: 48px;
        font-weight: 600;
        line-height: 110%;
        margin-bottom: 11px;

        @include breakpoint(medium) {
          margin-bottom: 34px;
        }

        @media only screen and (min-width: 1440px) {
          font-size: 96px;
          line-height: 100%;
          letter-spacing: -0.96px;
        }
      }

      .subtitle {
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.18px;
        color: white;
        margin-bottom: 7px;

        @include breakpoint(medium) {
          font-size: 32px;
          line-height: 40px;
          margin-bottom: 35px;
        }
      }

      .button {
        margin-top: 15px;
        width: 100%;
        max-width: 300px;
      }

      .hollow {
        background: transparent;
        color: white;
        border-color: white;

        @include hover-focus {
          background: $silver;
          border-color: $silver;
        }
      }
    }

    img {
      border-radius: 6px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  section.highlights {
    .grid-wrapper {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 11px;

      .txt-wrapper {
        grid-column: span 2;
      }

      @include breakpoint(large) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-auto-rows: 1fr;
        gap: 32px;
      }
    }

    h2 {
      font-size: 26px;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -0.26px;

      @include breakpoint(medium) {
        font-size: 64px;
      }
    }

    h3 {
      font-size: 18px;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.36px;

      @include breakpoint(medium) {
        font-size: 36px;
        font-weight: 600;
        line-height: 42px;
        letter-spacing: -0.36px;
      }
    }

    .txt-wrapper,
    .icon-wrapper,
    .cta-wrapper {
      border-radius: 6px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 14px;

      img,
      svg {
        height: 50px;
        object-fit: contain;
      }

      @include breakpoint(medium) {
        gap: 33px;

        img,
        svg {
          height: auto;
        }
      }
    }

    .icon-wrapper,
    .cta-wrapper {
      // aspect-ratio: 1/1;
      padding: 26.5px 23.5px;

      @media only screen and (min-width: 1440px) {
        padding: 110px 72px;
      }
    }

    .txt-wrapper {
      background: $grey;
      padding: 54px 44px;

      @media only screen and (min-width: 1440px) {
        padding: 72px 80px;
        gap: 26px;
        // aspect-ratio: 2/1;
      }
    }

    .icon-wrapper {
      background: $light-grey;
    }

    .cta-wrapper {
      background: $red;
    }
  }

  section.videos {

    .cbm-container {
      position: relative;
      padding-left: 12px;
      padding-right: 12px;
    }

    .slider-arrow {
      opacity: 0;
      left: -20px;
      top: 0;
      transform: none;

      // magic math
      margin-top: -55px;
      padding-top: ((540 / 1768) / 2) * 100%;

      &.next {
        left: unset;
        right: -20px;
      }

      @include hover-focus {
        svg path:nth-child(2) {
          fill: white;
        }
      }

      @include breakpoint(large) {
        opacity: 1;
      }
    }

    .video-wrapper {

      iframe,
      video {
        aspect-ratio: 16/9;
        width: 100%;
        height: unset;
        margin-bottom: 22px;
        border-radius: 6px;
      }

      .title {
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
        margin-bottom: 22px;
      }

      .copy {
        font-size: 24px;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.24px;
      }
    }

    .video-slider-wrapper {
      .slick-list {
        margin: 0 -7.5px;

        @include breakpoint(medium) {
          margin: 0 -16px;
        }
      }

      .slick-slide {
        margin: 0 7.5px;

        @include breakpoint(medium) {
          margin: 0 16px;
        }
      }
    }
  }

  section.stories {
    h2 {
      font-size: 64px;
      font-weight: 600;
      line-height: 100%;
    }

    .column.large-4:nth-child(even) {
      .story-wrapper {
        background: $grey;
      }
    }

    .column.large-4 {
      margin-top: 61px;
    }

    .story-wrapper {
      border-radius: 6px;
      overflow: hidden;
      background: $grey;
      padding-bottom: 60px;

      img {
        border-radius: 6px 6px 0 0;
        width: 100%;
      }

      .content {
        padding: 55px 61px 0;
      }

      h3 {
        margin-bottom: 20px;

        @include breakpoint(medium) {
          font-size: 36px;
          font-weight: 600;
          line-height: 42px;
          letter-spacing: -0.36px;
        }
      }

      .button {
        margin-left: 61px;
        margin-top: 22px;
      }

      @include breakpoint(medium) {
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
      }
    }
  }

  section.testimonial {
    img {
      border-radius: 6px;

      @include breakpoint(large) {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .inner-testimonial {
      background: $light-grey;
      border-radius: 6px;
      padding: 32px;
      display: grid;
      gap: 32px;
      grid-template-columns: 1fr 20px;
      align-items: center;
      margin-bottom: 22px;

      @include breakpoint (large) {
        height: 100%;
        margin-bottom: 0;
      }

      @media only screen and (min-width: 1440px) {
        padding: 107px 324px 144px 148px;
      }

      .quote {
        margin-bottom: 8px;

        @include breakpoint(large) {
          color: #000;
          font-size: 24px;
          font-weight: 500;
          line-height: 32px;
          letter-spacing: -0.18px;
        }
      }

      .author {
        font-size: 16px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.12px;
        color: black;
      }

      .closing-quote {
        color: black;
        font-weight: 600;
        font-size: 80px;
        line-height: 44px;
        letter-spacing: -2.25px;
        align-self: start;

        @media only screen and (min-width: 1440px) {
          font-size: 300px;
          align-self: unset;
        }
      }
    }

  }

  section.sign-up-cta {
    img {
      margin-bottom: 50px;
    }

    h2 {
      color: $grey;
      font-size: 64px;
      font-weight: 600;
      line-height: 100%;
      margin-bottom: 22px;
    }

    .copy.p1 {
      font-size: 24px;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.24px;
    }

    .button {
      margin-top: 30px;
    }

    @include breakpoint(large) {

      .copy,
      .button {
        width: 100%;
        max-width: 416px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}