.template-homepage {

  section.showcase {
    h2 {
      margin-bottom: 12px;
    }

    .hotspot-container {
      margin-top: 24px;

      @include breakpoint(medium) {
        margin-top: 50px;
      }
    }
  }

  section.help {
    .help-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 46px;

      @include breakpoint(medium) {
        margin-top: 70px;
        border-right: 2px solid #D9D9D6;
        padding-right: 32px;
        min-height: 130px;
      }

      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;

        @include breakpoint(medium) {
          font-size: 24px;
        }
      }

      a {
        font-size: 16px;
        line-height: 30px;
        color: #5B5B5B;
        text-decoration: underline;

        @include hover-focus {
          color: $black;
        }

        @include breakpoint(medium) {
          font-size: 20px;
        }
      }

      @include breakpoint(small down) {
        .icon-wrapper {
          width: 60px;
          height: 48px;
          text-align: center;

          img {
            height: 100%;
          }
        }
      }
    }

    .column.large-3:last-of-type .help-icon-wrapper {
      border: none;
    }
  }

  section.featured {
    h2 {
      margin-bottom: 50px;
    }

    h3 {
      margin-bottom: 12px;
    }

    .button {
      margin-top: 24px;
    }

    .featured-slider-old-wrapper .row {
      align-items: center;

      @include breakpoint(medium) {
        flex-direction: row-reverse;
        justify-content: start;
      }
    }

    .cbm-container {
      position: relative;
    }

    img {
      margin-bottom: 62px;

      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }

    .large-5 {
      text-align: center;

      @include breakpoint(medium) {
        text-align: left;
      }
    }

    .slider-arrow.next {
      right: 84.5px;
    }

    .slider-arrow.prev {
      display: none !important;
    }

    @include breakpoint(medium down) {
      .slider-arrow {
        display: none !important;
      }
    }
  }

  section.stories {
    .medium-6 {
      // display: flex;
      // flex-flow: column nowrap;
      // align-items: flex-start;

      margin-top: 24px;

      @include breakpoint(medium) {
        margin-top: 50px;
      }
    }

    .img-wrapper {
      width: 100%;
      height: 0;
      padding-bottom: 400px / 318px * 100%;
      position: relative;
      margin-bottom: 12px;
      border-radius: 6px;
      overflow: hidden;

      @include breakpoint(medium) {
        padding-bottom: 654px / 568px * 100%;
        margin-bottom: 24px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        position: absolute;
        top: 0;
        left: 0;
      }
    }

    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 12px;

      @include breakpoint(medium) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    // .copy {
    //   margin-bottom: 36px;
    // }

    a.button {
      // margin-top: auto;
      margin-top: 12px;
    }

    @include breakpoint(small down) {
      .stories-slider-wrapper .slick-list {
        padding-right: 48px;
      }

      .slick-list {
        overflow: visible;
        margin-left: -7.5px;
      }

      .slick-slide {
        margin: 24px 7.5px 0;
      }
    }
  }

  section.gallery {
    .gallery-wrapper {
      column-count: 2;
      column-gap: 12px;
      margin-top: 24px;

      @include breakpoint(medium) {
        column-count: 3;
        column-gap: 32px;
        margin-top: 50px;
      }

      .gallery-item {
        margin-bottom: 12px;

        @include breakpoint(medium) {
          margin-bottom: 32px;
        }

        .hotspot-container {
          padding-bottom: 602px / 568px * 100%;
        }

        &.tall {
          .hotspot-container {
            padding-bottom: 796px / 570px * 100%;
          }
        }

        .hotspot {
          display: none;

          @include breakpoint(large) {
            display: block;
          }
        }
      }
    }
  }
  
  section.video {
	.video-wrapper {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;
        margin-top: 32px;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }
    }
  }
  
  section.videos {

    .cbm-container {
      position: relative;
      padding-left: 12px;
      padding-right: 12px;
    }

    .slider-arrow {
      opacity: 0;
      left: -20px;
      top: 0;
      transform: none;

      // magic math
      margin-top: -55px;
      padding-top: ((540 / 1768) / 2) * 100%;

      &.next {
        left: unset;
        right: -20px;
      }

      @include hover-focus {
        svg path:nth-child(2) {
          fill: white;
        }
      }

      @include breakpoint(large) {
        opacity: 1;
      }
    }

    .video-wrapper {

      iframe,
      video {
        aspect-ratio: 16/9;
        width: 100%;
        height: unset;
        margin-bottom: 22px;
        border-radius: 6px;
      }

      .title {
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
        margin-bottom: 22px;
      }

      .copy {
        font-size: 24px;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.24px;
      }
    }

    .video-slider-wrapper {
      .slick-list {
        margin: 0 -7.5px;

        @include breakpoint(medium) {
          margin: 0 -16px;
        }
      }

      .slick-slide {
        margin: 0 7.5px;

        @include breakpoint(medium) {
          margin: 0 16px;
        }
      }
    }
  }

}