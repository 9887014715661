.template-about {
  section.hero-generic {
    img {
      margin-bottom: 33px;
    }
  }

  section.projects {
    margin-top: 90px;

    @include breakpoint(large) {
      margin-top: 150px;

      .row {
        padding-bottom: 94.5px;
      }
    }

    h2 {
      font-size: 36px;
      line-height: 1.2;
      font-weight: 600;
      color: $silver;
      @include transit;

      &.active {
        color: $black;
      }

      @include hover-focus {
        cursor: pointer;
        color: $black;
      }

      @include breakpoint(large) {
        font-size: 64px;
      }

      &:last-of-type {
        margin-bottom: 24px;

        @include breakpoint(medium) {
          margin-bottom: 36px;
        }
      }
    }

    .column {
      align-self: center;
    }

    .outer-slider-wrapper {
      max-width: 718px;
      margin-top: 32px;

      @include breakpoint(medium) {
        margin-top: 0;
        position: relative;
      }
    }

    .projects-slider-wrapper {
      margin-bottom: 24px;

      @include breakpoint(medium) {
        margin-bottom: 0;
        clip-path: inset(-100vw -100vw -100vw 0);
      }

      .slick-list {
        // padding-left: 90px;
        overflow: visible;
        margin: 0 -7.5px;

        @include breakpoint(medium) {
          margin: 0 -17px;
          // padding-le7t: 0;
        }
      }

      .slick-slide {
        margin: 0 7.5px;

        @include breakpoint(medium) {
          margin: 0 16px;
        }
      }
    }

    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 32px;
      margin-top: 32px;

      @include breakpoint(large) {
        position: absolute;
        width: 100%;
      }
    }

    .slider-arrow {
      position: relative;
      top: unset;
      left: unset;
      right: unset;
      transform: none;

      opacity: 0 !important;

      @include hover-focus {
        path:nth-child(2) {
          fill: white;
        }
      }

      @include breakpoint(medium) {
        opacity: 1 !important;

        &.slick-disabled {
          opacity: 1 !important;

          svg path {
            fill: $silver;

            &:nth-child(2) {
              fill: white;
            }
          }
        }
      }
    }

    .project {
      position: relative;
      border-radius: 6px;
      overflow: hidden;

      @include breakpoint(small down) {
        width: 228px;
        height: 349px;
      }

      @include breakpoint(medium) {
        aspect-ratio: 718/788;
      }
	  
	  img {
		height: 100%;
		object-fit: cover;
	  }

      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 1.2;
        text-align: center;
        color: #FFFFFF;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        z-index: 1;

        @include breakpoint(medium) {
          font-size: 48px;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
      }
    }
  }

  section.timeline {

    position: relative;

    .label {
      font-size: 24px;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.24px;
      margin-bottom: 16px;
    }

    h2 {
      font-size: 48px;
      font-weight: 600;
      line-height: 120%;
      // margin-bottom: 19px;

      @include breakpoint(medium) {
        font-size: 64px;
      }
    }

    h3 {
      font-size: 36px;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.48px;
      text-transform: uppercase;
      margin-bottom: 19px;

      @include breakpoint(medium) {
        font-size: 48px;
      }
    }

    .copy {
      font-size: 24px;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.24px;
    }

    img {
      border-radius: 6px;
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1468/610;

      @include breakpoint(medium) {
        margin-bottom: 130px;
      }
    }

    .entry {
      padding-top: 48px;

      @include breakpoint(medium) {
        padding-top: 154px;
      }

      h2 {
        font-size: 64px;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: -0.96px;
        color: $red;
        margin-bottom: 19px;

        @include breakpoint(medium) {
          font-size: 96px;
        }
      }
    }

    .entry.wide {
      padding-top: 0;
      padding-bottom: 30px;

      >.column {
        position: relative;
      }

      h2 {
        margin-top: 30px;
      }

      @include breakpoint(medium) {
        text-align: center;
      }
    }

    .entry.first {
      padding-top: 48px;

      .line {
        display: none;
      }

      @include breakpoint(medium) {
        padding-top: 62px;

        .line {
          position: absolute;
          display: block;
          width: 2px;
          height: 130px;
          background: #D1C5C5;
          left: 50%;
          transform: translateX(-49%) translateY(-130px);
        }

        .column:first-child:after {
          content: '';
          display: block;
          width: 17px;
          height: 17px;
          border-radius: 100%;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-49%);
          background: $red;
          z-index: 1;
        }
      }
    }

    .entry.even,
    .entry.odd {
      position: relative;

      .line {
        display: none;
      }

      @include breakpoint(medium) {

        .line {
          display: block;
          width: 2px;
          height: 100%;
          background: #D1C5C5;
          position: absolute;
          top: 0;
          left: calc(50% - 1px);
        }

        &:after {
          content: '';
          display: block;
          width: 17px;
          height: 17px;
          border-radius: 100%;
          position: absolute;
          top: calc(50% + 77px);
          left: 50%;
          transform: translateX(-49%);
          background: $red;
          z-index: 1;
        }
      }
    }

    .entry.last {
      padding-bottom: 62px;
    }
  }
}