.template-cart,
.template-checkout {

  main {
    background: $white;
  }

  section.cart,
  section.checkout {
    margin-top: 0;
    padding-top: 150px;

    @include breakpoint(medium) {
      padding-top: 66px;
    }

    .column:nth-child(2) {
      margin-top: 32px;

      @include breakpoint(large) {
        margin-top: 0;
      }
    }


    .link-w-arrow {
      display: grid;
      gap: 24px;
      grid-template-columns: max-content 42px;
      align-items: center;
      justify-content: end;

      color: $black;
      text-decoration: underline;

      svg {
        transform: rotate(180deg);
      }

      @include hover-focus {

        svg circle:first-of-type,
        svg path {
          fill: #D9D9D6;
        }
      }
    }

    h2 {
      font-size: 36px;
      line-height: 1.2;
      margin-bottom: 36px;
    }

    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 1.2;
      margin-bottom: 12px;
    }

    button.form-select {
      flex-direction: row;
      align-items: center;
      gap: 30px;
    }

    .inner-frame {
      background: #F9F9F9;
      border-radius: 24px;
      padding: 32px 16px;

      &.summary {
        @include breakpoint(medium) {
          padding: 32px;
        }
      }

      &.shopping-cart {
        hr {
          margin-top: 32px;

          // @include breakpoint(medium) {
          //   margin-top: 72px;
          //   margin-bottom: 72px;
          // }
        }
      }

      .details-grid {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.2;

        display: grid;
        grid-template-columns: max-content 1fr;
        gap: 8px 24px;

        >.full {
          grid-column: 1 / -1;
        }

        form,
        .actions {
          grid-column: 1 / -1;

          @include breakpoint(large) {
            grid-column: initial;
          }
        }
		
		.favourite {
		  display: flex;
		  justify-content: center;
		  align-items: center;

		  width: 43px;
		  height: 32px;

		  svg path {
		    transition: none;
		  }

		  &:hover,
		  &:focus,
		  &.active {
			svg path {
			  stroke: $red;
			  fill: $red;
			}
		  }
		}
      }

      .inner-split {
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-weight: 400;
        font-size: 18px;
        line-height: 1.2;
        margin-bottom: 24px;

        &.total {
          font-size: 24px;
        }
      }

      hr {
        margin-top: 12px;
        margin-bottom: 32px;
        border-bottom: 1px solid #D0D0D0;
      }

      .quantity {
        padding: 7.5px 15px;

        width: 86px;
        height: 39px;

        background: #FFFFFF;
        border: 1px solid $black;
        border-radius: 6px;

        font-weight: 600;
        font-size: 16px;
        line-height: 1;
        color: #000000;
      }
	  
	  .tag {
        padding: 7.5px 15px;

        width: 200px;
        height: 39px;

        background: #FFFFFF;
        border: 1px solid $black;
        border-radius: 6px;

        font-weight: 400;
        font-size: 16px;
        line-height: 1;
        color: #000000;
      }

      .actions {
        display: flex;
        gap: 24px;
        align-items: center;
        flex-wrap: wrap;

        button {
          display: flex;
          gap: 8px;
          text-align: left;

          @include hover-focus {
            text-decoration: underline;
          }
        }
      }

      .cart-product,
      .checkout-product {
        display: grid;
        gap: 32px;

        @include breakpoint(medium) {
          // grid-template-columns: 1fr 3fr 1fr;
          grid-template-columns: 130px 1fr 100px;
        }

        @media only screen and (min-width: 1920px) {
          gap: 132px;
        }

        .details {

          h3,
          h4 {
            font-weight: 600;
            font-size: 20px;
            line-height: 1.2;
            margin-bottom: 12px;

            span.unit-count {
              color: $black;
              font-weight: 400;
            }
          }
        }

        .price {
          font-weight: 600;
          font-size: 20px;
          line-height: 1.2;
          margin-bottom: 12px;
        }
      }

      .checkout-product {
        margin-bottom: 32px;

        @include breakpoint(medium) {
          grid-template-columns: minmax(132px, 1fr) 4fr;
          align-items: center;
        }

        @include breakpoint(large) {
          gap: 160px;
        }
      }

      a.button {
        width: 100%;
        margin-top: 12px;
      }
    }
  }

  section.checkout {
    padding-bottom: 60px;

    @include breakpoint(large) {
      padding-bottom: 150px;
    }

    .row {
      @include breakpoint(large) {
        flex-direction: row-reverse;
      }
    }

    .column {
      >hr {
        margin-top: 32px;
        margin-bottom: 32px;

        // @include breakpoint(medium) {
        //   margin-top: 72px;
        //   margin-bottom: 72px;
        // }

        border-bottom: 1px solid #D0D0D0;
      }

      >h3 {
        margin-top: 32px;

        // @include breakpoint(large) {
        //   margin-top: 72px;
        // }
      }
    }

    .inner-frame {
      padding: 32px;

      hr {
        margin-top: 32px;
        margin-bottom: 32px;
      }
    }

    .form-buttons-wrapper {
      margin-bottom: 0;
    }

    .shipping {
      .inner-split {
		gap: 12px;
        align-items: flex-start;
        font-size: 20px;

        @include breakpoint(large) {
          gap: 20%;
          justify-content: flex-start;
        }
      }
    }
	
	.pickup {
      .pickup-title {
        margin-top: 42px;
        margin-bottom: 30px;
      }
      .checkbox-wrapper {
        display: grid;
        gap: 24px;
        @include breakpoint(medium) {
          grid-template-columns: repeat(2, minmax(0, max-content));
          gap: 32px;
        }
        @include breakpoint(large) {
          gap: 80px;
        }
      }
    }

    .address {
      font-weight: 600;
      font-size: 18px;
      line-height: 1.2;

      .name {
        color: $silver;
      }

      img {
        display: block;
      }
    }

    .additional-info {
      form {
        margin-top: 12px;
      }
    }

    button.button {
      text-align: center;
      width: 100%;
      margin-top: 32px;
    }

    form {

      @include breakpoint(large) {
        max-width: 420px;
      }

      [type='checkbox']+label,
      [type='radio']+label {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        color: $black;
        gap: 10px;
      }

      // h4:not(:first-of-type) {
      //   margin-top: 32px;
      // }

      input[type='submit'] {
        width: 100%;
      }

      .cc-button {

        label:before,
        label:after {
          content: unset;
          display: none;
        }

        label {
          font-weight: 400;
          font-size: 20px;
          gap: 32px;

          border: 1px solid #D9D9D6;
          border-radius: 24px;
          padding: 28px;

          align-items: center;

          @include transit;

          .expiry {
            font-size: 18px;
            display: block;
          }

          &:hover,
          &:focus {
            border-color: $black;
          }
        }

        input:checked+label {
          border-color: $black;
        }
      }
    }

    .confirmation {
      .inner-split {
        display: grid;
        gap: 32px;
        align-items: start;

        @include breakpoint(medium) {
          gap: 60px;
          // grid-template-columns: minmax(1fr, 275px) 1fr;
          grid-template-columns: minmax(275px, 1fr) 4fr;
        }
      }
    }

    .button-wrapper {
      margin-top: 32px;
      display: grid;
      gap: 32px;

      @include breakpoint(medium) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
  }

  section.product-categories {
    margin-top: 0;
    padding-top: 90px;
    padding-bottom: 60px;

    @include breakpoint(large) {
      padding-top: 150;
      padding-bottom: 150px;
    }

    h2 {
      margin-bottom: 32px;
    }
  }

  .button.to-top {
    margin-bottom: 60px;
  }

  footer {
    margin-top: 0;
  }
  
  // Datepicker
  .datepicker-dropdown {
    background: #FFF;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);
    border-radius: 0px 24px 24px 24px;
    overflow: scroll;
    border: none;
    padding: 22px 24px;
    form [type='checkbox']+label,
    form [type='radio']+label {
      font-weight: 400;
      font-size: 14px;
      line-height: 12px;
      color: $black;
      gap: 9px;
      align-items: center;
    }
  }
  .date-picker {
    position: relative;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0px;
      background-image: url('../images/icon-new-calendar.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      width: 48px;
      height: 48px;
      // transform: translateY(-50%);
      cursor: pointer;
    }
  }
  .datepicker-close.button {
    background-color: $black !important;
    min-height: initial;
    min-width: 30px;
    color: white !important;
    line-height: 26px;
    @include hover-focus {
      text-decoration: underline;
    }
  }
  .datepicker-days .date-switch {
    font-weight: 400;
    font-size: 18px;
    color: black;
  }
  .datepicker td span.active,
  .datepicker td.active.day,
  .datepicker td.active.year {
    background: $red !important;
    color: white;
  }
  .datepicker td,
  .datepicker th {
    // padding: 7px 14px !important;
    width: 37px;
    height: 31px;
    padding: 0;
  }
  .datepicker th.dow {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(60, 60, 67, 0.6);
  }
  .datepicker tfoot {
    display: none;
  }
  .datepicker td.day {
    &:hover,
    &:focus {
      background: $silver;
      color: white;
    }
  }
}