section.content-blocks {
  .column {
    align-self: center;
    text-align: center;

    @include breakpoint(medium) {
      text-align: left;
      align-self: stretch;

      &:nth-child(2) {
        padding-top: 8.33333%;
        padding-bottom: 8.33333%;
      }
    }

    @include breakpoint(large) {
      align-self: center;

      &:nth-child(2) {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .row {
    margin-bottom: 90px;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }

  h2 {
    margin-bottom: 24px;

    @include breakpoint(medium) {
      margin-bottom: 12px;
    }
  }

  a.button {
    margin-top: 24px;

    @include breakpoint(medium) {
      margin-top: 12px;
    }
  }

  .img-wrapper {

    width: 100%;
    height: 0;
    padding-top: 100%;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 24px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      position: absolute;
      top: 0;
      left: 0;
    }

    @include breakpoint(medium) {
      border-radius: 0px;
      margin-bottom: 0;
      height: 100%;
      padding-top: 0;
    }

    @include breakpoint(large) {
      height: 0;
      padding-top: 100%;
    }
  }

  @include breakpoint(medium) {
    &:not(.reverse) {

      .row:nth-child(odd) {
        flex-direction: row-reverse;

        .column:last-child {
          margin-right: 5%;
          flex: 0 0 41.66667%;
          max-width: 41.66667%;
        }

        .column:first-child {
          padding-left: 0;
        }
      }

      .row:nth-child(even) {
        .column:last-child {
          margin-left: 5%;
          flex: 0 0 41.66667%;
          max-width: 41.66667%;
        }

        .column:first-child {
          padding-right: 0;
        }
      }
    }

    &.reverse {
      .row:nth-child(even) {
        flex-direction: row-reverse;

        .column:last-child {
          margin-right: 5%;
          flex: 0 0 41.66667%;
          max-width: 41.66667%;
        }

        .column:first-child {
          padding-left: 0;
        }
      }

      .row:nth-child(odd) {
        .column:last-child {
          margin-left: 5%;
          flex: 0 0 41.66667%;
          max-width: 41.66667%;
        }

        .column:first-child {
          padding-right: 0;
        }
      }
    }
  }
}