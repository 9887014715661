.template-orders {

  section.account {
    padding-top: 32px;

    @include breakpoint(medium) {
      padding-top: 60px;
    }
	
	.order {
	  background: #F9F9F9;
	}
  }

  button.form-select {
    flex-direction: row;
    align-items: center;
    gap: 30px;
  }

  .viewed {
    margin-top: 24px;
    font-size: 16px;
    line-height: 22px;
  }

  .filters-wrapper {
    margin-bottom: 24px;

    .label {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 0px;
    }

    display: grid;
    gap: 12px;
    justify-content: start;

    @include breakpoint(medium) {
      gap: 42px;
      grid-template-columns: repeat(auto-fill, 150px);
      margin-bottom: 12px;
    }
  }

  .date-picker {
    position: relative;
  }

  button.product-filter {
    padding: 0px 19px 0px 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.5px;
    color: $black;

    position: relative;

    @include breakpoint(medium) {
      font-weight: 600;
      font-size: 20px;
      line-height: 1.2;
      color: $silver;
      text-decoration: underline;
    }

    &:not(.more-filters) {

      &:before,
      &:after {
        content: '';
        display: block;
        width: 13px;
        height: 1px;
        background: $black;
        border-radius: 2px;

        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        transform-origin: center;

        @include transit;

        @include breakpoint(medium) {
          background-color: $silver;
          height: 2px;
        }
      }

      &:after {
        transform: translateY(-50%) rotate(90deg);
      }

      @include hover-focus {
        color: $black;

        &:before,
        &:after {
          background: $black;
        }
      }

      &.hover {
        color: $black;

        &:after {
          transform: translateY(-50%);
          background: $red;
        }
      }
    }
  }

  .dropdown-pane,
  .datepicker-dropdown {
    background: #FFF;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);
    border-radius: 0px 24px 24px 24px;
    overflow: scroll;
    border: none;
    padding: 22px 24px;

    form [type='checkbox']+label,
    form [type='radio']+label {
      font-weight: 400;
      font-size: 14px;
      line-height: 12px;
      color: $black;

      gap: 9px;
      align-items: center;
    }
  }

  .date-picker {
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 15px;
      background-image: url('../images/icon-calendar.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      width: 20px;
      height: 20px;
      // transform: translateY(-50%);
      cursor: pointer;
    }

    input {
      padding: 0;
      background: transparent;
      box-shadow: none;
      border: none;
      margin-bottom: 0;
      width: auto;
      min-width: unset;
      height: auto;

      font-weight: 600;
      font-size: 14px;
      line-height: 1;
      letter-spacing: 0.5px;
      color: $black;

      @include breakpoint(medium) {
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2;
        color: $silver;
        text-decoration: underline;
      }
    }
  }

  .datepicker-close.button {
    background-color: $black !important;
    min-height: initial;
    min-width: 30px;
    color: white !important;
    line-height: 26px;

    @include hover-focus {
      text-decoration: underline;
    }
  }

  .datepicker-days .date-switch {
    font-weight: 400;
    font-size: 18px;
    color: black;
  }

  .datepicker td span.active,
  .datepicker td.active.day,
  .datepicker td.active.year {
    background: $red !important;
    color: white;
  }

  .datepicker td,
  .datepicker th {
    // padding: 7px 14px !important;
    width: 37px;
    height: 31px;
    padding: 0;
  }

  .datepicker th.dow {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(60, 60, 67, 0.6);
  }

  .datepicker tfoot {
    display: none;
  }

  .datepicker td.day {

    &:hover,
    &:focus {
      background: $silver;
      color: white;
    }
  }
}