.has-tip {
  border: none;
}

.tooltip {
  background: #2D2926;
  border-radius: 6px;
  padding: 18px 24px;
  max-width: 325px;
}

.tooltip-wrapper {
  display: flex;
  gap: 6px;
  align-items: center;
}