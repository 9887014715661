.accordion {
  margin: 0;
}

.accordion-title {
  border: none;
  font-weight: 600;
  font-size: 16px;
  color: $black;
  background: transparent !important;

  &:before {
    content: '' !important;
    width: 12.67px;
    height: 6.33px;
    background: url("../images/accordion-arrow.png") no-repeat center center;
    background-size: contain;
    margin-top: -2px;
    @include transit;
  }
}

.accordion-content {
  border: none;
}

.accordion-item {
  &:not(.is-active)>.accordion-title {
    border-bottom: 1px solid #D9D9D6;
  }

  >.accordion-content {
    border-bottom: 1px solid #D9D9D6;
  }

  &.is-active>.accordion-title:before {
    transform: rotate(180deg);
  }

  &.is-active>.accordion-content {
    display: block !important;
  }
}