.template-notifications {

  section.account {
    padding-top: 32px;

    @include breakpoint(medium) {
      padding-top: 60px;
    }

    .inner-frame {
      margin-bottom: 0;

      .notification-row:not(:last-of-type) {
        border-bottom: 1px solid #EEEEEE;
        padding-bottom: 32px;
        margin-bottom: 32px;
      }
    }

    form input[type='submit'] {
      margin-left: auto;
      margin-right: unset;
    }
  }

  .notification-row {

    &.w-padding {
      padding: 0 32px;
      margin-bottom: 10px;
    }

    .label {
      text-align: center;
    }

    p {
      margin-bottom: 0;
    }

    .checkbox-input {
      margin-top: 24px;
      margin-right: 24px;
      display: inline-block;

      @include breakpoint(medium) {
        margin-top: 0;
        margin-right: 0;
        display: block;
        text-align: center;
      }

      label {
        align-items: center;
      }
    }

    @include breakpoint(medium) {
      display: grid;
      gap: 12px;
      grid-template-columns: 1fr 118px 118px;

      label {
        text-indent: -9999px;
        width: 27px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}