form {
  display: grid;
  grid-gap: 32px;

  h3,
  h4 {
    font-weight: 400;
    font-size: 20px;
    line-height: 1.5;
  }

  hr {
    border-bottom: 1px solid #EEEEEE;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  label {
    font-size: 18px;
    line-height: 1.56;
    color: #6F6F6F;
  }
  
  .error-msg {
    color: #EA0000;
    margin-top: 8px;
    line-height: 17px;
    font-size: 14px;
  }

  .inner-grid {
    display: grid;
    gap: 20px;

    @include breakpoint(medium) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 32px 14px;
    }
  }
  
  .inner-grid-3 {
    display: grid;
    gap: 20px;

    @include breakpoint(medium) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 32px 14px;
    }
  }

  .input-wrapper {
    position: relative;

    .form-copy {
      margin-top: 12px;
      margin-bottom: 0;
    }

    label {
      position: absolute;
      left: 12px;
      top: 12px;
      padding: 0 4px;
      background: white;
      pointer-events: none;

      transform: scale(1);
      transform-origin: left center;
      will-change: transform;
      transition-property: transform;
      transition-duration: 250ms;
      transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);

      @include hover-focus {
        cursor: text;
      }
    }

    input:focus+label,
    input:not(:placeholder-shown)+label,
    textarea:focus+label,
    textarea:not(:placeholder-shown)+label,
    select:focus+label,
    select:not(:placeholder-shown)+label {
      transform: scale(0.75) translateY(-32px);
    }
  }

  .form-copy {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: $silver;
    // margin-bottom: 20px;

    a {
      color: $black;
      text-decoration: underline;

      @include hover-focus {
        color: $red;
      }
    }

    &.black {
      color: $black;
    }
  }

  input[type="submit"] {
    margin-right: auto;
  }

  [type='text'],
  [type='password'],
  [type='date'],
  [type='datetime'],
  [type='datetime-local'],
  [type='month'],
  [type='week'],
  [type='email'],
  [type='number'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='url'],
  [type='color'],
  [type='checkbox'],
  [type='radio'],
  textarea,
  select {
    padding: 9px 16px;
    height: 49px;
    background: #FFFFFF;
    border: 0.75px solid #8D8D8D;
    border-radius: 2px;
    margin-bottom: 0;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #6F6F6F;

    &:focus {
      border: 0.75px solid #2D2926;
      box-shadow: none;
    }
  }

  [type='checkbox'],
  [type='radio'] {
    position: absolute;
	left: -999999999px;
	left: -200vw;

    &+label {
      line-height: 17px;
      font-size: 16px;
      color: $silver;
      margin: 0;
      position: relative;

      display: flex;
      // align-items: center;
      gap: 4px;

      &:before {
        content: '';
        display: block;
        width: 27px;
        height: 27px;
        min-width: 27px;
        min-height: 27px;
        max-width: 27px;
        max-height: 27px;
        border: 0.75px solid #8D8D8D;
        border-radius: 2px;
        margin-top: -6px;
        @include transit;

      }

      &:after {
        content: '';
        width: 19px;
        height: 15px;
        display: block;
        opacity: 0;
        background: url('../images/icon-check.png') no-repeat center center;
        background-size: contain;
        @include transit;

        position: absolute;
        left: 4px;
        top: 0px;
      }
    }

    &:checked+label:before {
      border-color: $black;
      background: $black;
    }

    &:checked+label:after {
      opacity: 1;
    }
  }

  select {
    // padding: 12px 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background: white url('../images/icon-select-arrow.svg') no-repeat calc(100% - 16px) center;

    &+label {
      position: absolute !important;
      clip: rect(0, 0, 0, 0);
      overflow: hidden;
      border: 0 none !important;
      width: 1px !important;
      height: 1px !important;
      margin: -1px;
    }
  }

  textarea {
    height: 180px;
    resize: vertical;
    overflow: auto;
  }

  .login-inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button,
    a {
      line-height: 17px;
      font-size: 14px;
      color: $silver;

      @include hover-focus {
        text-decoration: underline;
      }
    }

    input[type='checkbox']+label {
      line-height: 17px;
      font-size: 14px;
      color: $silver;
    }

    input[type='checkbox']+label:before {
      margin-top: -6px;
    }

    input[type='checkbox']+label:after {
      top: 0px;
    }
  }

}

.search-form {
  display: inline-block;
  gap: unset;
  position: relative;
  margin-bottom: 0;
  width: 100%;
  max-width: 320px;

  @include breakpoint(medium) {
    max-width: 520px;
  }

  &:after {
    content: '';
    display: block;
    width: 28px;
    height: 28px;
    background: url('../images/icon-search.png') no-repeat center center;
    background-size: contain;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  input[type="text"] {
    padding: 26px 20px;
    border: 1px solid #EDEDED;
    // box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 31px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $silver;
    width: 100%;
    min-width: 100%;
    text-align: center;
    margin-right: 0;

    @include breakpoint(medium) {
      font-size: 16px;
      color: #383637;
    }
  }

  input[type="submit"] {
    display: none;
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $black;
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

input::placeholder {
  font-size: 14px;
  line-height: 24px;
  color: #6F6F6F;
  opacity: 1;

  @include breakpoint(medium) {
    font-size: 16px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}