.template-category-landing {
  .current-category {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    a {
      color: $black;

      @include hover-focus {
        text-decoration: underline;
      }
    }

    span {
      color: $red;
    }
  }

  .view-toggle,
  .unit-toggle {
    display: flex;

    button {
      padding: 12.5px 28.5px;
      background: white;
      border: 1px solid #8E8A87;

      font-weight: 600;
      font-size: 14px;
      line-height: 12px;
	  
	  &.first {
		border-radius: 6px 0px 0px 6px;
	  }
	  
	  &.last {
		border-radius: 0px 6px 6px 0px;
	  }

      &:hover,
      &:active,
      &.active {
        border: 1px solid $red;
		color: $red;
      }
    }
  }

  .unit-toggle {
    button {
      font-weight: 400;
      font-size: 12px;
      line-height: 1;
      border: 1px solid $silver;
      padding: 9px 24px;
      border-radius: 2px 0px 0px 2px;
      background: transparent;

      &:last-of-type {
        border-radius: 0px 2px 2px 0px;
      }

      &:hover,
      &:active,
      &.active {
        border: 1px solid $black;
      }

    }
  }

  .product-filters-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  button.product-filter {
    padding: 17px 19px 17px 0;
    // padding: 17px 28px 17px 0px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.5px;
    color: $black;

    position: relative;

    @include breakpoint(medium) {
      // padding: 17px 28px;
      // font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-decoration: underline;
    }

    &.more-filters {
      margin-left: auto;

      @include breakpoint(medium) {
        margin-left: initial;
      }

      &:after {
        content: '';
        display: block;
        width: 16px;
        height: 14px;
        background: url('../images/icon-filters.svg') no-repeat center center;
        background-size: contain;

        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
      }

      &:hover,
      &:focus,
      &.hover {
        color: $black;

        &:after {
          background: url('../images/icon-filters-active.svg') no-repeat center center;
          background-size: contain;
        }
      }
    }

    &:not(.more-filters) {

      &:before,
      &:after {
        content: '';
        display: block;
        width: 13px;
        height: 1px;
        background: $black;
        border-radius: 2px;

        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        transform-origin: center;

        @include transit;

        @include breakpoint(medium) {
          background-color: $silver;
          height: 2px;
        }
      }

      &:after {
        transform: translateY(-50%) rotate(90deg);
      }

      @include hover-focus {
        color: $black;

        &:before,
        &:after {
          background: $black;
        }
      }

      &.hover {
        color: $black;

        &:after {
          transform: translateY(-50%);
          background: $red;
        }
      }
    }
  }

  .clear-filters {
    button {
      margin-top: 14px;
    }

    button.clear {
      padding: 13px 16.5px 13px 27px;
      background: white;
      border-radius: 2px;
      position: relative;
      border: 1px solid transparent;

      font-weight: 400;
      font-size: 14px;
      line-height: 1;

      &:before {
        position: absolute;
        left: 9px;
        top: 15px;
        content: '';
        width: 8px;
        height: 8px;
        display: block;
        background: url('../images/icon-clear.svg') no-repeat center center;
      }

      @include hover-focus {
        border: 1px solid $black;
      }

      @include breakpoint(medium) {
        font-weight: 600;
        font-size: 12px;
        padding: 8px 9px 8px 27px;

        &:before {
          position: absolute;
          left: 9px;
          top: 10px;
          content: '';
          width: 8px;
          height: 8px;
          display: block;
          background: url('../images/icon-clear.svg') no-repeat center center;
        }

      }
    }

    button.clear-all {
      font-weight: 400;
      font-size: 14px;
      line-height: 1;
      text-decoration: underline;

      @include hover-focus {
        text-decoration: none;
      }

      @include breakpoint(medium) {
        font-weight: 600;
        font-size: 12px;
      }
    }
  }

  section.mobile-filters {
    background: white;
    padding-top: 20px;
	padding-bottom: 8px;
    z-index: 5;

    @include breakpoint(medium) {
      margin-top: 70px;
    }

    .view-toggle {
      justify-content: center;
      margin-bottom: 30px;

      button {
        padding: 8px 23px;
      }
    }

    .current-category {
      font-weight: 400;
    }
  }

  section.products-filters {
    margin-top: 110px;
    background: white;
    padding-top: 16px;
    padding-bottom: 16px;
    z-index: 5;

    .product-filters-bar {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      gap: 20px;
    }

    .filters-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 40px;

      @media only screen and (min-width: 1400px) {
        gap: 72px;
      }
    }
  }

  section.products-grid {
    margin-top: 0;
    background: white;
    padding-top: 24px;
    padding-bottom: 40px;

    .products-wrapper {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin-bottom: 32px;

      @include breakpoint(medium) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      @include breakpoint(large) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        margin-bottom: 50px;
        gap: 32px;
      }

      .card.product.featured {
        grid-column: span 2;

        @include breakpoint(medium) {
          grid-column: span 1;
        }
      }
    }

    .call-to-action {
      border-radius: 6px;
      background: $black;
      padding: 42px 16px 20px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        color: white;
        margin-bottom: 24px;

        @include breakpoint(medium) {
          font-size: 24px;
          line-height: 28px;
        }
      }

      @include hover-focus {
        .title {
          text-decoration: underline;
        }

        svg path {
          fill: $silver;
        }

        svg path:nth-child(2) {
          fill: white;
        }
      }
    }

    .viewed {
      margin-top: 24px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  section.shop-more {
    margin-top: 0;
    background: white;
    padding-top: 40px;
    padding-bottom: 40px;

    @include breakpoint(large) {
      padding-top: 110px;
      padding-bottom: 90px;
    }

    .shop-more-wrapper {
      position: relative;

      .slider-arrow {
        width: 42px;
        height: 42px;
        left: -21px;
        opacity: 0;

        @include hover-focus {
          path:nth-child(2) {
            fill: white;
          }
        }

        @include breakpoint(medium) {
          opacity: 1;
        }
      }

      .slider-arrow.next {
        left: unset;
        right: -21px;
      }
    }

    .shop-more-slider-wrapper {
      margin-top: 50px;
      clip-path: inset(-100vw -100vw -100vw 0);

      .slick-list {
        overflow: visible;
        margin: 0 -7.5px;

        @include breakpoint(medium) {
          margin: 0 -16px;
        }
      }

      .slick-slide {
        margin: 0 7.5px;

        @include breakpoint(medium) {
          margin: 0 16px;
        }
      }
    }

    .more-category {
      position: relative;
      border-radius: 6px;
      overflow: hidden;
      display: block;

      @include breakpoint(small down) {
        width: 228px;
        height: 348px;

        img:not(.logo) {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      img.logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 75%;
        object-fit: contain;
        z-index: 1;
      }

      img:not(.logo) {
        width: 100%;
		height: 654px;
        max-height: 654px;
        object-fit: cover;
      }

      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 26px;
        text-align: center;
        color: #FFFFFF;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        z-index: 1;

        @include breakpoint(large) {
          font-size: 48px;
          line-height: 1;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
      }

      @include hover-focus {
        .title {
          text-decoration: underline;
        }
      }
    }
  }

  // Dropdowns
  .dropdown-pane {
    background: #F7F8F9;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);
    border-radius: 0px 24px 24px 24px;
    overflow: scroll;
    border: none;
    padding: 24px;

    form [type='checkbox']+label,
    form [type='radio']+label {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2;
      color: $black;

      gap: 9px;
      align-items: center;
    }
  }

  #more-filters-dropdown {
    position: absolute;
    left: 0px !important;
    right: 0;
    margin: auto;
    width: 100%;
    border-radius: 0px 0px 24px 24px;
    box-shadow: 0px 8px 8px 2px rgba(0, 0, 0, 0.25);

    @include breakpoint(large) {
      max-width: 860px;
    }

    .more-filters-wrapper {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 75px;
    }

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 1;
      margin-bottom: 24px;
    }

    form {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 14px 32px;

      @include breakpoint(medium) {
        gap: 20px 32px;
      }
    }
  }

  .brands-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 8px 24px;

    .brands-link {
      background: #FFFFFF;
      border: 1px solid #D9D9D6;
      padding: 12px;

      display: flex;
      align-items: center;
      justify-content: center;

      &.active,
      &:hover,
      &:focus {
        border-color: $black;
      }
    }
  }

  .length-wrapper {
    display: grid;
    gap: 24px;

    @include breakpoint(medium) {
      grid-template-columns: 200px minmax(0, 1fr);
      gap: 32px;
    }
  }

  .lengths-grid {
    display: grid;
    gap: 14px 8px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
	padding: 10px;

    button {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      padding: 5px 12px;

      border: 0.5px solid $silver;
      border-radius: 2px;

      @include hover-focus {
        background: $silver;
        color: white;
      }

      &.active {
        background: $silver;
        color: white;
        border-color: $black;
      }
    }
  }
  
  .lengths-grid-mobile {
    display: grid;
    gap: 14px 8px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
	padding: 10px;

    button {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      padding: 5px 12px;

      border: 0.5px solid $silver;
      border-radius: 2px;

      @include hover-focus {
        background: $silver;
        color: white;
      }

      &.active {
        background: $silver;
        color: white;
        border-color: $black;
      }
    }
  }

  .swatch-group-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    margin-top: 18px;
  }

  .swatch-group {
    display: grid;
    gap: 24px;
    grid-template-columns: 50px 1fr;

    margin-bottom: 42px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .swatch-wrapper {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-items: flex-start;

    .swatch {
      text-align: center;

      .circle {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        margin-bottom: 10px;
        display: inline-block;
        position: relative;

        &:after {
          content: '';
          display: block;
          width: 58px;
          height: 58px;
          border-radius: 100%;
          border: 1px solid transparent;

          position: absolute;
          top: -4px;
          left: -4px;

          @include transit;
        }

        @include breakpoint(medium) {
          width: 65px;
          height: 65px;

          &:after {
            width: 79px;
            height: 79px;
            top: -7px;
            left: -7px;
          }
        }
      }

      .swatch-name {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #525456;

        @include breakpoint(medium) {
          color: $black;
        }
      }

      &.active,
      &:hover,
      &:focus {
        .circle:after {
          border-color: $black;
        }
      }
    }
  }

  #style-dropdown,
  #sort-dropdown,
  .single-dropdown {
    padding: 31px;
    height: 230px;
    width: 230px;
  }

  #length-dropdown {
    width: 650px;

    @media only screen and (min-width: 1400px) {
      width: 700px;
    }
  }

  #finishes-dropdown,
  .group-dropdown {
    width: 685px;
    height: 420px;
    overflow: hidden;
    padding-top: 16px;

    .finishes-wrapper {
      display: grid;
      grid-template-columns: minmax(100px, auto) minmax(0, 1fr);
      gap: 0px;
      height: 100%;
    }

    .vertical.tabs,
    .tabs-content {
      padding-top: 8px;
      height: 100%;
      overflow: scroll;
    }

  }

  // Tabs
  .tabs,
  .tabs-content {
    border: none;
    background: transparent;
  }

  .tabs-title {
    margin-bottom: 28px;

    a {
      font-weight: 400;
      font-size: 16px;
      line-height: 1;
      color: $black;
      padding: 0;
	  padding-right: 10px;
    }

    >a:focus,
    >a:hover,
    >a[aria-selected='true'] {
      background: transparent;
      color: $black;
      font-weight: 600;
    }
  }

  .tabs-panel {
    padding: 0;
  }

  .back-to-top {
    background: #F7F8F9;
    padding-bottom: 64px;

    .button {
      margin-top: 0;
    }
  }

  footer {
    margin-top: 0;
  }
}