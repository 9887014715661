.template-locations {

  section.projects {
    margin-top: 90px;

    @include breakpoint(large) {
      margin-top: 150px;

      .row {
        padding-bottom: 94.5px;
      }
    }

    h2 {
      font-size: 36px;
      line-height: 1.2;
      font-weight: 600;
      color: $silver;
      @include transit;

      &.active {
        color: $black;
      }

      @include hover-focus {
        cursor: pointer;
        color: $black;
      }

      @include breakpoint(large) {
        font-size: 64px;
      }

      &:last-of-type {
        margin-bottom: 24px;

        @include breakpoint(medium) {
          margin-bottom: 36px;
        }
      }
    }

    .column {
      align-self: center;
    }

    .outer-slider-wrapper {
      max-width: 718px;
      margin-top: 32px;

      @include breakpoint(medium) {
        margin-top: 0;
        position: relative;
      }
    }

    .projects-slider-wrapper {
      margin-bottom: 24px;

      @include breakpoint(medium) {
        margin-bottom: 0;
        clip-path: inset(-100vw -100vw -100vw 0);
      }

      .slick-list {
        // padding-left: 90px;
        overflow: visible;
        margin: 0 -7.5px;

        @include breakpoint(medium) {
          margin: 0 -17px;
          // padding-le7t: 0;
        }
      }

      .slick-slide {
        margin: 0 7.5px;

        @include breakpoint(medium) {
          margin: 0 16px;
        }
      }
    }

    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 32px;
      margin-top: 32px;

      @include breakpoint(large) {
        position: absolute;
        width: 100%;
      }
    }

    .slider-arrow {
      position: relative;
      top: unset;
      left: unset;
      right: unset;
      transform: none;

      opacity: 0 !important;

      @include hover-focus {
        path:nth-child(2) {
          fill: white;
        }
      }

      @include breakpoint(medium) {
        opacity: 1 !important;

        &.slick-disabled {
          opacity: 1 !important;

          svg path {
            fill: $silver;

            &:nth-child(2) {
              fill: white;
            }
          }
        }
      }
    }

    .project {
      position: relative;
      border-radius: 6px;
      overflow: hidden;

      @include breakpoint(small down) {
        width: 228px;
        height: 349px;
      }

      @include breakpoint(medium) {
        aspect-ratio: 718/788;
      }
	  
	  img {
		height: 100%;
		object-fit: cover;
	  }

      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 1.2;
        text-align: center;
        color: #FFFFFF;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        z-index: 1;

        @include breakpoint(medium) {
          font-size: 48px;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
      }
    }
  }
}