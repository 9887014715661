ul.pagination {
  margin-bottom: 0;
  margin-top: 32px;

  li,
  a,
  .current {
    font-size: 16px;
    line-height: 22px;
    padding: 0;
  }

  li {
    border-radius: 6px;
    overflow: hidden;

    a {
      background: $black;
      color: white;
      padding: 4px 8px;
      min-width: 30px;

      @include hover-focus {
        background: $silver;
      }
    }
  }

  .current {
    padding: 4px 8px;
    min-width: 30px;
    background-color: $red;
  }
}