.hero.home {
  margin-top: 100px;
  position: relative;

  @include breakpoint(medium) {
    margin-top: 50px;
  }

  @include breakpoint(large) {
    .row {
      flex-direction: row;
    }
	
	.row-rev {
      flex-direction: row-reverse;
    }

    margin-top: 74px;
  }

  .slider-arrow {
    opacity: 0;
  }

  @include breakpoint(large) {
    &:hover {
      .slider-arrow {
        opacity: 1;
      }
    }
  }

  .column {
    align-self: center;
  }

  .hero-content {
    text-align: center;

    h1 {
      margin-bottom: 12px;

      @include breakpoint(medium) {
        margin-bottom: 23px;
      }
    }

    .hero-copy {
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 24px;

      @include breakpoint(medium) {
        font-weight: 400;
        font-size: 22px;
        line-height: 34px;
        padding: 0 24px;
        margin-bottom: 32px;
      }
    }
  }

  img {
    width: 100%;
    height: 395px;
    object-fit: cover;
    border-radius: 6px;
    margin-bottom: 32px;

    @include breakpoint(large) {
      height: 810px;
      margin-bottom: 0;
    }
  }
}

.hero-generic {
  margin-top: 124px;
  
  @include breakpoint(large) {
    margin-top: 74px;
  }

  h1 {
    margin-bottom: 12px;
  }

  .copy {

    @include breakpoint(medium) {
      font-size: 24px;
      line-height: 32;
    }
  }

  .category {
    font-size: 20px;
    line-height: 1.2;
    color: $red;
    margin-bottom: 12px;
  }

  .date {
    font-size: 18px;
    line-height: 1.2;
    color: $silver;
  }

  img.brand-hero {
    margin-bottom: 50px;
  }

  img.icon {
    margin-bottom: 18px;
  }

  .related-categories {
    margin-top: 32px;

    .related-title {
      font-size: 24px;
      line-height: 1.2;
      margin-bottom: 12px;
    }

    .categories-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }

    a.category {
      display: inline-block;
      padding: 8px 9px;
      border: 1px solid $silver;
      border-radius: 2px;

      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      color: $black;

      @include hover-focus {
        color: $white;
        background: $black;
        border-color: $black;
      }
    }
  }

  .products-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 12px;
    margin-top: 12px;

    @include breakpoint(medium) {
      grid-template-columns: repeat(3, minmax(0, 107px));
      justify-content: space-around;
    }
  }
}