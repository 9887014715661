.datepicker {
  display: none;
  position: absolute;
  padding: 4px;
  margin-top: 1px;
  direction: ltr
}

.datepicker.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  display: none;
  min-width: 160px;
  list-style: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  color: #333;
  font-size: 13px;
  line-height: 18px
}

.datepicker.dropdown-menu td,
.datepicker.dropdown-menu th {
  padding: 4px 5px
}

.datepicker table {
  border: 0;
  margin: 0;
  width: auto
}

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer
}

.datepicker td,
.datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  border: 0;
  font-size: 12px;
  padding: 4px 8px;
  cursor: pointer
}

.datepicker td {
  background: #fff
}

.datepicker td span.active,
.datepicker td.active.day,
.datepicker td.active.year {
  background: #2ba6cb
}

.datepicker td.new,
.datepicker td.old {
  color: #999
}

.datepicker td span.month.disabled,
.datepicker td span.year.disabled,
.datepicker td.day.disabled {
  color: #eee
}

.datepicker th {
  background: #fff
}

.datepicker th span.active,
.datepicker th.active.day,
.datepicker th.active.year {
  background: #2ba6cb
}

.datepicker th.date-switch {
  width: 145px
}

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle
}

.datepicker.days div.datepicker-days,
.datepicker.months div.datepicker-months,
.datepicker.years div.datepicker-years {
  display: block
}

.datepicker-dropdown:after,
.datepicker-dropdown:before {
  content: '';
  display: inline-block;
  position: absolute
}

.datepicker thead tr:first-child th {
  cursor: pointer
}

.datepicker thead tr:first-child th.cw {
  cursor: default;
  background-color: transparent
}

.datepicker tfoot tr:first-child th {
  cursor: pointer
}

.datepicker-inline {
  width: 220px
}

.datepicker-rtl {
  direction: rtl
}

.datepicker-rtl table tr td span {
  float: right
}

.datepicker-dropdown {
  top: 0;
  left: 0
}

.datepicker-dropdown:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: 1px solid rgba(0, 0, 0, .2);
  top: -7px;
  left: 6px
}

.datepicker-dropdown:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  top: -6px;
  left: 7px
}

.datepicker-dropdown::after,
.datepicker-dropdown::before,
.datepicker>div {
  display: none
}

.datepicker-close {
  position: absolute;
  top: -30px;
  right: 0;
  width: 15px;
  height: 30px;
  padding: 0;
  display: none
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent
}