footer {
  margin-top: 60px;
  padding-top: 22px;
  padding-bottom: 22px;
  border-top: 1px solid $light-grey;
  background: white;

  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: $grey;

  position: relative;
  z-index: 10000;

  @include breakpoint(large) {
    z-index: unset;

    .row {
      flex-direction: row-reverse;
    }
  }

  @include breakpoint(large) {
    margin-top: 150px;
  }

  a {
    color: $grey;

    @include hover-focus {
      text-decoration: underline;
    }
  }

  .copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // gap: 10px;
    margin-top: 24px;

    font-size: 14px;
    line-height: 20px;

    @include breakpoint(large) {
      margin-top: 0;
      justify-content: start;
      gap: 18px;

      font-size: 16px;
      line-height: 22px;
    }

    a {
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;

      &:before {
        content: '';
        display: inline-block;
        width: 3px;
        height: 3px;
        margin-top: -3px;
        background: $grey;
      }

      @include breakpoint(large) {
        gap: 18px;
      }
    }
  }

  .resources {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;

      @include hover-focus {
        text-decoration: underline;
      }
    }

    @include breakpoint(medium) {
      justify-content: space-around;
    }

    @include breakpoint(large) {
      justify-content: flex-end;
      gap: 47px;

      button {
        position: relative;
        display: flex;
        align-items: center;
        gap: 6px;

        font-size: 16px;
        line-height: 22px;

        &:after {
          content: '';
          display: block;
          width: 19px;
          height: 19px;
          background: url('../images/icon-chevron-up.svg') no-repeat center center;
          background-size: contain;
        }
      }
    }
  }
}