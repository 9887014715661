// Colours
$white: #fff;
$black: #2D2926;
$off-white: #F8F8F8;

$salt-grey: #F7F7F7;
$grey: #292929;
$light-grey:#D9D9D9;
// $silver: #75787B;
$silver: #727272;
$very-light-grey: #e2e2e2;
$primary: #DA291C;
$secondary: #2cafc8;
$green: #009103;
$yellow: #d4da26;
$red: #DA291C;

$light-grey-50: transparentize($light-grey, 0.5);

// Font Size
$text-xxs: 0.6875rem;
$text-xs: 12px;
$text-sm: 15px;
$text-base: 16px;
$text-md: 18px;
$text-lg: 46px;
$text-xl: 52px;

// Font Weight
$font-light: 300;
$font-normal: 400;
$font-heavy: 600;
$font-bold: 900;

// Margin & Padding
$h-w-tiny: 0.25rem; // 4px
$h-w-1: 0.5rem; // 8px
$h-w-2: 1rem; // 16px
$h-w-3: 1.5rem; // 24px
$h-w-4: 2rem; // 32px
$h-w-5: 2.5rem; // 40px
$h-w-6: 3rem; // 48px
$h-w-7: 3.5rem; // 56px
$h-w-8: 4rem; // 64px
$h-w-9: 4.5rem; // 72px
$h-w-10: 5rem; // 80px
$h-w-11: 5.5rem; // 88px
$h-w-12: 6rem; // 96px
$h-w-13: 6.5rem; // 104px
$h-w-14: 7rem; // 112px
$h-w-15: 7.5rem; // 120px
$h-w-16: 8rem; // 128px
$h-w-17: 8.5rem; // 136px
$h-w-18: 9rem; // 144px
$h-w-19: 9.5rem; // 152px
$h-w-20: 10rem; // 160px

// Border Radius
$rounded-sm: 0.125rem;
$rounded-default: 0.25rem;
$rounded-lg: 0.5rem;
$rounded-full: 9999px;

// Box Shadow
$shadow-default: 4px 4px 10px 0 rgba(0, 0, 0, 0.1);
$shadow-dark: 0 0 10px 0 rgba(0, 0, 0, 0.2);
$shadow-lift: 0px 4px 8px 0 rgba(0, 0, 0, 0.15);
$shadow-left: -6px 6px 15px 0 rgba(0, 0, 0, 0.1);
$shadow-right: 6px 6px 15px 0 rgba(0, 0, 0, 0.1);

// Top Bar Height
$top-bar-height: 91px;