@font-face {
  font-family: 'Hurme Geometric Sans';
  src: url("../fonts/HurmeGeometricSans1-Regular.woff") format("woff"),
    url("../fonts/HurmeGeometricSans1-Regular.woff2") format("woff2");
}

@font-face {
  font-family: 'Hurme Geometric Sans';
  font-weight: 600;
  src: url("../fonts/HurmeGeometricSans1-SemiBold.woff") format("woff"),
    url("../fonts/HurmeGeometricSans1-SemiBold.woff2") format("woff2");
}


body,
.copy {
  font-family: "Hurme Geometric Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: $grey;

  @include breakpoint(medium) {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }

  &.small {
    font-size: 18px;
    line-height: 24px;
  }
}

.copy a {
  // color: $red;
  color: #0a6192;
  text-decoration: underline;

  @include hover-focus {
    color: $black;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Hurme Geometric Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: $grey;
  font-style: normal;
  margin-bottom: 0;

  &.white {
    color: $white;
  }

  .hl,
  span {
    color: $red;
  }
}

h1 {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 400;

  @include breakpoint(medium) {
    font-size: 46px;
  }

  @include breakpoint(large) {
    font-size: 62px;
    line-height: 1.1;
  }
}

h2 {
  font-weight: 400;
  font-size: 22px;
  line-height: 1.2;

  @include breakpoint(medium) {
    font-size: 46px;
    line-height: 1.2;
  }
}

h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;

  @include breakpoint(medium) {
    font-size: 22px;
    line-height: 30px;
  }
}

.copy {

  p {
    line-height: 1.4;
  }

  >p:last-child {
    margin-bottom: 0;
  }

  &.white {
    color: $white;
  }

}

strong {
  font-weight: 600;
}

mark {
  color: $red;
  background-color: transparent;
}

@include breakpoint(small down) {
  br {
    display: none;
  }
}