.template-feature {

  section.showcase {
    @include breakpoint(large) {
      margin-top: 50px;
    }

    .hotspot-container {
      margin-top: 0;
      margin-bottom: 32px;
    }
  }

  section.featured-products {
    @include breakpoint(large) {
      margin-top: 100px;
    }

    .cbm-container {
      position: relative;
      padding-left: 12px;
      padding-right: 12px;
    }

    .slider-arrow {
      opacity: 0;
      left: -20px;
      top: calc(50% - 26.5px);

      &.next {
        left: unset;
        right: -20px;
      }

      @include hover-focus {
        svg path:nth-child(2) {
          fill: white;
        }
      }

      @include breakpoint(large) {
        opacity: 1;
      }
    }

    .featured-products-slider-wrapper {
      .slick-list {
        margin: 0 -7.5px;

        @include breakpoint(medium) {
          margin: 0 -16px;
        }
      }

      .slick-slide {
        margin: 0 7.5px;

        @include breakpoint(medium) {
          margin: 0 16px;
        }
      }
    }

    a.feature {
      display: block;
      color: $black;

      .img-wrapper {
        margin-bottom: 12px;
        height: 0;
        padding-top: 100%;
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;

          &.hover-focus {
            opacity: 0;
            @include transit;
            will-change: opacity;

            border-radius: 6px;
            object-fit: cover;
          }
        }
      }

      .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2;
      }

      .category {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.2;
      }

      @include hover-focus {
        .title {
          text-decoration: underline;
        }
      }

      @include breakpoint(large) {
        @include hover-focus {
          img.hover-focus {
            opacity: 1;
          }
        }
      }
    }
  }
}