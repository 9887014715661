.template-account {

  main {
    background: $white;
  }

  section.hero-generic {
    background: white;
    padding-bottom: 50px;
    margin-top: 50px;

    @include breakpoint(large) {
      padding-bottom: 150px;
      margin-top: 124px;
    }

    h1 {
      margin-bottom: 32px;
    }
  }


  section.account {
    margin-top: 0;
    padding-top: 60px;
    padding-bottom: 60px;

    @include breakpoint(large) {
      padding-bottom: 90px;
    }

    h2 {
      margin-bottom: 32px;

      @include breakpoint(large) {
        margin-bottom: 72px;
      }
    }

    #toggle-account-sidebar {
      font-weight: 400;
      font-size: 24px;
      line-height: 1.2;
      margin-bottom: 32px;

      img {
        @include transit;
        height: 20px;
        margin-left: 12px;
      }

      &.open {
        img {
          transform: rotate(45deg);
        }
      }

      @include hover-focus {
        text-decoration: underline;
      }
    }

    .has-tip {
      margin-top: -4px;
    }

    .account-sidebar {
      list-style-type: none;
      margin-left: 0;
      margin-bottom: 32px;
      display: none;
      opacity: 0;
      @include transit;

      &.open {
        opacity: 1;
        display: block;
      }

      @include breakpoint(medium) {
        margin-bottom: 60px;
        opacity: 1;
        display: block;
      }

      // li.active a {
      //   border-left: 3px solid $red;
      // }
      li {
        margin-bottom: 32px;
        position: relative;

        a {
          display: grid;
          grid-template-columns: 32px minmax(0, 1fr);
          gap: 30px;
          align-items: center;

          color: $black;
          font-weight: 400;
          font-size: 20px;
          line-height: 1;
        }

        img {
          justify-self: center;
        }

        &.active {
          &:before {
            content: '';
            display: block;
            height: 100%;
            width: 3px;
            background: $red;

            position: absolute;
            top: 0;
            left: -3px;
          }

          a {
            font-weight: 600;
          }
        }

        @include hover-focus {

          a {
            text-decoration: underline;
          }
        }
      }
    }

    h3 {
      margin-bottom: 12px;
    }

    .accordion-item {
	  background: #F9F9F9;
      padding-top: 32px;
      padding-bottom: 32px;
      border-bottom: 1px solid #D0D0D0;

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        border: none;
        padding-bottom: 0;
      }

      @include breakpoint(large) {

        &.is-active .inner-split .value,
        &.is-active .inner-split .address {
          opacity: 0;
          height: 0;
        }
      }
    }

    .accordion-title {
      padding: 0;
      border: none;

      &:before {
        content: unset !important;
        display: none;
      }

      @include hover-focus {

        .inner-split button,
        .inner-split>a {
          font-weight: 600;
        }
      }
    }

    .accordion-content {
      background: #F9F9F9;
	  padding: 24px 0 0;
      border: none;

      @include breakpoint(medium) {
        form {
          max-width: 50%;

          .checkbox-input label {
            font-weight: 400;
            font-size: 16px;
            align-items: center;
            gap: 10px;
            color: $black;
          }
        }
      }
    }

    .inner-frame-header {
      background: $red;
      padding: 48px 32px;
      border-radius: 24px 24px 0px 0px;

      h4 {
        font-weight: 600;
        font-size: 40px;
        line-height: 1;
        margin-bottom: 0;
      }
    }
	
	.inner-frame-header-grey {
      background: $light-grey;
      padding: 48px 32px;
      border-radius: 24px 24px 0px 0px;

      h4 {
        font-weight: 600;
        font-size: 40px;
        line-height: 1;
        margin-bottom: 0;
      }
    }

    .inner-frame-w-header {
      background: #F9F9F9;
      border-radius: 0px 0px 24px 24px;
      padding: 32px;
      margin-bottom: 32px;

      @include breakpoint(large) {
        margin-bottom: 72px;
      }

      .inner-split {
        display: grid;
        gap: 24px;
        align-items: start;

        @include breakpoint(large) {
          gap: 30px 64px;
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        .full {
          grid-column: 1/-1;

          .inner-split {
            button {
              margin-left: 58px;
              text-align: left;
              text-decoration: underline;
            }

            .label {
              font-weight: 400;
              font-size: 20px;
              opacity: 0;
              @include transit;
            }
          }

          .accordion-item.is-active .label {
            opacity: 1;
          }
        }

        a {
          color: $black;
        }
      }

      h5 {
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        margin-bottom: 32px;
      }

      .icon-wrapper {
        display: grid;
        gap: 16px;
        grid-template-columns: 42px 1fr;
        margin-bottom: 32px;

        img {
          justify-self: center;
        }

        .small-link {
          margin-top: 12px;
          font-weight: 400;
          font-size: 16px;
          text-decoration: underline;
        }
      }
    }

    .inner-frame {
      background: #F9F9F9;
      border-radius: 24px;
      padding: 32px;
      margin-bottom: 32px;

      @include breakpoint(large) {
        margin-bottom: 72px;
      }
	  
	  .notification-row:not(:last-of-type) {
        border-bottom: 1px solid #EEEEEE;
        padding-bottom: 32px;
        margin-bottom: 32px;
      }

      .inner-split {
        display: grid;
        gap: 12px;
        align-items: start;

        @include breakpoint(large) {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        font-weight: 400;
        font-size: 20px;
        line-height: 1.2;

        .value {
          font-weight: 600;
		  word-break: break-word;
          @include transit;
		  
		  @include breakpoint(large) {
            word-break: initial;
          }
        }

        button,
        >a {
          text-decoration: underline;
          text-align: left;

          @include breakpoint(large) {
            text-align: right;
            margin-left: auto;
          }
        }

        >a {
          color: $black;

          @include hover-focus {
            font-weight: 600;
          }
        }
      }


      hr {
        margin-top: 32px;
        margin-bottom: 32px;
        border-bottom: 1px solid #D0D0D0;
      }

      .address {
        font-weight: 600;
        font-size: 18px;
        line-height: 1.2;

        .name {
          color: $silver;
        }

        img {
          display: block;
        }
      }

      button.button {
        margin-top: 24px;
      }
    }
  }
  
  .notification-row {

    &.w-padding {
      padding: 0 32px;
      margin-bottom: 10px;
    }

    .label {
      text-align: center;
    }

    p {
      margin-bottom: 0;
    }

    .checkbox-input {
      margin-top: 24px;
      margin-right: 24px;
      display: inline-block;

      @include breakpoint(medium) {
        margin-top: 0;
        margin-right: 0;
        display: block;
        text-align: center;
      }

      label {
        align-items: center;
      }
    }

    @include breakpoint(medium) {
      display: grid;
      gap: 12px;
      grid-template-columns: 1fr 118px 118px;

      label {
        text-indent: -9999px;
        width: 27px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .button.to-top {
    margin-bottom: 60px;
  }

  footer {
    margin-top: 0;
  }
}