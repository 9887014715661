.hotspot-container {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;

  padding-bottom: 994px / 868px * 100%;
  background-repeat: no-repeat !important;
  background-size: cover !important;

  .hotspot {
    position: absolute;
    display: block;
    // width: 50px;
    // height: 50px;

    .icon {
      position: absolute;
      top: 0;
      left: 0;

      display: block;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      overflow: hidden;

      border: 1px solid #FFFFFF;
      background: rgba(0, 0, 0, 0.2);

      cursor: pointer;
      transition: all 0.3s ease;

      &:before {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        background: white;
        position: absolute;
        border-radius: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease;
      }

      &:hover:before {
        width: 22.5px;
        height: 22.5px;
      }
    }

    .content {
      position: absolute;
      // top: 30px;
      // left: 30px;

      padding: 16px;
      background: #FFFFFF;
      box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25);

      margin: 0;
      overflow: auto;
      // height: 120px;
      min-width: 150px;

      @include breakpoint(medium) {
        min-width: 200px;
      }

      opacity: 0;
      transition: opacity 0.1s ease 0s; // Hide

      .hotspot-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 1;
        margin-bottom: 20px;
      }

      .desc {
        font-size: 16px;
        line-height: 1;
        margin-bottom: 24px;
      }

      .favourite {
        path {
          transition: none;
        }

        &:hover,
        &:focus,
        &.favourited {
          path {
            fill: $red;
            stroke: $red;
          }
        }
      }

      a:hover {
        svg path {
          fill: #75787B;
        }
      }
    }

    &.open {

      .icon:before {
        width: 30px;
        height: 30px;
      }

      .content {
        opacity: 1;
        transition: opacity 0.2s ease 0.2s; // Show
      }
    }

    &.left-top {

      .content {
        border-radius: 0px 6px 6px 6px;
        top: 45px;
        left: 50px;
      }
    }

    &.right-top {

      .content {
        border-radius: 6px 0px 6px 6px;
        top: 45px;
        right: 5px;
      }
    }

    &.left-bottom {
      .content {
        border-radius: 6px 6px 6px 0px;
        left: 50px;
        bottom: -5px;
      }
    }

    &.right-bottom {

      .content {
        border-radius: 6px 6px 0px 6px;
        bottom: -5px;
        right: 5px;
      }
    }
  }


}