.template-project-single {

  section.account {
    padding-top: 32px;

    @include breakpoint(medium) {
      padding-top: 60px;
    }

    // Main Content
    .medium-9 {
      h2 {
        margin-bottom: 10px;
      }
    }

    .all-projects {
      color: $black;
      text-decoration: underline;
    }

    .select-products {
      display: block;
      margin-top: 32px;
      margin-left: auto;

      font-style: normal;
      font-weight: 400;
      font-size: 16px;

      @include hover-focus {
        text-decoration: underline;
      }
    }

    .actions-wrapper {
      margin-top: 32px;
      text-align: right;
      display: none;

      font-style: normal;
      font-weight: 400;
      font-size: 16px;

      button {

        @include hover-focus {
          text-decoration: underline;
        }
      }
    }

    .select-products,
    .actions-wrapper {
      @include breakpoint(medium) {
        height: 30px;
      }
    }

    .card.product {

      [type='checkbox'],
      [type='radio'] {
        display: none;

        &+label {
          line-height: 17px;
          font-size: 12px;
          color: $silver;
          margin: 0;
          position: relative;

          display: flex;
          // align-items: center;
          gap: 4px;

          &:before {
            content: '';
            display: block;
            width: 27px;
            height: 27px;
            min-width: 27px;
            min-height: 27px;
            max-width: 27px;
            max-height: 27px;
            border: 0.75px solid #8D8D8D;
            border-radius: 2px;
            margin-top: -3px;
            @include transit;

          }

          &:after {
            content: '';
            width: 19px;
            height: 15px;
            display: block;
            opacity: 0;
            background: url('../images/icon-check.png') no-repeat center center;
            background-size: contain;
            @include transit;

            position: absolute;
            left: 4px;
            top: 3px;
          }
        }

        &:checked+label:before {
          border-color: $black;
          background: $black;
        }

        &:checked+label:after {
          opacity: 1;
        }
      }

      .img-wrapper {
        position: relative;

        img {
          border-radius: 6px;
        }

        .checkbox-wrapper {
          position: absolute;
          top: 12px;
          right: 12px;
          z-index: 1;
          opacity: 0;

          @include transit;
        }
      }

      .content {
        background: transparent;
        padding: 28px 0 0;
      }

      @include hover-focus {
        cursor: default;
      }
    }

    .project-products-wrapper {
      display: grid;
      gap: 32px;
      margin-top: 12px;
      margin-bottom: 48px;

      @include breakpoint(medium) {
        margin-bottom: 72px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 72px 32px;
      }
	  
	  .quantity {
		width: 100%;
		
		@include breakpoint(large) {
		  width: 50%
		}
	  }

      &.selection {
        .checkbox-wrapper {
          opacity: 1 !important;
        }
      }
    }
	
	.project-products-wrapper-list {
      &.selection {
        .checkbox-wrapper {
          opacity: 1 !important;
        }
      }
    }
  }
}